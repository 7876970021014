import React from 'react';
import {Create, SimpleForm, TextInput, SelectInput } from 'react-admin';
import { useForm, useField } from 'react-final-form';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import dataProvider from '../dataProvider';
import Moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import * as Config from '../config'
import Logo from '../images/tts_logo_long.png'
import { useMediaQuery } from '@material-ui/core';
import * as qs from 'query-string';

const useStyles = makeStyles(theme => ({
  errorMessage:  {
    color: 'red',
    fontSize: '24px',
    paddingBottom: '10px'
  },
  recipient : {
    fontSize: '24px',
    paddingTop: '2%'
  },
  recipientResults: {
    fontSize: '24px',
    padding: 0
  },
  recipientInstructions: {
    fontSize: '20px'
  },
  dateOfBirth : {
    paddingTop: '20px',
    fontSize: '20px',
    width: '25%'
  },
  dateOfBirthMobile : {
    paddingTop: '20px',
    fontSize: '20px',
    width: '100%'
  },
  dateOfBirthInput : {
    maxWidth: '10%',
    marginLeft: '1%',
    marginRight: '1%',
  },
  dateOfBirthInputMobile: {
    width: '100%',
  },
  dateOfBirthSelectInput : {
    maxWidth: '15%',
    marginLeft: '1%',
    marginRight: '1%',
    width: '15%'
  },
  submitButton: {
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  logoBox: {
    width: '75%',
    marginLeft: '12%'
  },
  logo: {
    width: '65%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block'
  }
}));
export const PatientResults = props => (
    <PatientResultsForm {...props}  />
);

class PatientResultsForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isLoading : true,
    }
  }
  componentDidMount() {
      document.title = "LINK Test Result"
      this.fetchResults();
  }
  async fetchResults() {
    this.setState({
          isLoading : true,
          isError: false,
          errorMessage: "",
          recipientName : "",
          collectionDate: "",
          dateError: "",
          mappingResultId: "",
          uuid: "",
          htmlResults: "",
          showResults: false
    });
    const parsed = qs.parse(window.location.hash);
    console.log("parsed ", parsed);
    var id = ""
    var optValue = ""
    if (parsed && parsed.id && parsed.id.length > 0) {
      id = parsed.id
    } else if (parsed && parsed['/patient/results?id'] && parsed['/patient/results?id'].length > 0) {
      id = parsed['/patient/results?id']
    }

    if (parsed && parsed.opt && parsed.opt.length > 0) {
      optValue = parsed.opt
    } else if (parsed && parsed['/patient/results?opt'] && parsed['/patient/results?opt'].length > 0) {
      optValue = parsed['/patient/results?opt']
    }

      /*
    if (parsed && parsed.indexOf("&") >= 0)
      id = parsed.toString().substring(parsed.toString().toLowerCase().indexOf('?id=') + 4, parsed.indexOf("&"));
    else
      id = parsed.toString().substring(parsed.toString().toLowerCase().indexOf('?id=') + 4, parsed.length);
      */
    await dataProvider.getOne('testresults',{
          id
    }).then((validUser) => {
      if (optValue && optValue.length > 0 && parseInt(optValue) === 2) {
        var opt = "?opt=" + optValue
        fetch(Config.REACT_APP_API_URL + "/testresults/result/" + validUser.data.uuid + opt, {
            method: "GET",
            headers: new Headers({'Content-Type': 'application/json'})
        })
        .then((response) => response.text())
        .then(htmlResponse => {
          if (document.getElementById('PatientResultsSection')) {
            var section = document.getElementById('PatientResultsSection')
            section.style.width = '100%';
          }
          this.setState({
            isError : false,
            isLoading : false,
            showResults: true,
            htmlResults : htmlResponse
          });
        })
        .catch(({ status, statusText, headers, body }) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
          console.log("Caught body ", body);
          if (status && body) {
            this.setState({
                dateError : body ? body.message : "Error",
                isLoading : false
            });
          } else {
            this.setState({
              isError : false,
              isLoading : false,
            });
          }
        });
      } else {
        this.setState({
          isError : false,
          isLoading : false,
          recipientName : validUser.data.recipientName,
          mappingResultId: validUser.data.mappingResultId,
          uuid: validUser.data.uuid,
          collectionDate : validUser.data.collectionDate,
        });
      }
    }).catch(({ status, statusText, headers, body }) => {
      console.log("Caught status status ", status);
      console.log("Caught status statusText ", statusText);
      console.log("Caught body ", body);
      this.setState({
          errorMessage : body ? body.message: "Server error occured",
          isError : true,
          isLoading : false
      });
    });
  }
  render() {
    const {
        isLoading,
        isError,
        errorMessage,
        dateError,
        recipientName,
        htmlResults,
        mappingResultId,
        showResults,
        uuid,
        collectionDate
    } = this.state;
    const that = this;
    function Results() {
      const resultsForm = useForm();
      const dobday = useField("dobday", resultsForm);
      const dobmonth = useField("dobmonth", resultsForm);
      const dobyear = useField("dobyear", resultsForm);

      const classes = useStyles();
      const submitDOB = async values => {
        that.setState({
          isLoading : true
        });
        var formattedDay = parseInt(dobday.input.value) < 10 ? "0" + parseInt(dobday.input.value) : parseInt(dobday.input.value)
        var formattedMonth = parseInt(dobmonth.input.value) < 10 ? "0" + parseInt(dobmonth.input.value) : parseInt(dobmonth.input.value)
        var formattedYear = parseInt(dobyear.input.value)
        var dob = formattedYear + "-" + formattedMonth + "-" + formattedDay
        var date = Moment(dob).format('YYYY-MM-DD');
        if (Number.isNaN(parseInt(dobyear.input.value)) || Number.isNaN(parseInt(dobday.input.value)) || Number.isNaN(parseInt(dobmonth.input.value)) || Moment(date).isValid() === false) {
          that.setState({
            dateError : "Invalid Date",
            isLoading : false
          });
        } else {
          fetch(Config.REACT_APP_API_URL + "/testresults/confirm", {
              method: "POST",
              headers: new Headers({'Content-Type': 'application/json'}),
              body: JSON.stringify({
                  dob : dob,
                  uuid: uuid,
                  mappingResultId : mappingResultId
              })
          })
          .then(response => {
            if (response.status !== 200)
              return response.json()
            else
              return {
                success: true
              }
          })
          .then(data => {
            //console.log("Data ", data);
            if (!data.success) {
              //console.log("no success ", data);
              that.setState({
                  dateError : "The date of birth that you entered does not match the one we have on record. Please contact <a href=\"mailto:" + data.tenantEmail + "\">" + data.tenantEmail + "</a> for further assistance",
                  tenantEmail: "harvey@wallbangers.com",
                  tenantName: "Harvey Wallbanger's Sobriety Tests",
                  isLoading : false
              });
            } else {
              const parsed = qs.parse(window.location.hash);
              var opt = ""
              if (parsed && parsed.opt && parsed.opt.length > 0)
                opt = "?opt=" + parsed.opt
              fetch(Config.REACT_APP_API_URL + "/testresults/result/" + uuid + opt, {
                  method: "GET",
                  headers: new Headers({'Content-Type': 'application/json'})
              })
              .then((response) => response.text())
              .then(htmlResponse => {
                if (document.getElementById('PatientResultsSection')) {
                  var section = document.getElementById('PatientResultsSection')
                  section.style.width = '100%';
                }
                that.setState({
                  isError : false,
                  isLoading : false,
                  showResults: true,
                  htmlResults : htmlResponse
                });
              })
              .catch(({ status, statusText, headers, body }) => {
                console.log("Caught status status ", status);
                console.log("Caught status statusText ", statusText);
                console.log("Caught body ", body);
                if (status && body) {
                  that.setState({
                      dateError : body ? body.message : "Error",
                      isLoading : false
                  });
                } else {
                  that.setState({
                    isError : false,
                    isLoading : false,
                  });
                }
              });
            }
          })
        }
      }
      var months = [
        {id : '', value: '', name: ''},
        {id : '01', value: '01', name: 'Jan'},
        {id : '02', value: '02', name: 'Feb'},
        {id : '03', value: '03', name: 'Mar'},
        {id : '04', value: '04', name: 'Apr'},
        {id : '05', value: '05', name: 'May'},
        {id : '06', value: '06', name: 'Jun'},
        {id : '07', value: '07', name: 'Jul'},
        {id : '08', value: '08', name: 'Aug'},
        {id : '09', value: '09', name: 'Sep'},
        {id : '10', value: '10', name: 'Oct'},
        {id : '11', value: '11', name: 'Nov'},
        {id : '12', value: '12', name: 'Dec'},
      ]
      const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
      const isMedium = useMediaQuery(theme => theme.breakpoints.down('md'));
      const CreatedDateFormat = () => <>{collectionDate ? Moment(collectionDate).format('DD MMM YYYY') : ""}</>;
      return (
         <>
         { !showResults && <section id="PatientResultsSection" style={{width: isSmall || isMedium ? '98%': '50%', boxShadow: '0px 5px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)', paddingTop: '1%',  paddingBottom: '5%',  backgroundColor: '#FFFFFF', flex: 'auto',  marginLeft: 'auto', marginRight: 'auto'}}>{ isLoading ? <Box display="block" justifyContent="space-between" style={{width: isSmall || isMedium ? '98%': '90%', marginLeft: isSmall || isMedium ? '1%' : '5%', marginRight: isSmall || isMedium ? '1%' : '5%', backgroundColor: '#FAFAFA'}}>
            <Box display="flex" width="100%" className={classes.logoBox}>
              <img src={Logo} className={classes.logo} alt='LACENTA Logo' />
            </Box>
            <Box display="flex" justifyContent="space-between" style={{ width: '10%', paddingTop: '10%', marginLeft: 'auto', marginRight: 'auto' }}><CircularProgress style={{ marginLeft: '2em'}} size={24} thickness={4} /></Box>
          </Box>
           : <>
             { (isError || !showResults) && <Box display="flex" justifyContent="center" style={{ width: '90%', marginLeft: '5%', marginRight: '5%'}}>
              { isError && <Box display="block" justifyContent="space-between" style={{ width: '100%' }} className={classes.recipient}>
                <Box width="100%" className={classes.logoBox}>
                  <img src={Logo} className={classes.logo} alt='LACENTA Logo' />
                </Box><Box display="flex" justifyContent="space-between" style={{ width: '100%' }} className={classes.errorMessage}>{ errorMessage }</Box></Box> }
              { !isError && !showResults &&
                <Box display="block" justifyContent="space-between" style={{ width: '100%' }} className={classes.recipient}>
                  <Box width="100%" className={classes.logoBox}>
                    <img src={Logo} className={classes.logo} alt='LACENTA Logo' />
                  </Box>
                  <Box display="flex-row" justifyContent="flex-start" className={classes.recipient}>
                  Hi, { recipientName } <br/><br/>
                  <span className={classes.recipientInstructions}>Please enter your date of birth to view your test results from <CreatedDateFormat source="collectionDate" label="Created" /></span>
                  </Box>
                  {isSmall || isMedium ? ( <>
                    <Box display="flex" justifyContent="center" className={classes.dateOfBirthMobile}>
                      Your Date of Birth
                    </Box>
                    <Box display="flex" justifyContent="center" className={classes.dateOfBirthInputMobile}>
                      <TextInput source="dobday" label="Day"/>
                    </Box>
                    <Box display="flex" justifyContent="center" className={classes.dateOfBirthInputMobile}>
                      <SelectInput id="DobMonth" label="Month" style={{ minWidth: '25px !important', width: '50px !important', float: 'left' }} source="dobmonth" choices={months}/>
                    </Box>
                    <Box display="flex" justifyContent="center" className={classes.dateOfBirthInputMobile}>
                      <TextInput source="dobyear" label="Year"/>
                    </Box> </>
                   ) : (
                     <Box display="flex" justifyContent="center" style={{ width: '100%', paddingTop: '3%', paddingBottom: '2%' }}>
                       <Box display="flex-row" justifyContent="flex-start" className={classes.dateOfBirth}>
                         Your Date of Birth
                       </Box>
                       <Box display="flex-row" justifyContent="flex-start" className={classes.dateOfBirthInput}>
                         <TextInput source="dobday" label="Day"/>
                       </Box>
                       <Box display="flex-row" justifyContent="flex-start" className={classes.dateOfBirthSelectInput}>
                         <SelectInput id="DobMonth" label="Month" style={{ minWidth: '25px !important', width: '50px !important', float: 'left' }} source="dobmonth" choices={months}/>
                       </Box>
                       <Box display="flex-row" justifyContent="flex-start" className={classes.dateOfBirthInput}>
                         <TextInput source="dobyear" label="Year"/>
                       </Box>
                  </Box> ) }
                  <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
                    { dateError && dateError !== "" && <span className={classes.errorMessage}>{ReactHtmlParser(dateError)}</span> }
                  </Box>
                  <Box display="flex" justifyContent="center" className={classes.submitButton}>
                    <Box display="flex-row" justifyContent="flex-start" className={classes.submitButton}>
                      <Button variant="contained" color="primary" label="Submit" fullWidth onClick={() => submitDOB()}>Submit</Button>
                    </Box>
                  </Box>
                </Box> }
              </Box> }
           </>
        }
        </section> }
        { !isError && showResults && <Box display="block" justifyContent="space-between" style={{ width: '100%' }} className={classes.recipientResults}>
          {ReactHtmlParser (htmlResults)} </Box>
        }
        </>);
    }
    return <Create title="Patient Results - Total Testing Solutions" {...this.props}><SimpleForm  toolbar={null}><Results {...this.props} /></SimpleForm></Create>
  }

}
