// in src/App.js
import * as React from "react";
import { Admin, Resource, ListGuesser } from 'react-admin'; // ListGuesser EditGuesser
import DataProvider from './dataProvider';
import LoginPage from './screens/Login';
import authProvider from './authProvider';
import { HomeList } from './screens/Home';
import { UserList, UserEdit, UserCreate } from './screens/Users';
import { SourcesList, SourceEdit, SourceCreate, SourceEventList } from './screens/Sources';
import { MappingList, MappingEdit, MappingCreate } from './screens/Mapping';
import { PatientResults } from './screens/PatientResults';
import { ToastContainer } from 'react-toastify';
import { Theme } from './components/Theme';
import { ResultsTheme } from './components/Theme';
import { PatientCheckInList } from './screens/PatientCheckIn.js';
import { PatientCheckInResultsList } from './screens/PatientCheckInResults.js';
import { TenantList, EditTenant, CreateTenant } from './screens/Tenants.js';
import { TestTypeListList, TestTypeEdit, TestTypeCreate } from './screens/TestTypes.js';
import { LocationList, LocationCreate, LocationEdit } from './screens/Locations.js';

import { UploadsList, UploadForm } from './screens/Uploads.js';

import Layout from './components/Layout';
import { Route } from 'react-router-dom';

const customRoutes = [
  <Route exact path="/upload/create" component={UploadForm} />,
  //<Route exact path="/datemath/calculations/create/advanced" component={DateMathAdvancedCreate} />
  //<Route exact path="/products/clearanceMemo" component={EditClearanceMemo} />
];

const App = () => <Admin customRoutes={customRoutes} theme={ window.location.hash.indexOf('patient/results') > 0 ? ResultsTheme : Theme} layout={Layout} authProvider={authProvider} loginPage={LoginPage} dataProvider={DataProvider}>
  <Resource label="Home" name="result" list={HomeList} />
  <Resource label="Lab Sources" name="source" list={SourcesList} edit={SourceEdit} create={SourceCreate} />
  <Resource name="user" list={UserList} edit={UserEdit} create={UserCreate} />
  <Resource name="mapping" list={MappingList} edit={MappingEdit} create={MappingCreate} />
  <Resource name="source/event" list={SourceEventList} />
  <Resource name="patient/results" list={PatientResults} />
  <Resource name="patient/checkIn" list={PatientCheckInList} />
  <Resource name="patient/patientCheckedInResults" list={PatientCheckInResultsList} />
  <Resource name="patient/existing" />
  <Resource name="location" list={LocationList} edit={LocationEdit} create={LocationCreate} />
  <Resource name="test/type" list={TestTypeListList} edit={TestTypeEdit} create={TestTypeCreate}  />
  <Resource name="tenant" list={TenantList} edit={EditTenant} create={CreateTenant} />
  <Resource name="mapping/standard/columns" list={ListGuesser}  />
  <Resource name="mapping/standard/results" list={ListGuesser}  />
  <Resource name="upload" list={UploadsList} />
  <ToastContainer />
</Admin>

export default App;
