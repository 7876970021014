import React, {Fragment}  from 'react';
import {List, Datagrid, TextField, TopToolbar, CreateButton, RefreshButton,
SimpleForm, Edit, TextInput, Link, Create, required, BooleanInput, BooleanField} from 'react-admin';
import Box from '@material-ui/core/Box';

const TestTypeActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            conname: 'button',
        }) }
        <CreateButton basePath={basePath} />
        <RefreshButton />
    </TopToolbar>
);
function truncate(input, truncateLength) {
  if (input && input.length > truncateLength) {
    return input.substring(0, truncateLength) + '...';
  }
  return input;
};
const DescriptionField = ({ record = {} }) => <span>{truncate(record.description, 150)}</span>;
const AnalysisDetectedField = ({ record = {} }) => <span>{truncate(record.analysisDetected, 150)}</span>;
const AnalysisNotDetectedField = ({ record = {} }) => <span>{truncate(record.analysisNotDetected, 150)}</span>;
const AnalysisInconclusiveField = ({ record = {} }) => <span>{truncate(record.analysisInconclusive, 150)}</span>;
const AnalysisUnsatisfactoryField = ({ record = {} }) => <span>{truncate(record.analysisUnsatisfactory, 150)}</span>;
export const TestTypeListList = props => (
    <List {...props} title="Test Types" actions={<TestTypeActions />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <DescriptionField source="description" />
            <AnalysisDetectedField source="analysisDetected" label="Analysis Detected" />
            <AnalysisNotDetectedField source="analysisNotDetected" label="Analysis Not Detected" />
            <AnalysisInconclusiveField source="analysisInconclusive" label="Analysis Inconclusive" />
            <AnalysisUnsatisfactoryField source="analysisUnsatisfactory" label="Analysis Unsatisfactory" />
            <BooleanField source="autoResult" label="Auto Result" />
        </Datagrid>
    </List>
);

const EditTestTypeTitle = ({ record }) => {
  return <span>{record && record.id > 0 ? `Edit Test Type ${record.name} (${record.id})` : 'Create Test Type'}</span>;
};
const EditTestTypeBreadCrumb = ({ record }) => {
  return  <div className="breadcrumbs"><Link to={"/test/type"}>Test Types</Link> &nbsp;>{record && record.id > 0 ? ` Edit Test Type: ${record.name} (${record.id})` : 'Create'}</div>;
};

class TestTypeForm extends React.Component {
  render() {
    return (
      <SimpleForm {...this.props}>
        <Fragment>
          <EditTestTypeBreadCrumb {...this.props} />
          <Box display="flex" justifyContent="center" style={{ width: '100%' }}>
            <Box display="flex-row"  style={{ width: '65%', marginLeft: '1%', marginRight: '1%', }}>
              <TextInput multiline options={{ fullWidth: true }}  source="name" validate={required("Name cannot be blank")}  />
              <TextInput multiline options={{ fullWidth: true }}  source="description" validate={required("Description cannot be blank")}  />
              <TextInput multiline options={{ fullWidth: true }}  source="analysisDetected" validate={required("Analysis Detected cannot be blank")} label="Analysis Detected" />
              <TextInput multiline options={{ fullWidth: true }}  source="analysisNotDetected" validate={required("Analysis Not Detected cannot be blank")} label="Analysis Not Detected" />
              <TextInput multiline options={{ fullWidth: true }}  source="analysisInconclusive" validate={required("Analysis Inconclusive cannot be blank")} label="Analysis Inconclusive" />
              <TextInput multiline options={{ fullWidth: true }}  source="analysisUnsatisfactory" validate={required("Analysis Unsatisfactory cannot be blank")}  label="Analysis Unsatisfactory" />
            </Box>
            <Box style={{ width: '34%', margin: '1%'}}>
              <BooleanInput source="autoResult" fullWidth label="Auto Result" />
            </Box>
          </Box>
        </Fragment>
      </SimpleForm>
    )
  }
}
export const TestTypeEdit = props => (
  <Edit undoable={false} title={<EditTestTypeTitle />}  {...props}>
      <TestTypeForm {...props} />
  </Edit>
);
export const TestTypeCreate = props => (
  <Create undoable={false} title={<EditTestTypeTitle />}  {...props}>
      <TestTypeForm {...props} />
  </Create>
);
