import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { crudGetOne, UserMenu } from 'react-admin';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';

const myCustomIconStyle = {
    avatar: {
        height: 30,
        width: 30,
        background: localStorage.getItem('imageUrl') ? 'url(' + localStorage.getItem('imageUrl') + ')' : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
    }
};
const MyCustomIcon = withStyles(myCustomIconStyle)(({ fullName, classes }) => (
        <Fragment>
          <span style={{fontSize: 'medium', width: '200px', textAlign: 'right', paddingRight: '5px'}}>{fullName}</span>
          <Avatar className={classes.avatar} />
        </Fragment>
    )
);

class UserMenuView extends Component {

    fetchProfile = () => {
        this.props.crudGetOne(
            // The resource
            'profile',
            // The id of the resource item to fetch
            'profile',
            // The base path. Mainly used on failure to fetch the data
            '/profile',
            // Whether to refresh the current view. I don't need it here
            true
        );
    };

    render() {
        const { crudGetOne, profile, ...props } = this.props;
        const fullName = (localStorage.getItem('name') ? localStorage.getItem('name') : "");
        return (
            window.location.hash.indexOf('patient/results') > 0 ? <span></span> :
            <UserMenu id="UserMenu" icon={<MyCustomIcon fullName={fullName ? fullName : ''} />} {...props}>
            </UserMenu>
        );
    }
}

const mapStateToProps = state => {
    const resource = 'profile';
    const id = 'my-profile';
    const profileState = state.admin.resources[resource];

    return {
        profile: profileState ? profileState.data[id] : null
    };
};

const LacentaUserMenu = connect(
    mapStateToProps,
    { crudGetOne }
)(UserMenuView);
export default LacentaUserMenu;
