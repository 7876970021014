import React, {Fragment}  from 'react';
import {
  List, Datagrid, TextField, Link, Create, Pagination, ArrayField,
  SingleFieldList, SelectInput,  CreateButton, TopToolbar,
  RefreshButton, Edit, SimpleForm, TextInput, BooleanInput,
  required } from 'react-admin';
import Box from '@material-ui/core/Box';
import Moment from 'moment';
import Chip from '@material-ui/core/Chip';
import TrueIcon from '@material-ui/icons/Done';
import FalseIcon from '@material-ui/icons/Clear';

const TenantActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            conname: 'button',
        }) }
        <CreateButton basePath={basePath} />
        <RefreshButton />
    </TopToolbar>
);

export const TenantList = props => (
    <List {...props} sort={{ field: 'id', order: 'DESC' }} actions={<TenantActions />} bulkActionButtons={false} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="businessName" />
            <TextField source="phone" />
            <TextField source="physicianName" />
            <TextField source="physicianLastName" label="Physician Last Name" />
            <TextField source="address1" />
            <TextField source="address2" />
            <TextField source="city" />
            <TextField source="state" />
            <TextField source="zipcode" />
            <TextField source="npi" />
            <TextField source="clia" label="CLIA" />
        </Datagrid>
    </List>
);

const EditTenantTitle = ({ record }) => {
  return <span>{record && record.id > 0 ? `Edit Tanant ${record.businessName} (${record.id})` : 'Create Tenant'}</span>;
};
const EditTenantBreadCrumb = ({ record }) => {
  return  <div className="breadcrumbs"><Link to={"/tenant"}>Tenants</Link> &nbsp;> {record && record.id > 0 ? ` Edit Tenant: ${record.businessName} (${record.id})` : 'Create'}</div>;
};
const phoneNumberFormat = (num) => {
    let newNum = num.replace(/[.]+/g, '');
    let x;
    if (newNum.length <= 3) {
        x = newNum;
    } else if (newNum.length > 3 && newNum.length <= 6) {
        x = newNum.slice(0, 3) + "." + newNum.slice(3, 6)
    } else {
        x = newNum.slice(0, 3) + "." + newNum.slice(3, 6) + "." + newNum.slice(6, 10)
    }
    return x;
};
const validateBusiness = [required("Business name must be unique and cannot be blank")];
const validatePhone = [required("Phone number cannot be blank")];


let stateList = [
  { id: "AK", name: "Alaska" },
  { id: "AL", name: "Alabama" },
  { id: "AR", name: "Arkansas" },
  { id: "AS", name: "American Samoa" },
  { id: "AZ", name: "Arizona" },
  { id: "CA", name: "California" },
  { id: "CO", name: "Colorado" },
  { id: "CT", name: "Connecticut" },
  { id: "DC", name: "District of Columbia" },
  { id: "DE", name: "Delaware" },
  { id: "FL", name: "Florida" },
  { id: "GA", name: "Georgia" },
  { id: "GU", name: "Guam" },
  { id: "HI", name: "Hawaii" },
  { id: "IA", name: "Iowa" },
  { id: "ID", name: "Idaho" },
  { id: "IL", name: "Illinois" },
  { id: "IN", name: "Indiana" },
  { id: "KS", name: "Kansas" },
  { id: "KY", name: "Kentucky" },
  { id: "LA", name: "Louisiana" },
  { id: "MA", name: "Massachusetts" },
  { id: "MD", name: "Maryland" },
  { id: "ME", name: "Maine" },
  { id: "MI", name: "Michigan" },
  { id: "MN", name: "Minnesota" },
  { id: "MO", name: "Missouri" },
  { id: "MS", name: "Mississippi" },
  { id: "MT", name: "Montana" },
  { id: "NC", name: "North Carolina" },
  { id: "ND", name: "North Dakota" },
  { id: "NE", name: "Nebraska" },
  { id: "NH", name: "New Hampshire" },
  { id: "NJ", name: "New Jersey" },
  { id: "NM", name: "New Mexico" },
  { id: "NV", name: "Nevada" },
  { id: "NY", name: "New York" },
  { id: "OH", name: "Ohio" },
  { id: "OK", name: "Oklahoma" },
  { id: "OR", name: "Oregon" },
  { id: "PA", name: "Pennsylvania" },
  { id: "PR", name: "Puerto Rico" },
  { id: "RI", name: "Rhode Island" },
  { id: "SC", name: "South Carolina" },
  { id: "SD", name: "South Dakota" },
  { id: "TN", name: "Tennessee" },
  { id: "TX", name: "Texas" },
  { id: "UT", name: "Utah" },
  { id: "VA", name: "Virginia" },
  { id: "VI", name: "Virgin Islands" },
  { id: "VT", name: "Vermont" },
  { id: "WA", name: "Washington" },
  { id: "WI", name: "Wisconsin" },
  { id: "WV", name: "West Virginia" },
  { id: "WY", name: "Wyoming" }
]

class TenantForm extends React.Component {
  render() {
    return (
      <SimpleForm {...this.props}>
        <Fragment>
          <Box display="flex" justifyContent="center" style={{ width: '98%', marginLeft: '1%', marginRight: '1%', }}>
            <Box display="flex-row">
                <EditTenantBreadCrumb {...this.props} />                
                <BooleanInput source="showRegLocations" label="Show Locations on Registration" />
                <TextInput source="businessName" validate={validateBusiness} />
                <TextInput source="email" validate={required("Email is mandatory and cannot be blank")}  />
                <TextInput source="physicianName" label="Physician Name" validate={required("Physician Name is mandatory and cannot be blank")} />
                <TextInput source="physicianLastName" label="Physician Last Name" validate={required("Physician Last Name is mandatory and cannot be blank")} />
                <TextInput parse={phoneNumberFormat} label="Phone" source="phone" validate={validatePhone} />
                <TextInput source="address1" validate={required("Address 1 is mandatory and cannot be blank")} />
                <TextInput source="address2" />
                <TextInput source="city" validate={required("City is mandatory and cannot be blank")} />
                <SelectInput choices={stateList} source="state" validate={required("State is mandatory and cannot be blank")} />
                <TextInput source="zipcode" validate={required("Zipcode is mandatory and cannot be blank")} />
                <TextInput source="npi" validate={required("NPI is mandatory and cannot be blank")} />
                <TextInput source="clia" label="CLIA" validate={required("CLIA is mandatory and cannot be blank")} />
            </Box>
          </Box>
        </Fragment>
      </SimpleForm>
    )
  }
}
export const EditTenant = props => (
  <section className='section'>
    <Edit undoable={false} title={<EditTenantTitle />}  {...props}>
        <TenantForm {...props} />
    </Edit>
  </section>
);
export const CreateTenant = props => (
  <section className='section'>
    <Create undoable={false} title={<EditTenantTitle />}  {...props}>
        <TenantForm {...props} />
    </Create>
  </section>
);
const ChipRole = ({ record = {} }) => <Chip clickable label={
  record.role === 'ROLE_ADMIN' ? 'Admin' : record.role === 'ROLE_USER' ? 'User' : record.role === 'ROLE_TENANT_ADMIN' ? 'Tenant Admin' : ''}
/>

const TenantUserActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <CreateButton basePath={basePath} />
        <RefreshButton />
    </TopToolbar>
);
const IsEnabled = ({ record }) => {
  return <span>{record && !record.isLocked ? <TrueIcon/> : <FalseIcon/>}</span>
};
const TenantUsersPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
const CreatedDateFormat = ({ record = {} }) => <span>{record.createdDatetime ? Moment(record.createdDatetime).format('DD MMM YYYY') : ""}</span>;
const ModifiedDateFormat = ({ record = {} }) => <span>{record.modifiedDatetime ? Moment(record.modifiedDatetime).format('DD MMM YYYY HH:MM:SS') : ""}</span>;

export const TenantUserList = props => (
    <List sort={{ field: 'id', order: 'DESC' }} perPage={50} {...props} actions={<TenantUserActions />} pagination={< TenantUsersPagination />}  bulkActionButtons={false} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="firstName" label="First Name" />
            <TextField source="lastName" label="Last Name"/>
            <TextField source="email" />
            <IsEnabled source="isLocked" label="Account is Enabled"  />
            <ArrayField source="roles" linkType={false} ><SingleFieldList><ChipRole source="role" /></SingleFieldList></ArrayField>
            <CreatedDateFormat source="createdDatetime" label="Created" />
            <ModifiedDateFormat source="modifiedDatetime" label="Modified" />
        </Datagrid>
    </List>
);
