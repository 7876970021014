import React, {Fragment}  from 'react';
import {
  List, Datagrid, TextField, PasswordInput, Pagination, Link,
  ReferenceManyField, Show, ReferenceField,  CreateButton, TopToolbar,
  RefreshButton, ExportButton, BooleanInput, NumberInput,
  SimpleForm, Create, TextInput, ReferenceInput, SelectInput,
  RadioButtonGroupInput, maxLength, required, Edit } from 'react-admin';
import { useForm, useField } from 'react-final-form';
import Breadcrumbs from '../components/Breadcrumbs'
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup'
import CircularProgress from '@material-ui/core/CircularProgress';
import RunIcon from '@material-ui/icons/Stop';
import FalseIcon from '@material-ui/icons/FiberManualRecord';
import 'react-toastify/dist/ReactToastify.css';
import JSONPretty from 'react-json-pretty';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Config from '../config'
import Moment from 'moment';

const SourcesActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        { false && <ExportButton
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filter={filterValues}
              exporter={exporter}
        /> }
        <CreateButton basePath={basePath} />
        <RefreshButton />
    </TopToolbar>
);

const EditSourceTitle = ({ record }) => {
  return <span>Edit {record ? `${record.name} (${record.id})` : ''} </span>;
};
//const CroppedPayloadField = ({ record }) => {
  //return <span>{!record ? '' : record && record.payload && record.payload.length > 50 ? `${record.payload.substring(0, 50)}...` : record && record.payload ? `${record.payload}` : '' }</span>;
//};
const PayloadField = ({ record }) => {
  return <JSONPretty id="json-pretty" data={!record ? '' : record && record.payload ? record.payload : `` }></JSONPretty>
};
export const SourcesEditColumns = props => (
  <SourcesEditColumnsSection {...props} />
);
const EditSourceBreadCrumb = ({ record }) => {
  return  <div className="breadcrumbs"><Link to={"/source"}>Lab Sources</Link> &nbsp;> Edit Lab Source: {record ? `${record.name} (${record.id})` : ''}</div>;
};
class SourcesEditColumnsSection extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isSourceLoading : false,
      isCreate : !(this.props.id != null && this.props.id > 0)
    }
  }
  render() {
    const {
        isSourceLoading,
        isCreate
    } = this.state;
    const that = this;
    function SourceForm() {
      const sourceForm = useForm();
      const pollingDays = useField("pollingDays", sourceForm);
      const id = useField("id", sourceForm);
      const isRunning = useField("isRunning", sourceForm);
      const isEnabled = useField("isEnabled", sourceForm);
      const testMode = useField("testMode", sourceForm);
      const name = useField("name", sourceForm);
      const username = useField("username", sourceForm);
      const password = useField("password", sourceForm);
      const hostname = useField("hostname", sourceForm);
      const path = useField("path", sourceForm);
      const port = useField("port", sourceForm);
      const ext = useField("ext", sourceForm);
      const protocol = useField("protocol", sourceForm);
      const pollingFrequency = useField("pollingFrequency", sourceForm);
      const mappingId = useField("mappingId", sourceForm);

      const testSource = async values => {
        toast.info("Testing source...", {
          position: toast.POSITION.BOTTOM_CENTER,
          closeButton: false,
          hideProgressBar: true
        });
        return fetch(Config.REACT_APP_API_URL + "/source/test", {
            method: "POST",
            headers: new Headers({'Content-Type': 'application/json', 'Authorization': localStorage.getItem('token')}),
            body: JSON.stringify({
                id: id.input.value,
                isEnabled: isEnabled.input.value,
                testMode: testMode.input.value,
                name: name.input.value,
                username: username.input.value,
                password: password.input.value,
                hostname: hostname.input.value,
                path: path.input.value,
                ext: ext.input.value,
                port: port.input.value,
                protocol: protocol.input.value,
                pollingDays: pollingDays.input.value,
                pollingFrequency: pollingFrequency.input.value,
                mappingId: mappingId.input.value
            })
        })
        .then((response) => response.json())
        .then((connectionResult) => {
          toast.dismiss();
          if (!connectionResult.isLegitConnection || !connectionResult.isLegitDirectory) {
            toast.error("Connection: " + connectionResult.isLegitConnection + " | Directory: " + connectionResult.isLegitDirectory , {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: true,
              hideProgressBar: false,
              autoClose: 2000
            });
          } else {
            toast.success("Connection: " + connectionResult.isLegitConnection + " | Directory: " + connectionResult.isLegitDirectory , {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: true,
              hideProgressBar: false,
              autoClose: 2000
            });
          }
        })
      }
      const IsRunning = () => {
        return <>{isRunning.input.value === true ? <RunIcon style={{ fontSize: '30px', color: 'green'}} /> : <FalseIcon style={{ fontSize: '30px', color: 'red'}} /> }{isRunning.input.value === true && <span style={{ verticalAlign: 'top', fontSize: '24px'}}>Running</span>}{isRunning.input.value === false && <span style={{ verticalAlign: 'top', fontSize: '24px'}}>Not Running</span>}</>
      }
      const TestConnection = () => {
        return <>{(protocol.input.value !== "http") && <Button variant="contained" color="primary" label="Sunday" onClick={() => testSource()}>Test Connection</Button>}</>
      }
      const validatePaths = (value, allValues) => {
          if (allValues.protocol !== "http" && (!value || value.length <= 0)) {
              return 'Path is required'
          }
          return null
      };
      const validateExtInput = (value, allValues) => {
          if (allValues.protocol !== "http" && (!value || value.length <= 0)) {
              return 'Ext is required'
          }
          return null
      };
      const isNotHttpEnabled = () => {
          if (protocol.input.value === "http")
            return true
          return false;
      }

      const PollingDays = ({ record }) => {
        that.toggleDay = (toggleDay, sourceForm) => {
          var pollingDaysString = ""
          var isSunday = ((pollingDays.input.value.indexOf('SUN') >= 0 && toggleDay === 'SUN') ? false : toggleDay === 'SUN' ? true : pollingDays.input.value.indexOf('SUN') >= 0)
          var isMonday = ((pollingDays.input.value.indexOf('MON') >= 0 && toggleDay === 'MON') ? false : toggleDay === 'MON' ? true : pollingDays.input.value.indexOf('MON') >= 0)
          var isTuesday = ((pollingDays.input.value.indexOf('TUE') >= 0 && toggleDay === 'TUE') ? false : toggleDay === 'TUE' ? true : pollingDays.input.value.indexOf('TUE') >= 0)
          var isWednesday = ((pollingDays.input.value.indexOf('WED') >= 0 && toggleDay === 'WED') ? false : toggleDay === 'WED' ? true :  pollingDays.input.value.indexOf('WED') >= 0)
          var isThursday = ((pollingDays.input.value.indexOf('THU') >= 0 && toggleDay === 'THU') ? false : toggleDay === 'THU' ? true :  pollingDays.input.value.indexOf('THU') >= 0)
          var isFriday = ((pollingDays.input.value.indexOf('FRI') >= 0 && toggleDay === 'FRI') ? false : toggleDay === 'FRI' ? true :  pollingDays.input.value.indexOf('FRI') >= 0)
          var isSaturday = ((pollingDays.input.value.indexOf('SAT') >= 0 && toggleDay === 'SAT') ? false : toggleDay === 'SAT' ? true :  pollingDays.input.value.indexOf('SAT') >= 0)
          if (isSunday)
            pollingDaysString += "SUN"
          if (isMonday)
              pollingDaysString += (pollingDaysString.length > 0 ? "|" :"") + "MON";
          if (isTuesday)
              pollingDaysString += (pollingDaysString.length > 0 ? "|" :"") + "TUE";
          if (isWednesday)
              pollingDaysString += (pollingDaysString.length > 0 ? "|" :"") + "WED";
          if (isThursday)
              pollingDaysString += (pollingDaysString.length > 0 ? "|" :"") + "THU";
          if (isFriday)
              pollingDaysString += (pollingDaysString.length > 0 ? "|" :"") + "FRI";
          if (isSaturday)
              pollingDaysString += (pollingDaysString.length > 0 ? "|" :"") + "SAT";
          sourceForm.change('pollingDays',  pollingDaysString);
        }
        return <span>
          <ButtonGroup style={{ paddingTop: '10px' }} size="small" disableElevation variant="contained" color="primary" aria-label="small primary button group">
            <Button color={pollingDays.input.value.indexOf('SUN') >= 0 ? "primary" : "default"} label="Sunday" onClick={() => that.toggleDay("SUN", sourceForm)}>Sunday</Button>
            <Button color={pollingDays.input.value.indexOf('MON') >= 0 ? "primary" : "default"} label="Monday" onClick={() => that.toggleDay("MON", sourceForm)}>Monday</Button>
            <Button color={pollingDays.input.value.indexOf('TUE') >= 0 ? "primary" : "default"} label="Tuesday" onClick={() => that.toggleDay("TUE", sourceForm)}>Tuesday</Button>
            <Button color={pollingDays.input.value.indexOf('WED') >= 0 ? "primary" : "default"} label="Wednesday" onClick={() => that.toggleDay("WED", sourceForm)}>Wednesday</Button>
            <Button color={pollingDays.input.value.indexOf('THU') >= 0 ? "primary" : "default"} label="Thursday" onClick={() => that.toggleDay("THU", sourceForm)}>Thursday</Button>
            <Button color={pollingDays.input.value.indexOf('FRI') >= 0 ? "primary" : "default"} label="Friday" onClick={() => that.toggleDay("FRI", sourceForm)}>Friday</Button>
            <Button color={pollingDays.input.value.indexOf('SAT') >= 0 ? "primary" : "default"} label="Saturday" onClick={() => that.toggleDay("SAT", sourceForm)}>Saturday</Button>
          </ButtonGroup>
        </span>;
      };
      const usernameFormatter = username => {
        if (!username || username === "") return;
        return decodeURIComponent(username)
      };
      const CreatedDateFormat = ({ record = {} }) => <span>{record.createdDatetime ? Moment(record.createdDatetime).format('DD MMM YYYY hh:mm:ss A') : ""}</span>;
      return (
        <>{ isSourceLoading ? <CircularProgress style={{ marginLeft: '2em'}}
        size={24}
        thickness={2}
        /> : <><Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
          <Box flexDirection="row" width="40%" justifyContent="space-between" style={{ width: '100%' }}>
            <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
              <Box style={{ marginRight: '1%'}} width="49%">
                <TextInput style={{ marginTop: '4%' }} source="name" validate={[required(), maxLength(100)]} fullWidth />
                <TextInput source="hostname" validate={[required(), maxLength(255)]} fullWidth />
                <TextInput source="username" parse={usernameFormatter} validate={[required()]} fullWidth />
                <PasswordInput source="password" fullWidth validate={[required()]} />
                <NumberInput source="port" validate={[required(), maxLength(10)]} fullWidth />
                <TextInput source="path" label="Path *" disabled={isNotHttpEnabled()} validate={[validatePaths, maxLength(255)]} fullWidth />
                <TextInput source="ext" label="Ext *"  disabled={isNotHttpEnabled()} validate={[validateExtInput, maxLength(10)]} fullWidth />
                <TextInput source="id" fullWidth style={{ display: 'none' }} />
                <TextInput source="tenantId" label="Tenant Id" style={{ display: 'none' }}/>
              </Box>
              <Box width="50%">
                <RadioButtonGroupInput source="protocol" validate={[required(), maxLength(10)]} choices={[
                    { id: 'ftp', name: 'FTP' },
                    { id: 'sftp', name: 'SFTP' },
                    { id: 'http', name: 'HTTP' }
                ]} fullWidth />
                <TextInput source="pollingFrequency" validate={[required(), maxLength(10)]} fullWidth />
                <ReferenceInput source="mappingId"  reference="mapping"><SelectInput validate={[required()]} fullWidth optionText="name" /></ReferenceInput>
                <TextInput source="pollingDays" fullWidth style={{ display: 'none' }} />
                  <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                    <Box flexDirection="row" width="40%" justifyContent="space-between" style={{ width: '100%' }}>
                      <Box style={{ width: '99%', margin: '1%'}}>
                        <BooleanInput source="isEnabled" label="Enabled" />
                      </Box>
                      <Box style={{ width: '99%', margin: '1%'}}>
                        <BooleanInput source="testMode" label="Test Mode" />
                      </Box>
                      <Box style={{ width: '99%', margin: '1%'}}>
                        { !isCreate && <IsRunning /> }
                      </Box>
                      <Box style={{ width: '99%', margin: '1%', marginTop: '5%'}}>
                        <TestConnection />
                      </Box>
                    </Box>
                  </Box>
              </Box>
            </Box>
            <Box display="flex" justifyContent="space-between" style={{ backgroundColor: '#e5e5e5', width: '100%' }}>
              <Box style={{ margin: '1%'}} width="98%">
                <Typography style={{ fontSize: "18px "}} >Polling Days</Typography>
                <PollingDays fullWidth />
              </Box>
            </Box>
          </Box>
            { !isCreate && <Box style={{ marginLeft: "1%", width: '59%', maxHeight: '65vh', overflow: 'scroll' }} width="59%">
              <Typography style={{ fontSize: "18px "}} >Event Log</Typography>
              <Show actions={null} {...that.props}>
              <ReferenceManyField
                reference="source/event"
                target="id"
                label="Logs"
              >
                <Datagrid expand={<PayloadField />}>
                  <TextField source="level" />
                  <TextField source="eventType" />
                  <CreatedDateFormat source="createdDatetime" label="Created" />
                </Datagrid>
             </ReferenceManyField>
             </Show>
           </Box> }
         </Box></>
        } </>
      );
    }
    return isCreate ? <Create {...this.props}>
          <SimpleForm initialValues = {{ "isEnabled" : "true", "protocol": "sftp", "tenantId" : "1"}}>
            <SourceForm {...this.props} />
          </SimpleForm>
        </Create> : <Edit title={<EditSourceTitle />} undoable={false}  {...this.props}>
              <SimpleForm >
                <EditSourceBreadCrumb />
                <SourceForm {...this.props} />
              </SimpleForm>
            </Edit>
  }
}

export const SourceEdit = props => (
  <section className='section'>
    <SourcesEditColumns {...props}  />
  </section>
);

export const SourceCreate = props => (
  <section className='section'>
    <Breadcrumbs />
    <SourcesEditColumns {...props} />
  </section>
);

const CreatedDateFormat = ({ record = {} }) => <span>{record.createdDatetime ? Moment(record.createdDatetime).format('DD MMM YYYY hh:mm:ss A') : ""}</span>;
export const SourceEvents = props => (
  <Show  actions={null}  {...props}>
  <ReferenceManyField
    reference="source/event"
    target="id"
    label="Logs"
  >
    <Datagrid expand={<PayloadField />}>
      <TextField source="level" />
      <TextField source="eventType" />
      <CreatedDateFormat source="createdDatetime" label="Created" />
    </Datagrid>
 </ReferenceManyField>
 </Show>
);
//expand={<SourcesEditColumns {...props}  />}
const PollingDays = ({ record }) => {
  return <span>
    <ButtonGroup style={{ paddingTop: '10px' }} size="small" disableElevation variant="contained" color="primary" aria-label="small primary button group">
      <Button color={record.pollingDays.indexOf('SUN') >= 0 ? "primary" : "default"} label="Sunday">S</Button>
      <Button color={record.pollingDays.indexOf('MON') >= 0 ? "primary" : "default"} label="Monday">M</Button>
      <Button color={record.pollingDays.indexOf('TUE') >= 0 ? "primary" : "default"} label="Tuesday">T</Button>
      <Button color={record.pollingDays.indexOf('WED') >= 0 ? "primary" : "default"} label="Wednesday">W</Button>
      <Button color={record.pollingDays.indexOf('THU') >= 0 ? "primary" : "default"} label="Thursday">T</Button>
      <Button color={record.pollingDays.indexOf('FRI') >= 0 ? "primary" : "default"} label="Friday">F</Button>
      <Button color={record.pollingDays.indexOf('SAT') >= 0 ? "primary" : "default"} label="Saturday">S</Button>
    </ButtonGroup>
  </span>;
};
const SourcesPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

const IsRunning =  ({ record }) => {
  return <>{record.isRunning === true ? <RunIcon style={{ fontSize: '30px', color: 'green'}} /> : <FalseIcon style={{ fontSize: '30px', color: 'red'}} /> }</>
}
export const SourcesList = props => (
    <List sort={{ field: 'id', order: 'DESC' }} perPage={50} title="Lab Sources"  {...props} actions={<SourcesActions />} pagination={< SourcesPagination />} bulkActionButtons={false}>
        <Datagrid rowClick="edit" expand={SourceEvents}>
            <TextField source="id" />
            <IsRunning sortable={false} source="isRunning" label="Running" />
            <TextField source="name" />
            <TextField source="hostname"  />
            <ReferenceField label="Mapping" source="mappingId" reference="mapping" link={false}>
              <TextField source="name" />
            </ReferenceField>
            <TextField source="path"  />
            <TextField source="ext"  />
            <TextField source="protocol" />
            <TextField source="pollingFrequency"  />
            <PollingDays source="pollingDays"  />
        </Datagrid>
    </List>
);

export const SourceEventList = props => (
    <List {...props}>
        <Datagrid rowClick={false}>
            <TextField source="level" />
            <TextField source="eventType" />
            <TextField source="payload" />
            <CreatedDateFormat source="createdDatetime" label="Created" />
        </Datagrid>
    </List>
);
