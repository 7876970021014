import React, {useEffect}  from 'react';
import { List, Datagrid, TextField, SimpleForm,
  FileInput, FileField, Pagination, TopToolbar, RefreshButton,
  Button, LinearProgress, useDataProvider, ReferenceField } from 'react-admin';
import IconSave from '@material-ui/icons/Save';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import IconCloudUpload from '@material-ui/icons/CloudUpload';
import { toast } from 'react-toastify';
import { useForm, useField } from 'react-final-form';
import * as Config from '../config'
import Moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextFieldCore from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import ReactDataGrid from 'react-data-grid';
import Tooltip from '@material-ui/core/Tooltip';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import { useHistory } from "react-router-dom";
import FileIcon from '@material-ui/icons/FileCopy';

const ListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <Link to={{pathname: '/upload/create', search: stringify({
                      filter: JSON.stringify({ tenantId: localStorage.getItem('tenantId') }),
        })}}><Button label="Upload"><IconCloudUpload /></Button></Link>
        <RefreshButton />
    </TopToolbar>
);
// <UploadForm />

const CreatedDateFormat = ({ record = {} }) => <span>{record.createdDatetime ? Moment(record.createdDatetime).format('DD MMM YYYY') : ""}</span>;
const ModifiedDateFormat = ({ record = {} }) => <span>{record.modifiedDatetime ? Moment(record.modifiedDatetime).format('DD MMM YYYY hh:mm:ss A') : ""}</span>;

const UploadsPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

export const UploadsList = props => (
    <List actions={<ListActions />} pagination={< UploadsPagination />} perPage={50} title="Uploads" {...props} bulkActionButtons={false} >
        <Datagrid rowClick={false}>
            <TextField source="id" />
            {localStorage.permissions === "ROLE_ADMIN" && <ReferenceField sortable={false} label="Tenant" source="tenantId" reference="tenant" link={false}>
              <TextField source="businessName" />
            </ReferenceField> }
            <TextField source="filename" />
            <TextField source="status" />
            <TextField source="summary" sortable={false} />
            <CreatedDateFormat source="createdDatetime" label="Created" />
            <ModifiedDateFormat  source="modifiedDatetime" label="Modified" />
        </Datagrid>
    </List>
);

function toCamelCase(str) {
  // Lower cases the string
  return str.toLowerCase()
    // Replaces any - or _ characters with a space
    .replace( /[-_]+/g, ' ')
    // Removes any non alphanumeric characters
    .replace( /[^\w\s]/g, '')
    // Uppercases the first character in each group immediately following a space
    // (delimited by spaces)
    .replace( / (.)/g, function($1) { return $1.toUpperCase(); })
    // Removes spaces
    .replace( / /g, '' );
}
//<TextField source="totalCount" />
//<TextField source="attemptCount" />
//<TextField source="errorCount" />

//const ToolTipLocationCode = props => {
  //return <Tooltip title={props.row.errorDisplay} aria-label={props.row.errorDisplay}><span >{props.row.locationCode}</span></Tooltip>
//}
//class LocationCodeRowFormatter extends React.Component {
  //render() { return (<ToolTipLocationCode {...this.props} />)}
//};
const ToolTipFirstName = props => {
  return <Tooltip title={props.row.patientFirstNameErrorDisplay} aria-label={props.row.patientFirstNameErrorDisplay}><span >{props.row.patientFirstName}</span></Tooltip>
}
class FirstNameRowFormatter extends React.Component {
  render() { return (<ToolTipFirstName {...this.props} />)}
};
const ToolTipLastName = props => {
  return <Tooltip title={props.row.patientLastNameErrorDisplay} aria-label={props.row.patientLastNameErrorDisplay}><span >{props.row.patientLastName}</span></Tooltip>
}
class LastNameRowFormatter extends React.Component {
  render() { return (<ToolTipLastName {...this.props} />)}
};
const ToolTipPhone = props => {
  return <Tooltip title={props.row.patientPhoneErrorDisplay} aria-label={props.row.patientPhoneErrorDisplay}><span >{props.row.patientPhone}</span></Tooltip>
}
class PhoneRowFormatter extends React.Component {
  render() { return (<ToolTipPhone {...this.props} />)}
};
const ToolTipEmail = props => {
  return <Tooltip title={props.row.patientEmailErrorDisplay} aria-label={props.row.patientEmailErrorDisplay}><span>{props.row.patientEmail}</span></Tooltip>
}
class EmailRowFormatter extends React.Component {
  render() { return (<ToolTipEmail {...this.props} />)}
};
const ToolTipDob = props => {
  return <Tooltip title={props.row.patientDateOfBirthErrorDisplay} aria-label={props.row.patientDateOfBirthErrorDisplay}><span >{props.row.patientDateOfBirth}</span></Tooltip>
}
class DOBRowFormatter extends React.Component {
  render() { return (<ToolTipDob {...this.props} />)}
};
const ToolTipResult = props => {
  return <Tooltip title={props.row.resultErrorDisplay} aria-label={props.row.resultErrorDisplay}><span >{props.row.result}</span></Tooltip>
}
class ResultRowFormatter extends React.Component {
  render() { return (<ToolTipResult {...this.props} />)}
};
const ToolTipCollectionDate = props => {
  return <Tooltip title={props.row.collectionDateErrorDisplay} aria-label={props.row.collectionDateErrorDisplay}><span >{props.row.collectionDate}</span></Tooltip>
}
class CollectionDateRowFormatter extends React.Component {
  render() { return (<ToolTipCollectionDate {...this.props} />)}
};


export class UploadForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialize: true,
      showUploadDialog: false,
      uploading : false,
      locationList: [{ id: 0, code : 'None Selected'}],
      testTypes: [{ id: 0, code : 'None Selected'}],
      mappingSourceList: [{ id: 0, name : 'None Selected'}],
      selectedTenantId : localStorage.getItem('tenantId'),
      testResults: {},
      errors: undefined,
      selectedMappingSource : undefined,
      selectedLocationCode : undefined,
      noErrorsReadyToUpload: false,
      resultsColumns : [
        { key: 'lineNumber', name: 'Line #' },
        //{ key: 'locationCode', name: 'Location'}, //, formatter: (props) => <LocationCodeRowFormatter {...props} length={5} />
        { key: 'patientFirstName', name: 'Patient First Name', formatter: (props) => <FirstNameRowFormatter {...props} length={5} /> },
        { key: 'patientLastName', name: 'Patient Last Name', formatter: (props) => <LastNameRowFormatter {...props} length={5} /> },
        { key: 'patientPhone', name: 'Patient Phone', formatter: (props) => <PhoneRowFormatter {...props} length={5} /> },
        { key: 'patientEmail', name: 'Patient Email', formatter: (props) => <EmailRowFormatter {...props} length={5} /> },
        { key: 'patientDateOfBirth', name: 'Patient Date of Birth', formatter: (props) => <DOBRowFormatter {...props} length={5} /> },
        { key: 'collectionDate', name: 'Collection Date', formatter: (props) => <CollectionDateRowFormatter {...props} length={5} /> },
        { key: 'result', name: 'Result', formatter: (props) => <ResultRowFormatter {...props} length={5} /> },
      ],
      resultsRows : [

      ]
    }
  }

  componentDidMount() {
  }
  render() {
    const {
      locationList,
      testTypes,
      tenants,
      mappingSourceList,
      testResults,
      resultsColumns,
      resultsRows,
      initialize,
      errors,
      selectedMappingSource,
      selectedLocationCode,
      selectedTestType,
      selectedTenant,
      selectedTenantId,
      noErrorsReadyToUpload
    } = this.state
    const self = this;
    function UploadDialog() {
      const [tenantId, setTenantId] = React.useState(selectedTenantId ? selectedTenantId : localStorage.getItem('tenantId'));
      const [tenant, setTenant] = React.useState(selectedTenant ? selectedTenant : localStorage.getItem('tenantId'));
      const [tenantInputValue, setTenantInputValue] = React.useState(selectedTenant ? selectedTenant.businessName : undefined);
      const [tenantError, setTenantError] = React.useState(undefined);
      const [testType, setTestType] = React.useState(selectedTestType ? selectedTestType : undefined);
      const [testTypeInputValue, setTestTypeInputValue] = React.useState(selectedTestType ? selectedTestType.code : undefined);
      const [locationCode, setLocationCode] = React.useState(selectedLocationCode ? selectedLocationCode : undefined);
      const [locationCodeInputValue, setLocationCodeInputValue] = React.useState(selectedLocationCode ? selectedLocationCode.code : undefined);
      const [mappingSource, setMappingSource] = React.useState(selectedMappingSource ? selectedMappingSource : undefined);
      const [mappingSourceInputValue, setMappingSourceInputValue] = React.useState(selectedMappingSource ? selectedMappingSource.name : undefined);
      const [locationCodeError, setLocationCodeError] = React.useState(undefined);
      const [mappingSourceError, setMappingSourceError] = React.useState(undefined);
      const [testTypeError, setTestTypeError] = React.useState(undefined);
      const dataProvider = useDataProvider();
      const form = useForm();
      const history = useHistory();
      //const dataProvider = useDataProvider();
      const file = useField("file", form);

      var isAdmin = false;
      if (localStorage.permissions === "ROLE_ADMIN")
        isAdmin = true;

      self.closeUploadDialog = () => {
        self.setState({showUploadDialog: false });
      }
      self.showUploadDialog = () => {
        if (isAdmin) {
          self.fetchAllTenants();
        } else {
          self.fetchAllTestTypes([])
        }
        self.setState({ uploading: true, showUploadDialog: true, testResults: {} });
      }
      // Similar to componentDidMount and componentDidUpdate:
     useEffect(() => {
       // Update the document title using the browser API
       if (initialize && mappingSourceList.length <= 1) {
         //console.log("Inside initialize")
         self.setState({ initialize: !initialize });
         self.showUploadDialog();
       }
     }, []);
     self.fetchAllTenants = () => {
      dataProvider.getList('tenant',{
            filter: { },
            sort: { field: 'id', order: 'DESC' },
            pagination: { page: 1, perPage: 5000 },
      }).then((returnedTenants) => {
         //self.fetchAllTestTypes(returnedTenants.data)
         self.setState({
             tenants: returnedTenants.data,
             uploading: false
         });
      }).catch(({ status, statusText, headers, body }) => {
        console.log("Caught status status ", status);
        console.log("Caught status statusText ", statusText);
      });
     }
      self.fetchAllTestTypes = (returnedTenants) => {
       dataProvider.getList('test/type',{
             filter: { },
             sort: { field: 'id', order: 'DESC' },
             pagination: { page: 1, perPage: 5000 },
       }).then((returnedTestTypes) => {
          self.fetchAllLocations(returnedTestTypes.data, returnedTenants)
       }).catch(({ status, statusText, headers, body }) => {
         console.log("Caught status status ", status);
         console.log("Caught status statusText ", statusText);
       });
      }
      self.fetchAllLocations = (returnedTestTypes, returnedTenants) => {
        dataProvider.getList('location/' + tenantId, {
              filter: { },
              sort: { field: 'id', order: 'DESC' },
              pagination: { page: 1, perPage: 5000 },
        }).then((returnedLocations) => {
          self.fetchAllMappingSources(returnedLocations.data, returnedTestTypes, returnedTenants)
        }).catch(({ status, statusText, headers, body }) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
        });
      }
      self.fetchAllMappingSources = (returnedLocations, returnedTestTypes, returnedTenants) => {
        dataProvider.getList('mapping',{
              filter: { },
              sort: { field: 'id', order: 'DESC' },
              pagination: { page: 1, perPage: 5000 },
        }).then((returnedMappingSources) => {
          var computedResultsRows = []
          self.setState({
              locationList: returnedLocations,
              testTypes: returnedTestTypes,
              tenants: returnedTenants,
              mappingSourceList: returnedMappingSources.data,
              uploading: false,
              resultsRows: computedResultsRows
          });
        }).catch((exception) => {
          console.log("Caught  exception ", exception);
        });
      }
      const handleFileChange = () => {
        self.setState({ noErrorsReadyToUpload: false, testResults : undefined, resultsRows: undefined})
      }
      const handleTenantChange = (event, newValue) => {
        //console.log("Inside handleTenantChange ", newValue)
        if (event.target.innerHTML && event.target.innerHTML.indexOf("<path") >= 0)
          setTenant(undefined)
        else {
          if (newValue) {
            setTenant(newValue.id)
            setTenantInputValue(newValue.businessName)
            setTenantId(newValue.id)
            self.fetchAllTestTypes(tenants)
          } else {
            setTenant(undefined)
            setTenantInputValue(undefined)
          }
          self.setState({ selectedTenantId: newValue.id, selectedTenant: newValue})
        }
      }
      const handleTestTypeChange = (event, newValue) => {
        //console.log("Inside handleTestTypeChange ", newValue)
        if (event.target.innerHTML && event.target.innerHTML.indexOf("<path") >= 0)
          setTestType(undefined)
        else {
          if (newValue) {
            setTestType(newValue.id)
            setTestTypeInputValue(newValue.name)
          } else {
            setTestType(undefined)
            setTestTypeInputValue(undefined)
          }
          self.setState({ selectedTestType: newValue})
        }
      }
      const handleLocationCodeChange = (event, newValue) => {
        //console.log("Inside handleLocationCodeChange")
        if (event.target.innerHTML && event.target.innerHTML.indexOf("<path") >= 0)
          setLocationCode(undefined)
        else {
          if (newValue) {
            setLocationCode(newValue.id)
            setLocationCodeInputValue(newValue.code)
          } else {
            setLocationCode(undefined)
            setLocationCodeInputValue(undefined)
          }
          self.setState({ selectedLocationCode: newValue})
        }
      }
      const handleMappingSourceChange = (event, newValue) => {
        //console.log("Inside handleMappingSourceChange ", newValue)
        if (event.target.innerHTML && event.target.innerHTML.indexOf("<path") >= 0)
          setMappingSource(undefined)
        else {
          if (newValue) {
            setMappingSource(newValue.id)
            setMappingSourceInputValue(newValue.name)
          } else {
            setMappingSource(undefined)
            setMappingSourceInputValue(undefined)
          }
          self.setState({ selectedMappingSource: newValue})
        }
      }
      self.uploadMapping = () => {
        self.setState({ errors: undefined })
        var validationError = false
        if (!file.input.value.rawFile || file.input.value.rawFile.name.length > 200) {
          validationError = true
          self.setState({ errors: "File Name cannot exceed 200 characters", uploading: false })
        }
        if (!locationCode || locationCode == null) {
          if (document.getElementById('UploadLocationCode') && document.getElementById('UploadLocationCode').value && document.getElementById('UploadLocationCode').value.length > 0) {
            setLocationCode(document.getElementById('UploadLocationCode').value)
          } else {
            setLocationCodeError("Location is required")
            validationError = true
          }
        }
        if (!mappingSource || mappingSource == null) {
          if (document.getElementById('UploadMapping') && document.getElementById('UploadMapping').value && document.getElementById('UploadMapping').value.length > 0) {
            setMappingSource(document.getElementById('UploadMapping').value)
          } else {
            setMappingSourceError("Source is required")
            validationError = true
          }
        }
        if (!testType || testType == null) {
          if (document.getElementById('testType') && document.getElementById('testType').value && document.getElementById('testType').value.length > 0) {
            setTestType(document.getElementById('testType').value)
          } else {
            setTestTypeError("Test Type is required")
            validationError = true
          }
        }
        if (!tenant|| tenant == null) {
          if (document.getElementById('tenant') && document.getElementById('tenant').value && document.getElementById('tenant').value.length > 0) {
            setTenant(document.getElementById('tenant').value)
          } else {
            setTenantError("Tenant Selection is required")
            validationError = true
          }
        }
        if (!file.input.value) {
          var error = ""
          if (!file.input.value) {
            if (error.length > 0)
              error += " | "
            error += "No file selected for upload"
          }
          self.setState({ errors: error, mappingSource: mappingSource, locationCode : locationCode })
        } else if (!validationError) {
          self.setState({ uploading: true, testResults: {}})
          try {
            toast.info("Processing file, please wait...", {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: false,
              hideProgressBar: true
            });
            let formData = new FormData();
            formData.set("file", file.input.value.rawFile);
            fetch(Config.REACT_APP_API_URL + "/result/" + mappingSource.id + "/" + locationCode.id + "/" + selectedTenantId + "/" + testType.id + "/test", {
                method: "POST",
                headers: new Headers({'Authorization': localStorage.getItem('token')}),
                body: formData
            }).then(response => {
                return response.json()
            }).then(uploadResponse => {
               //console.log("registration response ", uploadResponse);
               if (!uploadResponse || (uploadResponse.success !== null && uploadResponse.success === false) || (uploadResponse.status && uploadResponse.status >= 400)) {
                  toast.dismiss()
                  self.setState({ errors: uploadResponse.message ? uploadResponse.message : uploadResponse.error, uploading: false })
               } else {
                 var computedResultsRows = []
                 var testResults = uploadResponse
                 if (testResults && testResults.rowErrors) {
                   var rowErrors = Object.keys(testResults.rowErrors)
                   for (var i = 0; i < rowErrors.length; i++) {
                       var resultRow = {};
                       resultRow['lineNumber'] = rowErrors[i]
                       for (var j = 0; j < testResults.rowErrors[rowErrors[i]].length; j++) {
                         var errorColumn = testResults.rowErrors[rowErrors[i]][j].columnName ? testResults.rowErrors[rowErrors[i]][j].columnName.toLowerCase() : ""
                         var errorDisplay = testResults.rowErrors[rowErrors[i]][j].errorDisplay ? testResults.rowErrors[rowErrors[i]][j].errorDisplay : 'Invalid'
                         var errorValue = testResults.rowErrors[rowErrors[i]][j].value ? testResults.rowErrors[rowErrors[i]][j].value : "blank"
                         //console.log("errorColumn ", errorColumn);
                         //console.log("errorDisplay ", errorDisplay);
                         //console.log("errorValue ", errorValue);
                         //if (testResults.successfulResults && testResults.successfulResults.length > 0) {
                           ///var testResultRow = testResults.successfulResults[rowErrors[i]-1]
                           //if (testResultRow && testResultRow['locationCode'])
                          //   resultRow['locationCode'] = testResultRow['locationCode']
                         //}
                         resultRow[toCamelCase(errorColumn)] = errorValue
                         var errorDisplayKey = toCamelCase(errorColumn) + 'ErrorDisplay'
                         resultRow[errorDisplayKey] = errorDisplay
                         //console.log("Object.keys(testResults.rowErrors)[i] ", Object.keys(testResults.rowErrors)[i]);
                         //console.log("Result Row to set ", Object.keys(testResults.rowErrors)[i][j]['columnName']);
                         //console.log("value ", testResults.successfulResults[Object.keys(testResults.rowErrors)[i]-1][Object.keys(testResults.rowErrors)[i][j]['columnName']]);
                         //resultRow[Object.keys(testResults.rowErrors)[i]['columnName'].toLowerCase()] = testResults.successfulResults[Object.keys(testResults.rowErrors)[i]-1][Object.keys(testResults.rowErrors)[i]['columnName'].toLowerCase()]
                       }
                       computedResultsRows.push(resultRow)
                   }
                   //console.log("computedResultsRows ", computedResultsRows);
                 } else {
                   if (testResults.successfulResults != null) {
                     var rowSuccesses = Object.keys(testResults.successfulResults)
                     for (var successIndex = 0; successIndex < rowSuccesses.length; successIndex++) {
                       //console.log("testResults.successfulResults[rowSuccesses[i]] ", testResults.successfulResults[rowSuccesses[i]])
                       var rowSuccessesKeys = Object.keys(testResults.successfulResults[rowSuccesses[successIndex]])
                       //console.log("rowSuccessesKeys ", rowSuccessesKeys)
                       var successResultRow = {};
                       successResultRow['lineNumber'] = parseInt(rowSuccesses[successIndex]) + 1
                       for (var successResultRowKeyIndex = 0; successResultRowKeyIndex < rowSuccessesKeys.length; successResultRowKeyIndex++) {
                           successResultRow[rowSuccessesKeys[successResultRowKeyIndex]] = testResults.successfulResults[rowSuccesses[successIndex]][rowSuccessesKeys[successResultRowKeyIndex]]
                       }
                       //console.log("successResultRow ", successResultRow);
                       computedResultsRows.push(successResultRow)
                     }
                     self.setState({ noErrorsReadyToUpload: true })
                   }
                 }
                 //console.log("computedResultsRows ", computedResultsRows);
                 toast.dismiss()
                 toast.success("Processing Complete!  Please wait...", {
                   position: toast.POSITION.BOTTOM_CENTER,
                   closeButton: true,
                   hideProgressBar: false,
                   autoClose: 2000,
                   onClose: () => self.setState({
                       uploading: false,
                       resultsRows: computedResultsRows,
                       testResults: testResults
                   })
                 });
               }
             }).catch(exception => {
               console.log("exception ", exception);
               toast.error("Exception processing file ", {
                 position: toast.POSITION.BOTTOM_CENTER,
                 hideProgressBar: true,
                 closeButton: true
               });
               self.setState({ errors: exception, uploading: false })
             })

          } catch (ex) {
            toast.error("Exception processing file " + ex, {
              position: toast.POSITION.BOTTOM_CENTER,
              hideProgressBar: true,
              closeButton: true
            });
            self.setState({ errors: ex, uploading: false })
            self.setState({ errors: ex, uploading: false })
          }
        }
      }
      self.publishMapping = () => {
        self.setState({ errors: undefined })
        var validationError = false
        if (!locationCode || locationCode == null) {
          if (document.getElementById('UploadLocationCode') && document.getElementById('UploadLocationCode').value && document.getElementById('UploadLocationCode').value.length > 0) {
            setLocationCode(document.getElementById('UploadLocationCode').value)
          } else {
            setLocationCodeError("Location is required")
            validationError = true
          }
        }
        if (!mappingSource || mappingSource == null) {
          if (document.getElementById('UploadMapping') && document.getElementById('UploadMapping').value && document.getElementById('UploadMapping').value.length > 0) {
            setMappingSource(document.getElementById('UploadMapping').value)
          } else {
            setMappingSourceError("Source is required")
            validationError = true
          }
        }
        if (!testType || testType == null) {
          if (document.getElementById('testType') && document.getElementById('testType').value && document.getElementById('testType').value.length > 0) {
            setTestType(document.getElementById('testType').value)
          } else {
            setTestTypeError("Test Type is required")
            validationError = true
          }
        }
        if (!file.input.value) {
          var error = ""
          if (!file.input.value) {
            if (error.length > 0)
              error += " | "
            error += "No file selected for upload"
          }
          self.setState({ errors: error, mappingSource: mappingSource, locationCode : locationCode })
        } else if (!validationError) {
          self.setState({ uploading: true, testResults: {}})
          try {
            toast.info("Processing file, please wait...", {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: false,
              hideProgressBar: true
            });
            let formData = new FormData();
            formData.set("file", file.input.value.rawFile);
            fetch(Config.REACT_APP_API_URL + "/result/" + mappingSource.id + "/" + locationCode.id + "/" + selectedTenantId + "/" + testType.id, {
                method: "POST",
                headers: new Headers({'Authorization': localStorage.getItem('token')}),
                body: formData
            }).then(response => {
                return response.json()
            }).then(uploadResponse => {
               //console.log("registration response ", uploadResponse);
               if (!uploadResponse || (uploadResponse.success !== null && uploadResponse.success === false) || (uploadResponse.status && uploadResponse.status >= 400)) {
                  toast.dismiss()
                  self.setState({ errors: uploadResponse.message, uploading: false })
               } else {
                 toast.dismiss()
                 toast.success("Upload Complete!  Please wait...", {
                   position: toast.POSITION.BOTTOM_CENTER,
                   closeButton: true,
                   hideProgressBar: false,
                   autoClose: 2000,
                   onClose: () => history.push('/upload/' + tenantId)
                 });
               }
             }).catch(exception => {
               console.log("exception ", exception);
               self.setState({ errors: exception, uploading: false })
             })

          } catch (ex) {
            self.setState({ errors: ex, uploading: false })
          }
        }
      }
      return (
        <>
        <Card>
          <CardHeader>Upload File (csv)</CardHeader>
            { self.state.uploading && <CardContent> <LinearProgress style={{height: "5px", width: "100%", margin: "0" }}/> </CardContent>}
            { !self.state.uploading && <CardContent style={{ marginLeft: "1%", marginRight: "1%" }}>
              <div className="breadcrumbs">
                <Link to={{pathname: '/upload/' + localStorage.getItem('tenantId')}}> &lt; Back to Uploads</Link>
              </div>
              {
                //--getOptionSelected={(option, value) => option !== undefined && value !== undefined ? option.id === value.id : undefined }
              }
              {isAdmin && <Autocomplete
                id="tenant"
                style={{ width: "450px" }}
                value={tenant}
                disableClearable={true}
                inputValue={tenantInputValue}
                onChange={handleTenantChange}
                getOptionLabel={(option) => option && option.businessName}
                options={tenants && tenants.sort((a, b) => -b.businessName.localeCompare(a.businessName))}
                renderInput={(params) => (
                  <TextFieldCore required {...params} label="Tenant" margin="normal" variant="outlined" helperText={tenantError} />
                )}
              /> }
              {tenantId > 0 && mappingSourceList && mappingSourceList.length > 0 && <Autocomplete
                id="UploadMapping"
                style={{ width: "450px" }}
                value={mappingSource}
                disableClearable={true}
                options={mappingSourceList.sort((a, b) => -b.name.localeCompare(a.name))}
                inputValue={mappingSourceInputValue}
                onChange={handleMappingSourceChange}
                getOptionLabel={(option) => option.name }
                renderInput={(params) => (
                  <TextFieldCore required {...params} label="File Format" margin="normal" variant="outlined" helperText={mappingSourceError} />
                )}
              /> }
              {tenantId > 0 && locationList && locationList.length > 0 && <Autocomplete
                id="UploadLocationCode"
                style={{ width: "450px" }}
                value={locationCode}
                disableClearable={true}
                inputValue={locationCodeInputValue}
                onChange={handleLocationCodeChange}
                getOptionLabel={(option) => option && option.name}
                options={locationList.sort((a, b) => -b.name.localeCompare(a.name))}
                renderInput={(params) => (
                  <TextFieldCore required {...params} label="Location" margin="normal" variant="outlined" helperText={locationCodeError} />
                )}
              /> }
              { tenantId > 0 && testTypes && testTypes.length > 0 && <Autocomplete
                id="testType"
                style={{ width: "450px" }}
                value={testType}
                disableClearable={true}
                inputValue={testTypeInputValue}
                onChange={handleTestTypeChange}
                getOptionLabel={(option) => option && option.name}
                options={testTypes.sort((a, b) => -b.name.localeCompare(a.name))}
                renderInput={(params) => (
                  <TextFieldCore required {...params} label="Test Type" margin="normal" variant="outlined" helperText={testTypeError} />
                )}
              /> }
              <Box display="flex" justifyContent="space-between" style={{ width: '450px' }}>
              {locationCode && mappingSource && testType &&
                <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                  <Box display="flex" justifyContent="space-between" style={{ width: '10%', marginTop: '50px', textAlign: 'center' }}>
                    <FileIcon />
                  </Box>
                  <Box display="flex" justifyContent="space-between" style={{ width: '90%', textAlign: 'left' }}>
                    <FileInput onChange={handleFileChange} source="file" label="Upload File" multiple={false} accept=".csv" ><FileField source="file" title="title" /></FileInput>
                  </Box>
                </Box>
              }
              </Box>
              {(!locationCode || !mappingSource || !testType) && !isAdmin && <><br/>Select Source, Location Code, and Test Type to Upload File</>}
              {(!locationCode || !mappingSource || !testType || !tenant) && isAdmin && <><br/>Select Source, Location Code, Test Type, and Tenant to Upload File</>}

              {errors && <><br/><br/>
                <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                  <Box display="flex" justifyContent="space-between" style={{ width: '48%', textAlign: 'center', color: 'red' }}>
                    {errors}
                  </Box>
                </Box>
              </>}

              {!noErrorsReadyToUpload && testResults && testResults.filename && <><br/><br/>
                <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                  {testResults.rowErrors && Object.keys(testResults.rowErrors).length > 0 && <>{Object.keys(testResults.rowErrors).length} of {(testResults.successfulResults ? testResults.successfulResults.length : 0) + Object.keys(testResults.rowErrors).length} rows failed validation.  File is not ready for publishing</>}
                </Box>
                <br/>
                <ReactDataGrid className='rdg-light red' columns={resultsColumns} rows={resultsRows} />
              </>}
              {noErrorsReadyToUpload && resultsRows && <><br/><br/>
                <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                    File validation is successful and is now ready to be published
                </Box>
                <br/>
                <ReactDataGrid className='rdg-light green' columns={resultsColumns} rows={resultsRows} />
              </>}
            </CardContent> }
          <CardActions style={{ marginLeft: "1%", marginRight: "1%" }}>
          { !self.state.uploading && !noErrorsReadyToUpload && (locationCode && mappingSource && file && file.input.value) && <Button
          label="Upload"
          onClick={() => self.uploadMapping()}
          >
          <IconCloudUpload />
          </Button> }
          {!self.state.uploading && noErrorsReadyToUpload && (locationCode && mappingSource && file && file.input.value) && <Button
            label="Publish File"
            onClick={() => self.publishMapping()}
            >
            <IconSave />
            </Button>
          }
          </CardActions>
        </Card>
        </>
      )
    }
    return (
      <>
        <SimpleForm toolbar={null}>
          <UploadDialog {...this.props} />
        </SimpleForm>
      </>
    )
  }
}
//<Button label="Upload" onClick={() => self.showUploadDialog()}><IconCloudUpload /></Button>
//
//<Box display="flex" justifyContent="space-between" style={{ width: '30%' }}>
  //<TextInput label="Source Date Format" source={getSource('dateFormat')} style={{ width: '33% !important', float: 'left' }} />
//</Box>
