import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import UserMenu from './UserMenu';
import * as Config from '../config'
import Logo from '../images/tts_logo_long_small.png'

const styles = {
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
};
//<Toolbar>
  // <Typography variant="title" id="react-admin-title" />
//</Toolbar>
function ShowEnvironmment(props) {
  const isNotProd = (Config.REACT_APP_ENV !== 'PROD');
  if (false && isNotProd) {
    return <span style={{ color : "white"}}>{Config.REACT_APP_ENV}</span>;
  }
  return <span></span>;
}

const SPAAppBar = withStyles(styles)(({ classes, ...props }) => (
    <AppBar {...props} userMenu={<UserMenu />}>
        <Typography
            variant="subtitle1"
            color="inherit"
            className={classes.title}
            id="react-admin-title"
        />
      <img style={{ position: 'absolute', marginLeft: '45%'}} src={Logo} alt='LACENTA Logo' />
        <span className={classes.spacer} />
        <ShowEnvironmment />
    </AppBar>
));

export default SPAAppBar;
