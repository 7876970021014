import React, { FC, Fragment } from 'react';
import { useSelector } from 'react-redux';
import HomeIcon from '@material-ui/icons/HomeTwoTone';
import HomeIconSelected from '@material-ui/icons/Home';
import UsersIcon from '@material-ui/icons/PeopleTwoTone';
import UsersIconSelected from '@material-ui/icons/People';
import LabSourcesIcon from '@material-ui/icons/LocalDrinkTwoTone';
import LabSourcesIconSelected from '@material-ui/icons/LocalDrink';
import MappingIcon from '@material-ui/icons/AccountTreeTwoTone';
import MappingIconSelected from '@material-ui/icons/AccountTree';
import PatientCheckIn from '@material-ui/icons/CheckCircleOutline';
import PatientCheckInSelected from '@material-ui/icons/CheckCircle';
import { useMediaQuery, Theme } from '@material-ui/core';
import { MenuItemLink, usePermissions } from 'react-admin';
import PatientResultsSelected from '@material-ui/icons/AssignmentInd';
import PatientResults from '@material-ui/icons/AssignmentIndTwoTone';
import TenantIconSelected from '@material-ui/icons/SupervisedUserCircleTwoTone';
import TenantIcon from '@material-ui/icons/SupervisedUserCircle';
import TestTypeIconSelected from '@material-ui/icons/ColorizeTwoTone';
import TestTypeIcon from '@material-ui/icons/Colorize';
import FileIconSelected from '@material-ui/icons/BackupTwoTone';
import FileIcon from '@material-ui/icons/Backup';
import LocationIconSelected from '@material-ui/icons/LocationCityTwoTone';
import LocationIcon from '@material-ui/icons/LocationCity';
import * as Config from '../config'

//type MenuName = 'menuReports' | 'menuOthers';

interface Props {
    dense: boolean;
    logout: () => void;
    onMenuClick: () => void;
}

const Menu: FC<Props> = ({ onMenuClick, dense, logout }) => {
    /*
    const [state, setState] = useState({
        menuReports: false,
        menuOthers: false,
    });
    */
    const styles = {
      activeLink: {
        color: 'black',
        textDecoration: 'underline'
      },
      link: {
        color: 'gray'
      },
      dashboardDiv: {

      }
    };

    const { permissions } = usePermissions();

//  const translate = useTranslate();
    const isXSmall = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('xs')
    );
    const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen);
    var location = window.location.href ? window.location.href : "";

    useSelector((state: AppState) => state.theme); // force rerender on theme change

    return (
        <div style={styles.dashboardDiv}>
              {permissions &&
                <MenuItemLink
                    style={ String(location) && String(location).indexOf("result") > 0 ? styles.activeLink : styles.link}
                    to={`/result`}
                    primaryText="Home"
                    leftIcon={String(location).indexOf("result") > 0 ? <HomeIconSelected /> : <HomeIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
              }
              {false && permissions && permissions.includes(Config.ROLE_ADMIN) &&
              <MenuItemLink
                  style={ String(location) && String(location).indexOf("source") > 0 ? styles.activeLink : styles.link}
                  to={`/source`}
                  primaryText="Lab Sources"
                  leftIcon={String(location).indexOf("source") > 0 ? <LabSourcesIconSelected /> : <LabSourcesIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
              />
              }
              {permissions && (permissions.includes(Config.ROLE_ADMIN) || permissions.includes(Config.ROLE_TENANT_ADMIN)) &&
              <MenuItemLink
                  style={ String(location) && (String(location).indexOf("mapping") > 0 && String(location).indexOf("mapping/result") < 0) ? styles.activeLink : styles.link}
                  to={`/mapping`}
                  primaryText="File Formats"
                  leftIcon={String(location).indexOf("mapping") > 0 && String(location).indexOf("mapping/result") < 0 ? <MappingIconSelected /> : <MappingIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
              />
              }
              {permissions && !permissions.includes(Config.ROLE_ADMIN) &&
                <Fragment>
                  <MenuItemLink
                      style={ String(location) && String(location).indexOf("checkIn") > 0 ? styles.activeLink : styles.link}
                      to={`/patient/checkIn`}
                      primaryText="Patient Check-In"
                      leftIcon={String(location).indexOf("checkIn") > 0 ? <PatientCheckInSelected /> : <PatientCheckIn />}
                      onClick={onMenuClick}
                      sidebarIsOpen={open}
                      dense={dense}
                  />
                  <MenuItemLink
                      style={ String(location) && String(location).indexOf("patientCheckedInResults") > 0 ? styles.activeLink : styles.link}
                      to={`/patient/patientCheckedInResults`}
                      primaryText="Patient Results"
                      leftIcon={String(location).indexOf("patientCheckedInResults") > 0 ? <PatientResultsSelected /> : <PatientResults />}
                      onClick={onMenuClick}
                      sidebarIsOpen={open}
                      dense={dense}
                  />
                </Fragment>
              }
              {permissions && (permissions.includes(Config.ROLE_ADMIN) || permissions.includes(Config.ROLE_TENANT_ADMIN)) &&
                <MenuItemLink
                    style={ String(location) && String(location).indexOf("user") > 0 ? styles.activeLink : styles.link}
                    to={`/user`}
                    primaryText="Users"
                    leftIcon={String(location).indexOf("user") > 0 ? <UsersIconSelected /> : <UsersIcon />}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                    dense={dense}
                />
              }
              {permissions && permissions.includes(Config.ROLE_ADMIN) &&
              <MenuItemLink
                  style={ String(location) && String(location).indexOf("tenant") > 0 ? styles.activeLink : styles.link}
                  to={`/tenant`}
                  primaryText="Tenants"
                  leftIcon={String(location).indexOf("tenant") > 0 ? <TenantIconSelected /> : <TenantIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
              />
              }
              {permissions && permissions.includes(Config.ROLE_ADMIN) &&
              <MenuItemLink
                  style={ String(location) && String(location).indexOf("test/type") > 0 ? styles.activeLink : styles.link}
                  to={`/test/type`}
                  primaryText="Test Types"
                  leftIcon={String(location).indexOf("test/type") > 0 ? <TestTypeIconSelected /> : <TestTypeIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
              />
              }
              {permissions && permissions.includes(Config.ROLE_TENANT_ADMIN) &&
              <MenuItemLink
                  style={ String(location) && String(location).indexOf("location") > 0 ? styles.activeLink : styles.link}
                  to={`location`}
                  primaryText="Locations"
                  leftIcon={String(location).indexOf("location") > 0 ? <LocationIconSelected /> : <LocationIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
              />
              }
              <MenuItemLink
                  style={ String(location) && String(location).indexOf("upload") > 0 ? styles.activeLink : styles.link}
                  to={`/upload/${localStorage.getItem('tenantId')}`}
                  primaryText="Uploads"
                  leftIcon={String(location).indexOf("upload") > 0 ? <FileIconSelected /> : <FileIcon />}
                  onClick={onMenuClick}
                  sidebarIsOpen={open}
                  dense={dense}
              />              
            {isXSmall && logout}
        </div>
    );
};


export default Menu;
