import { AuthProvider } from 'react-admin';
import * as Config from './config'
import decodeJwt from 'jwt-decode';

const authProvider: AuthProvider = {
    // called when the user attempts to log in
    login: ({ emailAddress, password }) => {
        return fetch(Config.REACT_APP_API_URL + "/auth/signin/", {
            method: "POST",
            headers: new Headers({'Content-Type': 'application/json'}),
            body: JSON.stringify({
              email: emailAddress,
              password: password
            })
        })
        .then(response => {
            if (response.status < 200 || response.status >= 300) {
                throw new Error(response.statusText);
            }
            return response.json();
        })
        .then((json) => {
            if (json && json.fullToken) {
              var token = json.fullToken;
              const decodedToken = decodeJwt(json.fullToken);
              var permissions = [];
              if (decodedToken.principal.authorities) {
                permissions = decodedToken.principal.authorities.map(function(item) {
                  return item['authority'];
                });
              }
              localStorage.setItem('emailAddress', decodedToken.principal.email);
              localStorage.setItem('username', decodedToken.principal.email);
              localStorage.setItem('name', decodedToken.principal.name);
              localStorage.setItem('token', token)
              localStorage.setItem('permissions', permissions.join(","));
              if (decodedToken.principal.tenants && decodedToken.principal.tenants.length > 0) {
                localStorage.setItem('tenantId', decodedToken.principal.tenants[0].id)
                localStorage.setItem('tenantName', decodedToken.principal.tenants[0].businessName)
              }
            } else {
              //console.log("json ", json.message);
              let message;
              let errorMessage = "";
              try  {
                if (json.isPrototypeOf(String))
                  message = JSON.parse(json.message)
                else
                  message = json.message                
                if (message && message.isPrototypeOf(Array)) {
                  message.forEach(function(obj) {
                    var result = obj.field.replace( /([A-Z])/g, " $1" );
                    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                    if (errorMessage !== "")
                      errorMessage += ", "
                    errorMessage += finalResult + " - " + (obj.message ? obj.message : "is empty")
                  });
                } else {
                  errorMessage = message
                }
              } catch (e) {
                  // not json, no big deal
                  errorMessage = "Error occurred, please contact support"
              }
              throw Error(errorMessage);
            }
          }).catch((ex) => {
              console.log("ex ", ex)
              throw new Error(ex)
          });
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('username');
        localStorage.removeItem('emailAddress');
        localStorage.removeItem('token');
        localStorage.removeItem('permissions');
        localStorage.removeItem('name');
        localStorage.removeItem('tenantId')
        localStorage.removeItem('tenantName')
        localStorage.removeItem('tenants')
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        if (status === 401 || status === 403) {
            var hashedLocation = window.location.hash
            localStorage.removeItem('token');
            if (hashedLocation && hashedLocation !== '#/login') {
              localStorage.setItem('attemptedURL', hashedLocation)
            }
            return Promise.reject();
        }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        var hashedLocation = window.location.hash
        if (hashedLocation && hashedLocation !== '#/login') {
          localStorage.setItem('attemptedURL', hashedLocation)
        }
        return window.location.hash.indexOf('patient/results') > 0 ? Promise.resolve() : localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => {
        const role = localStorage.getItem('permissions');
        return role ? Promise.resolve(role) : Promise.reject();
    },
};

export default authProvider;
