import React, {Fragment, Component}  from 'react';
import {List, Datagrid, TextField, Pagination, Filter, TextInput, Confirm,
  ReferenceInput, AutocompleteInput, Button, RefreshButton, TopToolbar,
  SimpleForm, Loading, useRefresh, useDataProvider} from 'react-admin';
import Moment from 'moment';
import Box from '@material-ui/core/Box';
import IconButton from "@material-ui/core/IconButton";
import IconSave from '@material-ui/icons/Save';
import IconCancel from '@material-ui/icons/Cancel';
import InconclusiveIcon from '@material-ui/icons/HelpOutlineRounded';
import DetectedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import NotDetectedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import UpdateResultsIcon from '@material-ui/icons/EditOutlined';
import PublishIcon from '@material-ui/icons/Publish';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextFieldCore from '@material-ui/core/TextField';
import { toast } from 'react-toastify';
import { makeStyles } from '@material-ui/core/styles';
import ThumbUp from '@material-ui/icons/ThumbUp';
import ThumbDown from '@material-ui/icons/ThumbDown';
import VaccinatedIcon from '@material-ui/icons/LocalPharmacy';
import VerifiedNegativeIcon from '@material-ui/icons/CheckCircle';
import VaccinatedWithIdIcon from '@material-ui/icons/RecentActors';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  detectedIconLargeSelected : {
    width: 'auto',
    color: '#FFFFFF',
    backgroundColor: '#CC0006',
    borderRadius: 0
  },
  detectedIconLarge : {
    width: 'auto',
    color: '#CC0006',
    borderRadius: 0,
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#CC0006'
    },
  },
  notDetectedIconLargeSelected: {
    width: 'auto',
    color: '#FFFFFF',
    backgroundColor: '#2A78E4',
    borderRadius: 0
  },
  notDetectedIconLarge : {
    width: 'auto',
    color: '#2A78E4',
    borderRadius: 0,
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#2A78E4'
    }
  },
  inconclusiveIconLargeSelected: {
    width: 'auto',
    color: '#FFFFFF',
    backgroundColor: '#FFD968',
    borderRadius: 0
  },
  inconclusiveIconLarge: {
    width: 'auto',
    color: '#FFD968',
    borderRadius: 0,
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#FFD968'
    }
  },
  vaccinatedIconLargeSelected: {
    width: 'auto',
    color: '#FFFFFF',
    backgroundColor: 'green',
    borderRadius: 0
  },
  vaccinatedIconLarge: {
    width: 'auto',
    color: 'green',
    borderRadius: 0,
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: 'green'
    }
  },
  verifiedNegativeIconLargeSelected: {
    width: 'auto',
    color: '#FFFFFF',
    backgroundColor: 'purple',
    borderRadius: 0
  },
  verifiedNegativeIconLarge: {
    width: 'auto',
    color: 'purple',
    borderRadius: 0,
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: 'purple'
    }
  },
  largeIcon: {
    fontSize: "3em"
  },
  editor: {
    border: '1px solid gray',
    minHeight: '6em',
    height: '20vh',
    maxHeight: '20vh',
    overflowY: 'auto'
  },
});


const Actions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <RefreshButton />
    </TopToolbar>
);

const PatientCheckInResultsPagination = props => <Pagination style={{ display: 'block' }} rowsPerPageOptions={[25, 50, 100]} {...props} />
const PatientCheckInResultsFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Patient First Name" source="firstName" alwaysOn resettable />
        <TextInput label="Patient Last Name" source="lastName" resettable alwaysOn />
        <TextInput label="Patient Phone" source="phone" resettable alwaysOn />
        <ReferenceInput label="Location" source="locationCode" reference="location/list" alwaysOn>
          <AutocompleteInput optionText="code" fullWidth />
        </ReferenceInput>
        <Button key={'ClearLocation'} style={{ display: 'block', minWidth: '5px', padding: '0', paddingBottom: '10px', color: 'black' }} onClick={() => props.setFilters({
              locationCode: '',
              resultStatus: props.filterValues.resultStatus,
              firstName: props.filterValues.firstName,
              lastName: props.filterValues.lastName,
              phone: props.filterValues.phone
         })} alwaysOn aria-label="Clear Collection Dates"><IconCancel /></Button>
    </Filter>
);

const BulkActionButtons = props => (
    <Fragment>
        {/* Add the default bulk delete action */}
        <BulkAddButton {...props} dataProvider={useDataProvider()}  />
    </Fragment>
);

class BulkAddButton extends Component {
    constructor(props) {
        super(props)
        this.state = {
          isLoading : false,
          showConfirmSubmitDialog : false,
          numberOfPatientsSelected: 0
        }
    }
    showConfirmation = () => {
      const { selectedIds } = this.props;
      this.setState({
        showConfirmSubmitDialog: true,
        numberOfPatientsSelected : selectedIds.length
      })
    }
    submitForNotification = () => {
      const { dataProvider, selectedIds } = this.props;
        this.setState({
          showConfirmSubmitDialog: false
        })
        toast.info("Submitting Patient Results, please wait...", {
          position: toast.POSITION.BOTTOM_CENTER,
          closeButton: false,
          hideProgressBar: true
        });
        dataProvider.create('patient/' + selectedIds + '/finalize',{ data: {}
      }).then((submitted) => {
          toast.dismiss()
          toast.success("Patient Results Submitted", {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: true,
            hideProgressBar: true,
            autoClose: 1000
          });
          window.location.reload(true)
        }).catch(({ status, statusText, headers, body }) => {
          console.log("error submitting patient results ", body);
          toast.dismiss()
          toast.error(body && body.message ? body.message : "Error", {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
            autoClose: 1000
          });
        });
    }
    render() {
        return <Fragment>
          <Confirm
              isOpen={this.state.showConfirmSubmitDialog}
              title={`Please confirm that you would like to submit the selected patients (${this.state.numberOfPatientsSelected}) for notfication `}
              content="Please note: This action can not be reversed."
              confirm="Yes, Submit for Notification"
              confirmColor="primary"
              cancelColor="primary"
              cancel="Cancel"
              ConfirmIcon={ThumbUp}
              CancelIcon={ThumbDown}
              onConfirm={() => this.submitForNotification()}
              onClose={() => {
                this.setState({
                  showConfirmSubmitDialog: false
                })
              }}
          />
          <Button label='Submit for Notification' onClick={() =>this.showConfirmation()}><PublishIcon/></Button>
        </Fragment>
    }
}
//{record.result !== "PROCESSING" && <Button label='Submit for Notification' onClick={() =>this.submitForNotification(record)}><PublishIcon/></Button> }


const PatientDateOfBirthFormat = ({ record = {} }) => <span>{record.patient.dob ? Moment(record.patient.dob).format('DD MMM YYYY') : ""}</span>;
// DETECTED, NOT_DETECTED, INCONCLUSIVE, SPECIMEN_UNSATISFACTORY, PROCESSING
const ResultsButton = ({ record = {} }) => <Fragment>
  {record.result === "DETECTED" && <Tooltip title="DETECTED" aria-label="DETECTED"><DetectedIcon className="DetectedColor" /></Tooltip>}
  {record.result === "NOT DETECTED" && <Tooltip title="NOT DETECTED" aria-label="NOT DETECTED"><NotDetectedIcon className="NotDetectedColor" /></Tooltip>}
  {record.result === "INCONCLUSIVE" && <Tooltip title="INCONCLUSIVE" aria-label="INCONCLUSIVE"><InconclusiveIcon className="InconclusiveColor" /></Tooltip>}
  {record.result === "VACCINATED" && <Tooltip title="VACCINATED" aria-label="VACCINATED"><VaccinatedIcon className="VaccinatedColor" /></Tooltip>}
  {record.result === "VACCINATED WITH ID" && <Tooltip title="VACCINATED WITH ID" aria-label="VACCINATED WITH ID"><VaccinatedWithIdIcon title="VACCINATED WITH ID" className="VaccinatedColor" /></Tooltip>}
  {record.result === "VERIFIED NEGATIVE" && <Tooltip title="VERIFIED NEGATIVE" aria-label="VERIFIED NEGATIVE"><VerifiedNegativeIcon className="VerifiedNegativeColor" /></Tooltip>}
</Fragment>;
export const PatientCheckInResultsList = props => (
  <Fragment>
    <section className='section'>
      <List bulkActionButtons={<BulkActionButtons  {...props} />} actions={<Actions />} sort={{ field: 'id', order: 'DESC' }} filters={<PatientCheckInResultsFilter />} perPage={50} title="Home" {...props} pagination={< PatientCheckInResultsPagination />}>
          <Datagrid isRowSelectable={ record => record.result !== "PROCESSING" } expand={<TextField label="Notes" source="notes" />} >
            <ResultsButton label="Results" source="result" />
            <TextField label="Location" source="locationCode" />
            <TextField label="First Name" source="patient.firstName"  />
            <TextField label="Last Name" source="patient.lastName" />
            <TextField label="Phone Number" source="patient.phone" />
            <PatientDateOfBirthFormat source="patient.dob" label="Date of Birth" />
            <UpdatePatientResultsSection label='Update Results'  />
          </Datagrid>
      </List>
    </section>
  </Fragment>
);

class UpdatePatientResultsSection extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isLoading : false,
        showPatientDialog : false,
        patientResults : { patient : {} },
        result : ''
      }
  }
  render() {
    const {
      isLoading,
      showUpdatePatientResultsDialog
    } = this.state;
    const selfUR = this;
    this.showUpdatePatientResultsDialog = (record) => {
      console.log("showUpdatePatientResultsDialog record ", record);
      selfUR.setState({
        isLoading : false,
        showUpdatePatientResultsDialog : true
      });
      selfUR.initialize(record);
    }
    this.submitForNotification = (record) => {
      selfUR.submitForNotification(record);
    }
    const UpdateResultsButton = ({ record }) => {
      return <Fragment>
        <Button label='Update' onClick={() =>this.showUpdatePatientResultsDialog(record)}><UpdateResultsIcon/></Button>
      </Fragment>
    }
    function PatientUpdateDialog() {
      const refresh = useRefresh();
      const dataProvider = useDataProvider();
      const classes = useStyles();
      function updateNotes (event) {
        selfUR.state.patientResults.notes = event.target.value
      }
      selfUR.initialize = (record) => {
        console.log("PatientCheckOutDialog initialize record ", record);
        selfUR.setState({
          patientResults: record
        });
      }
      selfUR.closeUpdatePatientResultsDialog = () => {
        selfUR.setState({
          showUpdatePatientResultsDialog: false,
          isLoading : false,
        }, () => refresh());
      }
      selfUR.updatePatientResults = (results) => {
        selfUR.state.patientResults.result = results
        selfUR.setState({
          patientResults: selfUR.state.patientResults
        });
      }
      selfUR.submitForNotification = (record) => {
        toast.info("Submitting Patient Results, please wait...", {
          position: toast.POSITION.BOTTOM_CENTER,
          closeButton: false,
          hideProgressBar: true
        });
        console.log("Submitting Patient Results : ", selfUR.state.patientResults)
        dataProvider.create('patient/' + record.id + '/finalize',{ data: {}
      }).then((submitted) => {
          toast.dismiss()
          toast.success("Patient Results Submitted", {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: true,
            hideProgressBar: true,
            autoClose: 1000
          });
          refresh()
        }).catch(({ status, statusText, headers, body }) => {
          console.log("error submitting patient results ", body);
          toast.dismiss()
          toast.error(body && body.message ? body.message : "Error", {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
            autoClose: 1000
          });
          selfUR.setState({
              isLoading : false
          });
        });
      }
      selfUR.savePatientResults = () => {
        console.log("selfUR.state.patientResults.notes : ", selfUR.state.patientResults.notes)
        //selfUR.state.patientResults.notes = notes.input.value
        selfUR.state.patientResults.dateOfBirth = Moment(selfUR.state.patientResults.dateOfBirth).format('YYYY-MM-DD')
        selfUR.setState({
          isLoading : true
        });
        toast.info("Saving Patient Results, please wait...", {
          position: toast.POSITION.BOTTOM_CENTER,
          closeButton: false,
          hideProgressBar: true
        });
        console.log("Saving patient results : ", selfUR.state.patientResults)
        dataProvider.create('patient',{ data: selfUR.state.patientResults
        }).then((checkout) => {
          toast.dismiss()
          toast.success("Patient Results Saved", {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: true,
            hideProgressBar: true,
            autoClose: 1000
          });
          selfUR.setState({
            showUpdatePatientResultsDialog: false,
            isLoading : false,
          }, () => refresh());
        }).catch(({ status, statusText, headers, body }) => {
          console.log("error saving patient ", body);
          toast.dismiss()
          toast.error(body && body.message ? body.message : "Error", {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
            autoClose: 1000
          });
          selfUR.setState({
              isLoading : false
          });
        });
      }
      return (
        <>
          <Dialog fullWidth={true} maxWidth={"md"}  disableBackdropClick disableEscapeKeyDown open={showUpdatePatientResultsDialog} >
              <DialogTitle>Record Patient Result</DialogTitle>
              { isLoading ?
              <DialogContent>
                <Loading />
              </DialogContent> :
              <DialogContent>
                <Box id="DOBInputs" display="flex" style={{ width: '100%' }}>
                  <Box display="flex-row" justifyContent="flex-start" style={{ minHeight: '45vh', width: '60%' }}>
                    <TextFieldCore disabled defaultValue={selfUR.state.patientResults.locationCode} label="Location" source="locationCode" />
                    <TextFieldCore disabled defaultValue={selfUR.state.patientResults.testType} label="Type" source="testType" />
                    <TextFieldCore disabled defaultValue={selfUR.state.patientResults.patient.firstName} label="First Name" source="firstName"  />
                    <TextFieldCore disabled defaultValue={selfUR.state.patientResults.patient.lastName} label="Last Name" source="lastName" />
                    <TextFieldCore disabled defaultValue={selfUR.state.patientResults.patient.phone} label="Phone Number" source="phone" />
                    <TextFieldCore disabled defaultValue={selfUR.state.patientResults.patient.email} label="Email" source="email" />
                    <TextFieldCore disabled defaultValue={Moment(selfUR.state.patientResults.patient.dob).format('YYYY-MM-DD')} label="Date of Birth" source="dateOfBirth" />
                    <br/><br/>
                    <span className={classes.dateOfBirth}>
                      Notes
                    </span>
                    <TextFieldCore onChange={updateNotes} defaultValue={selfUR.state.patientResults.patient.notes} source="notes" label="" fullWidth />
                  </Box>
                  <Box display="flex-row" justifyContent="flex-start" style={{ width: '40%', marginTop: '-8%', zIndex: 2, position: 'absolute', right: 0}}>
                    <IconButton
                      onClick={() => selfUR.updatePatientResults('DETECTED')}
                      className={selfUR.state.patientResults.result === 'DETECTED' ? classes.detectedIconLargeSelected : classes.detectedIconLarge}
                    >
                      <DetectedIcon className={classes.largeIcon} />
                      &nbsp;&nbsp;&nbsp;&nbsp;POSITIVE
                    </IconButton>
                    <IconButton
                      onClick={() => selfUR.updatePatientResults('NOT DETECTED')}
                      className={selfUR.state.patientResults.result === 'NOT DETECTED' ? classes.notDetectedIconLargeSelected : classes.notDetectedIconLarge}
                    >
                      <NotDetectedIcon className={classes.largeIcon} />
                      &nbsp;&nbsp;&nbsp;&nbsp;NEGATIVE
                    </IconButton>
                    <IconButton
                      onClick={() => selfUR.updatePatientResults('INCONCLUSIVE')}
                      className={selfUR.state.patientResults.result === 'INCONCLUSIVE' ? classes.inconclusiveIconLargeSelected : classes.inconclusiveIconLarge}
                    >
                      <InconclusiveIcon className={classes.largeIcon} />
                      &nbsp;&nbsp;&nbsp;&nbsp;INCONCLUSIVE
                    </IconButton>
                    <IconButton
                      onClick={() => selfUR.updatePatientResults('VACCINATED')}
                      className={selfUR.state.patientResults.result === 'VACCINATED' ? classes.vaccinatedIconLargeSelected : classes.vaccinatedIconLarge}
                    >
                      <VaccinatedIcon className={classes.largeIcon} />
                      &nbsp;&nbsp;&nbsp;&nbsp;VACCINATED
                    </IconButton>
                    <IconButton
                      onClick={() => selfUR.updatePatientResults('VACCINATED WITH ID')}
                      className={selfUR.state.patientResults.result === 'VACCINATED WITH ID' ? classes.vaccinatedIconLargeSelected : classes.vaccinatedIconLarge}
                    >
                      <VaccinatedWithIdIcon className={classes.largeIcon} />
                      &nbsp;&nbsp;VACCINATED (ID)
                    </IconButton>
                    <IconButton
                      onClick={() => selfUR.updatePatientResults('VERIFIED NEGATIVE')}
                      className={selfUR.state.patientResults.result === 'VERIFIED NEGATIVE' ? classes.verifiedNegativeIconLargeSelected : classes.verifiedNegativeIconLarge}
                    >
                      <VerifiedNegativeIcon className={classes.largeIcon} />
                      CONFIRMED NEGATIVE
                    </IconButton>

                  </Box>
                </Box>
              </DialogContent> }
          <DialogActions>
            <Button
              label="Save"
              onClick={() => selfUR.savePatientResults()}
            >
              <IconSave />
            </Button>
            <Button
              label="Cancel"
              onClick={() => selfUR.closeUpdatePatientResultsDialog()}
            >
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
        </>
      )
    }
    return <Fragment>
      <SimpleForm toolbar={null} className="CheckOutPatientForm">
        <PatientUpdateDialog />
      </SimpleForm>
      <UpdateResultsButton {...selfUR.props} />
    </Fragment>
  }
}
