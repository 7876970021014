import React from "react";
import { Link } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import LoadingIndicator from './LoadingIndicator';
import { withStyles } from '@material-ui/core/styles';

function replaceBreadCrumbURL(url, match) {
  return url;
}

function isIgnoreFirstPath(url) {
  if (url === "/mapping/result")
    return true;
  return false;
}

function includeBreadCrumbChevron(length, url) {
  return true;
}

const styles = {
    button: {
      background: 'none',
      border: 'none',
      padding: '0',
      color: '#069',
      textDecoration: 'underline',
      cursor: 'pointer'
    },
    link: {
      color: '#069',
    },
    hide: {
      display: 'none',
    }
};

const PureBreadcrumbs = withStyles(styles)(({ breadcrumbs, classes }) => (
  <div>
    <div className="breadcrumbs">
      {breadcrumbs.map(({ breadcrumb, match }, index) => (
        <div className={isIgnoreFirstPath(match.url) ? classes.hide : "bc"} key={match.url}>
          {index < breadcrumbs.length - 1 ? <Link className={classes.link} to={replaceBreadCrumbURL(match.url, match) || ""}>{breadcrumb}</Link> : <span>{breadcrumb}</span>}
           <span>{index < breadcrumbs.length - 1 && includeBreadCrumbChevron(breadcrumbs.length, match.url) && ">"}</span>
        </div>
      ))}
    </div>
    <LoadingIndicator />
  </div>
));

const DynamicUserEditBreadcrumb = ({ match }) => (
  <span>Edit User ({match.params.userId})</span>
);

const routes = [
  { path: '/', breadcrumb: "Dashboard" },
  { path: '/mapping/result', breadcrumb: "Home" },
  { path: '/mapping', breadcrumb: "File Formats" },
  { path: '/source', breadcrumb: "Lab Sources" },
  { path: '/user', breadcrumb: "Users" },
  { path: '/user/create', breadcrumb: 'Create User' },
  { path: '/user/:userId', breadcrumb: DynamicUserEditBreadcrumb },
];

export default withBreadcrumbs(routes, {excludePaths: ['/','/products/:productId/'] })(PureBreadcrumbs);
