import React from 'react';
import { Layout, Sidebar } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import CustomNotification from './Notification';

const MySidebar = props => {
    return (
        window.location.hash.indexOf('patient/results') > 0 ? <></> : <Sidebar {...props} />
    );
};

const TTSLayout = props => <Layout
    {...props}
    appBar={AppBar}
    sidebar={MySidebar}
    menu={Menu}
    notification={CustomNotification}
/>;

export default TTSLayout;
