import { Notification } from 'react-admin';

const CNotifcation = ({ record }) => {    
    return <Notification autoHideDuration={5000} />;
}

const CustomNotification = props => <CNotifcation {...props} />;


export default CustomNotification;
