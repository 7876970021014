import React, {Fragment}  from 'react';
import {List, Datagrid, TextField, TopToolbar, CreateButton, RefreshButton,
        SimpleForm, Edit, TextInput, Link, Create, required} from 'react-admin';
import Box from '@material-ui/core/Box';
import Moment from 'moment';

const LocationsActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            conname: 'button',
        }) }
        <CreateButton basePath={basePath} />
        <RefreshButton />
    </TopToolbar>
);
const CreatedDateFormat = ({ record = {} }) => <span>{record.createdDatetime ? Moment(record.createdDatetime).format('DD MMM YYYY') : ""}</span>;
const ModifiedDateFormat = ({ record = {} }) => <span>{record.modifiedDatetime ? Moment(record.modifiedDatetime).format('DD MMM YYYY HH:MM:SS') : ""}</span>;

export const LocationList = props => (
    <List sort={{ field: 'name', order: 'ASC' }} {...props} title="Locations" actions={<LocationsActions />} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
          <TextField source="id" />
          <TextField source="name" />
          <CreatedDateFormat source="createdDatetime" label="Created" />
          <ModifiedDateFormat source="modifiedDatetime" label="Modified" />
        </Datagrid>
    </List>
);

const EditLocationTitle = ({ record }) => {
  return <span>{record && record.id > 0 ? `Edit Location ${record.name} (${record.id})` : 'Create Location'}</span>;
};
const EditLocationBreadCrumb = ({ record }) => {
  return  <div className="breadcrumbs"><Link to={"/location"}>Locations</Link> &nbsp;>{record && record.id > 0 ? ` Edit Location: ${record.name} (${record.id})` : 'Create'}</div>;
};

class LocationForm extends React.Component {
  render() {
    return (
      <SimpleForm {...this.props}>
        <Fragment>
          <Box display="flex" style={{ width: '98%', paddingLeft: '1%' }}>
            <EditLocationBreadCrumb {...this.props} />
          </Box>
          <Box display="flex" justifyContent="center" style={{ width: '98%', paddingLeft: '1%' }}>
            <TextInput multiline options={{ fullWidth: true }}  source="name" validate={required("Location cannot be blank")}  />
          </Box>
        </Fragment>
      </SimpleForm>
    )
  }
}
export const LocationEdit = props => (
  <Edit title={<EditLocationTitle />}  {...props}>
      <LocationForm {...props} />
  </Edit>
);
export const LocationCreate = props => (
  <Create title={<EditLocationTitle />}  {...props}>
      <LocationForm {...props} />
  </Create>
);
