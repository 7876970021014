// src/screens/Login.js
import React from 'react';
//import { useLogin } from 'react-admin'; // useNotify
import { makeStyles } from '@material-ui/core/styles';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { withTypes } from 'react-final-form';
import { useLocation } from 'react-router-dom';
import { Notification, useLogin, useNotify, TextInput, PasswordInput, required } from 'react-admin';
import {
    Button,
    CircularProgress,
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import BellIcon from '@material-ui/icons/Notifications';
import LockIcon from '@material-ui/icons/Lock';
import EmailIcon from '@material-ui/icons/Email';
import Logo from '../images/tts_logo_long.png'
import CheckInCounter from '../images/hospital-check-in-counter.png'
import * as qs from 'query-string';

 const useStyles = makeStyles(theme => ({
     main: {
         display: 'flex',
         flexDirection: 'column',
         minHeight: '80vh',
         alignItems: 'center',
         justifyContent: 'flex-start',
         backgroundColor: 'white',
         backgroundRepeat: 'no-repeat',
         backgroundSize: 'cover',
         marginTop: '20vh',
         backgroundImage: 'URL(/dotgrid.png)'
     },
     emailIcon:  {
       width: '5%',
     },
     emailInput: {
       width: '100%',
     },
     passwordInput: {
       width: '100%',
     },
     loginCard: {
       marginTop: '6em',
     },
     card: {
         minWidth: 300,
         marginTop: '6em',
     },
     avatar: {
         margin: '1em',
         display: 'flex',
         justifyContent: 'center',
     },
     icon: {
         backgroundColor: theme.palette.secondary.main,
     },
     welcomeBack: {
       fontSize: '3em',
     },
     keepConnected: {
       marginTop: '1em',
       fontSize: '1em',
       lineHeight: '2em'
     },
     hint: {
         marginTop: '1em',
         display: 'flex',
         justifyContent: 'center',
         color: theme.palette.grey[500],
     },
     form: {
         padding: '0 1em 1em 1em',
     },
     input: {
         marginTop: '1em',
     },
     actions: {
         padding: '0 1em 1em 1em',
     },
     logoBox: {
       maxWidth: '750px'
     },
     loginBox: {
       marginTop: '3%',
     }
 }));

interface FormValues {
    emailAddress?: string;
    password?: string;
}

const { Form } = withTypes();

const Login = ({ theme }) => {
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation();

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        var attemptedURL = localStorage.getItem('attemptedURL')
        if (attemptedURL) {
          attemptedURL = attemptedURL.substring(attemptedURL.lastIndexOf("?") + 1, attemptedURL.length)
          var parsed = qs.parse(attemptedURL);
          localStorage.removeItem('attemptedURL')
          if (!location.state) {
            location.state = {}
          }
          if (location.state && parsed.mr) {
            location.state['nextPathname'] = '/patient/checkIn?mr=' + parsed.mr
          }
        }
        login(auth, location.state ? location.state.nextPathname : '/').catch(
            (error: Error) => {
                setLoading(false);
                notify(
                    typeof error === 'string'
                        ? error
                        : typeof error === 'undefined' || !error.message
                        ? 'ra.auth.sign_in_error'
                        : error.message,
                    'warning'
                );
            }
        );
    };


    return (
          <div className={classes.main}>
            <Form
            onSubmit={handleSubmit}
            render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} noValidate>
                  <Box display="flex" justifyContent="center">
                  <Box width="75%">
                    <Box display="flex" justifyContent="space-between">
                      <Box width="48%" className={classes.logoBox}>
                        <img src={Logo} width='100%' alt='LACENTA Logo' />
                        <img src={CheckInCounter} width='100%' alt='LACENTA Logo' />
                      </Box>
                      <Box width="48%" className={classes.loginBox}>
                          <div className={classes.welcomeBack}>
                              Welcome Back :)
                          </div>
                          <div className={classes.keepConnected}>
                               Please login with your personal information
                               by email address and password { true && <BellIcon /> }
                          </div>
                          <div className={classes.hint}>
                              {
                                //Hint: demo / demo
                              }
                          </div>
                          <div className={classes.form}>
                              <div>
                                <Box display="flex" justifyContent="space-between">
                                  <Box width="10%" className={classes.emailIcon}>
                                    <EmailIcon className={classes.input} />
                                  </Box>
                                  <Box width="90%" className={classes.emailSection}>
                                    <TextInput disabled={loading} type="email" className={classes.emailInput} source="emailAddress" label="Email Address" validate={[required()]} />
                                  </Box>
                                </Box>
                              </div>
                              <div>
                                <Box display="flex" justifyContent="space-between">
                                  <Box width="10%" className={classes.emailIcon}>
                                    <LockIcon className={classes.input} />
                                  </Box>
                                  <Box width="90%" className={classes.emailSection}>
                                    <PasswordInput disabled={loading} className={classes.passwordInput} source="password" label="Password" validate={[required()]} />
                                  </Box>
                                </Box>
                                <Box display="flex" justifyContent="space-between">
                                  <Box width="100%">
                                    <Button width="100%"
                                        variant="contained"
                                        type="submit"
                                        color="primary"
                                        disabled={loading}
                                        fullWidth
                                    >
                                        {loading && (
                                            <CircularProgress
                                                size={25}
                                                thickness={2}
                                            />
                                        )}
                                        Login Now
                                    </Button>
                                  </Box>
                                </Box>
                              </div>
                          </div>
                      </Box>
                    </Box>
                  </Box>
                </Box>

            <Notification />
          </form>
        )}
/>
          </div>
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};


export default Login;
