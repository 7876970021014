import React, {Fragment} from 'react';
import {
  List, Datagrid, TextField, Pagination, Link, ReferenceInput,
  ArrayField, SingleFieldList, CreateButton, TopToolbar, RefreshButton, Button, useDataProvider,
  Edit, SimpleForm, TextInput, BooleanInput, SelectInput, Create, regex, minLength, required } from 'react-admin';
import TrueIcon from '@material-ui/icons/Done';
import FalseIcon from '@material-ui/icons/Clear';
import Moment from 'moment';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconSave from '@material-ui/icons/Save';
import IconCancel from '@material-ui/icons/Cancel';
import { toast } from 'react-toastify';
import { useForm, useField } from 'react-final-form';

const UserActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <CreateButton basePath={basePath} />
        <RefreshButton />
    </TopToolbar>
);

const IsEnabled = ({ record }) => {
  return <span>{record && !record.isLocked ? <TrueIcon/> : <FalseIcon/>}</span>
};
const CreatedDateFormat = ({ record = {} }) => <span>{record.createdDatetime ? Moment(record.createdDatetime).format('DD MMM YYYY') : ""}</span>;
const ModifiedDateFormat = ({ record = {} }) => <span>{record.modifiedDatetime ? Moment(record.modifiedDatetime).format('DD MMM YYYY HH:MM:SS') : ""}</span>;
//<ImageField source="imageUrl" label="Image" />
const UsersPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />

const ChipRole = ({ record = {} }) => <Chip clickable label={
  record.role === 'ROLE_ADMIN' ? 'Admin' : record.role === 'ROLE_USER' ? 'User' : record.role === 'ROLE_TENANT_ADMIN' ? 'Tenant Admin' : ''}
/>
export const UserList = props => (
    <List sort={{ field: 'id', order: 'DESC' }} perPage={50} {...props} actions={<UserActions />} pagination={< UsersPagination />}  bulkActionButtons={false} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="firstName" label="First Name" />
            <TextField source="lastName" label="Last Name"/>
            <TextField source="email" />
            <IsEnabled source="isLocked" label="Account is Enabled"  />
            <ArrayField source="roles" linkType={false} ><SingleFieldList><ChipRole source="role" /></SingleFieldList></ArrayField>
            <CreatedDateFormat source="createdDatetime" label="Created" />
            <ModifiedDateFormat source="modifiedDatetime" label="Modified" />
        </Datagrid>
    </List>
);

var availableRoles = [
    {id : 'ROLE_USER', value: 'ROLE_USER', name: 'User'},
    {id : 'ROLE_TENANT_ADMIN', value: 'ROLE_TENANT_ADMIN', name: 'Tenant Admin'},
];

var availableRolesAdmin = [
    {id : 'ROLE_ADMIN', value: 'ROLE_ADMIN', name: 'Admin'},
    {id : 'ROLE_USER', value: 'ROLE_USER', name: 'User'},
    {id : 'ROLE_TENANT_ADMIN', value: 'ROLE_TENANT_ADMIN', name: 'Tenant Admin'},
];

const EditUserTitle = ({ record }) => {
  return <span>Edit User {record ? `${record.name} (${record.id})` : ''}</span>;
};

const EditUserBreadCrumb = ({ record }) => {
  return  <div className="breadcrumbs"><Link to={"/User"}>Users</Link> &nbsp;> Edit User: {record ? `${record.name} (${record.id})` : ''}</div>;
};
const confirmPasswordValidation = (value, allValues) => {
    if (!value) {
        return 'Confirm Password is required';
    }
    if (allValues.password !== value) {
        return 'Password and Confirm Password do not match';
    }
    return null;
}
const validateDigit = regex(/\d/, 'Password must contain a digit');
const validateLowercase = regex(/[a-z]/, 'Password must contain a lowercase character');
const validateUppercase = regex(/[A-Z]/, 'Password must contain an uppercase character');
const validateFancy = regex(/[@#$%^&+=]/, 'Password must contain one of the @#$%^&+= characters');
const validatePassword = [required(), minLength(8), validateLowercase, validateUppercase, validateDigit, validateFancy];
const validateConfirmPassword = [required(), minLength(8), confirmPasswordValidation];

const ChangePassword = props => {
  return <Fragment>
    <TextInput autocomplete="off" source="password" type="password" validate={validatePassword} fullWidth />
    <TextInput autocomplete="off" source="confirmPassword" label="Confirm Password" type="password" validate={validateConfirmPassword} fullWidth />
  </Fragment>
}

class UserForm extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        showChangePassword : false,
        showChangePasswordDialog : false
      }
  }
  render() {
    const {
        showChangePasswordDialog
    } = this.state;
    const selfUR = this;
    function UserFunction() {
      const dataProvider = useDataProvider();
      const form = useForm();
      const userRoleName = useField("userRoleName", form);
      const userRoleNames = useField("userRoleNames", form);
      const password = useField("password", form);
      const tenantId = localStorage.tenantId;
      const tenantName = localStorage.tenantName;
      var isAdmin = false;
      var roleIsNotAdmin = userRoleName.input.value !== 'ROLE_ADMIN'
      if (localStorage.permissions === "ROLE_ADMIN")
        isAdmin = true;
      if (userRoleNames.input.value && userRoleNames.input.value.length > 0) {
        form.change("userRoleName", userRoleNames.input.value[0])
        form.change("userRoleNames", null)
      }
      //form.change("userRoleName", userRoleNames.input.value[0])
      if (!isAdmin) {
        form.change("tenantId", tenantId)
        form.change("tenantName", tenantName)
      }
      function openChangePasswordDialog() {
        selfUR.setState({
          showChangePasswordDialog: true
        })
      }
      function closeChangePasswordDialog() {
        selfUR.setState({
          showChangePasswordDialog: false
        })
      }
      function submitChangedPassword() {
          var userId = 0;
          if (document.getElementById('UserId'))
            userId = document.getElementById('UserId').value;
          document.getElementById('password').getAttribute("aria-invalid")
          if (document.getElementById('password').value === '' || document.getElementById('confirmPassword').value === '' ||
            document.getElementById('password').getAttribute("aria-invalid") === "true" || document.getElementById('confirmPassword').getAttribute("aria-invalid") === "true") {
            toast.warn("Please make sure password and password confirmation are valid before saving", {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: false,
              hideProgressBar: true
            });
          } else {
            toast.info("Changing password, please wait...", {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: false,
              hideProgressBar: true
            });
            dataProvider.update('user/password/' + password.input.value,{ id: userId, data: {}}).then((submitted) => {
              toast.dismiss()
              toast.success("Password Changed", {
                position: toast.POSITION.BOTTOM_CENTER,
                closeButton: true,
                hideProgressBar: true,
                autoClose: 1000
              });
              selfUR.setState({
                showChangePasswordDialog: false
              })
            }).catch(({ status, statusText, headers, body }) => {
              console.log("error submitting patient results ", body);
              toast.dismiss()
              toast.error(body && body.message ? body.message : "Error", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                autoClose: 1000
              });
            });
          }
      }
      return (
        <>
          <Dialog fullWidth={true} maxWidth={"sm"}  disableBackdropClick disableEscapeKeyDown open={showChangePasswordDialog} >
              <DialogTitle>Change Password </DialogTitle>
              <DialogContent>
                <ChangePassword />
              </DialogContent>
            <DialogActions>
              <Button
                label="Save Changed Password"
                onClick={() => submitChangedPassword()}
              >
                <IconSave />
              </Button>
              <Button
              label="Cancel"
              onClick={() => closeChangePasswordDialog()}
              >
                <IconCancel />
              </Button>
            </DialogActions>
          </Dialog>
          <Fragment>
            <Box display="flex" justifyContent="center" style={{ width: '98%', marginLeft: '1%', marginRight: '1%', }}>
              <Box display="flex-row" style={{ width: '49%', marginRight: '1%' }}>
                <TextInput source="firstName" validate={required()} fullWidth />
                <TextInput source="lastName" validate={required()} fullWidth />
                <TextInput source="email" validate={required()} fullWidth />
                <Box display="flex" style={{ width: '100%', marginRight: '1%' }}>
                  <BooleanInput source="isLocked" label="Account is Locked" />
                </Box>
                {isAdmin && roleIsNotAdmin && <ReferenceInput label="Tenant" source="tenantId" reference="tenant" alwaysOn>
                    <SelectInput optionText="businessName" fullWidth />
                </ReferenceInput> }
                {isAdmin && <SelectInput label="Roles" choices={availableRolesAdmin} source="userRoleName" fullWidth /> }
                {!isAdmin && <TextInput source="tenantId" label="Tenant Id"  style={{ display: 'none' }}/> }
                {!isAdmin && <TextInput disabled source="tenantName" label="Tenant Name"/> }
                {!isAdmin && <SelectInput validate={required()}  label="Roles" choices={availableRoles} source="userRoleName" fullWidth /> }
              </Box>
              <Box display="flex-row"  style={{  width: '49%', marginLeft: '1%' }}>
                { selfUR.props.isedit === "true" && <Button variant="contained" color="primary" label="Change Password" onClick={() => openChangePasswordDialog()} />}
                { selfUR.props.isedit === "true" && <TextInput id="UserId" source="id" fullWidth style={{display: 'none'}}/> }
                { selfUR.props.isedit === "false" && <ChangePassword {...selfUR.props} /> }
              </Box>
            </Box>
          </Fragment>
        </>
      )
    }
    return (
      <Fragment>
        { selfUR.props.isedit  === "true" && <Edit undoable={false} title={<EditUserTitle />}  {...this.props}>
          <SimpleForm>
            <EditUserBreadCrumb />
            <UserFunction {...this.props}/>
          </SimpleForm>
        </Edit> }
        { selfUR.props.isedit === "false" && <Create undoable={false} title={<EditUserTitle />}  {...this.props}>
          <SimpleForm>
            <UserFunction {...this.props}/>
          </SimpleForm>
        </Create> }
      </Fragment>
    )
  }
}

export const UserEdit = props => (
  <section className='section'>
    <UserForm {...props} isedit="true" />
  </section>
);
export const UserCreate = props => (
  <section className='section'>
    <UserForm {...props} isedit="false"  />
  </section>
);
