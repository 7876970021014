import React, {Fragment, useEffect, useCallback}  from 'react';
import {List, Datagrid, TextField, TopToolbar, RefreshButton,
  AutocompleteInput, ReferenceInput, TextInput, Pagination,
  SimpleForm, SelectInput, Button, Loading, BooleanInput,
  useRefresh, required, useDataProvider} from 'react-admin';
import Moment from 'moment';
import IconPlus from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';
import IconUpload from '@material-ui/icons/CloudUpload';
import IconRemove from '@material-ui/icons/RemoveCircle';
import { toast } from 'react-toastify';
import { useForm, useField } from 'react-final-form';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextFieldCore from '@material-ui/core/TextField';
import InconclusiveIcon from '@material-ui/icons/HelpOutlineRounded';
import DetectedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import NotDetectedIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
//import { useCookies } from 'react-cookie';
import IconButton from "@material-ui/core/IconButton";
import VaccinatedIcon from '@material-ui/icons/LocalPharmacy';
import VerifiedNegativeIcon from '@material-ui/icons/CheckCircle';
import VaccinatedWithIdIcon from '@material-ui/icons/RecentActors';
import CoreButton from '@material-ui/core/Button';
import * as qs from 'query-string';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles({
  dateOfBirth : {
    paddingTop: '20px',
    fontSize: '20px',
    width: '25%'
  },
  dateOfBirthInput : {
    maxWidth: '20%',
    paddingRight: '1%',
  },
  dateOfBirthInputMobile: {
    maxWidth: '100%',
  },
  dateOfBirthSelectInput : {
    paddingRight: '1%',
  },
  editor: {
    border: '1px solid gray',
    minHeight: '6em',
    height: '20vh',
    maxHeight: '20vh',
    overflowY: 'auto'
  },
  cdcScreeningSurvey : {
    paddingTop: '20px',
    paddingBottom: '20px',
    fontSize: '20px',
    border: '1px solid grey'
  },
  cdcScreeningSurveyLeftColHeader: {
    width: '60%',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  cdcScreeningSurveyRightColHeader: {
    width: '40%',
    textAlign: 'center',
    fontWeight: 'bold'
  },
  cdcScreeningSurveyLeftCol: {
    width: '58%',
    textAlign: 'left',
    marginLeft: '1%',
    marginRight: '1%'
  },
  cdcScreeningSurveyRightCol: {
    width: '38%',
    textAlign: 'center',
    marginLeft: '1%',
    marginRight: '1%'
  },
  no : {
    width: '100%',
    color: 'green',
    borderRadius: 0,
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: 'green'
    },
    height: '100%'
  },
  noSelected : {
    width: '100%',
    color: '#FFFFFF',
    backgroundColor: 'green',
    borderRadius: 0,
    height: '100%'
  },
  yes : {
    width: '100%',
    color: '#CC0006',
    borderRadius: 0,
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#CC0006'
    },
    height: '100%'
  },
  yesSelected : {
    width: '100%',
    color: '#FFFFFF',
    backgroundColor: '#CC0006',
    borderRadius: 0,
    height: '100%'
  },
  yesNoButtonsContainer: {
    height: '100%'
  },
  yesNoButtonsElement: {
    alignItems: 'stretch',
    height: '100%'
  },
  approved: {
    color: '#FFFFFF',
    backgroundColor: '#2A78E4',
    width: '100%',
    textAlign: 'center',
  },
  notApproved: {
    color: '#FFFFFF',
    backgroundColor: '#CC0006',
    width: '100%',
    textAlign: 'center',
  },
  vaccinatedIconLargeSelected: {
    width: '100%',
    color: '#FFFFFF',
    backgroundColor: 'green',
    borderRadius: 0
  },
  vaccinatedIconLarge: {
    width: '100%',
    color: 'green',
    borderRadius: 0,
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: 'green'
    }
  },
  verifiedNegativeIconLargeSelected: {
    width: '100%',
    color: '#FFFFFF',
    backgroundColor: 'purple',
    borderRadius: 0
  },
  verifiedNegativeIconLarge: {
    width: '100%',
    color: 'purple',
    borderRadius: 0,
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: 'purple'
    }
  },
  formContainer : {
    width: '95%',
    maxWidth: '600px',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  notClearToEnter: {
    color: '#CC0006',
    fontSize: 30
  },
  surveyButtons: {
    fontSize: '1.5rem !important'
  }
});

const PatientCheckInActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <NewPatientCheckIn label="Check-in New Patient" basePath={basePath} />
    </TopToolbar>
);


const PatientCheckInPagination = props => <Pagination style={{ display: 'block' }} rowsPerPageOptions={[25, 50, 100]} {...props} />

const PatientOptionRenderer = ({ record }) => (
  <div>
      {record.firstName} {record.lastName} - {record.email} - {record.phone} - {record.dateOfBirth ? Moment(record.dateOfBirth).format('DD MMM YYYY') : ""}
  </div>
);

class PatientCheckInSection extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isLoading : false,
        firstNameError : null,
        lastNameError : null,
        emailError : null,
        phoneError : null,
        locationCodeError: null,
        testTypeError : null,
        showExistingPatientCheckInDialog : false,
        showNewPatientSurvey : false,
        dobError : false,
        address1Error : null,
        cityError : null,
        stateError : null,
        zipcodeError: null,
        genderError: null,
        ethnicityError: null,
        isPregnantError: null,
        locationList: [],
        testTypeList: [],
        locationCode: null,
        testType : null,
        checkedInExistingPatientId : null,
        experienceSymptomsValue: 'NO',
        closeContactValue: 'NO',
        isolationValue: 'NO',
        waitingOnResultsValue: 'NO',
        approvedToEnter : true,
        notApprovedToEnter : null,
        patientToCheckOut: {
          patient: {}
        },
        hasInitialized : false,
        selectedSurveyIndex: -1,
        surveyAnswers: [],
        surveys: [],
        disableTestType: false,
        mounted: false,
        passedMRID: 0
      }
  }
  render() {
    const {
      isLoading,
      showExistingPatientCheckInDialog,
      locationCodeError,
      testTypeError,
      firstNameError,
      lastNameError,
      emailError,
      phoneError,
      dobError,
      notApprovedToEnter,
      surveys,
      disableTestType,
      address1Error,
      cityError,
      zipcodeError,
      stateError,
      ethnicityError,
      genderError,
      mounted,
      passedMRID
    } = this.state;
    const selfUR = this;
    function ExistingPatientCheckIn() {
      const refresh = useRefresh();
      const form = useForm();
      const classes = useStyles();
      //const [cookies, setCookie] = useCookies();
      const checkHashedMR = () => {
        var hashedLocation = window.location.hash
        var parsed = 0
        if (hashedLocation) {
          hashedLocation = hashedLocation.substring(hashedLocation.lastIndexOf("?") + 1, hashedLocation.length)
          parsed = qs.parse(hashedLocation);
        }
        if ((!mounted && parsed.mr) || (parsed.mr && parsed.mr !== null && parsed.mr !== 0 && parsed.mr !== passedMRID)) {
          //console.log("parsed.mr ", parsed.mr);
          selfUR.setState(
            { mounted : true, passedMRID : parsed.mr}, () => {
              fetchPatientByMappingResultId(parsed.mr)
          });
        }
      }
      useEffect(checkHashedMR)
      selfUR.showExistingPatientCheckInDialog = () => {
        selfUR.setState({
          isLoading : false,
          firstNameError : null,
          lastNameError : null,
          phoneError : null,
          emailError : null,
          testTypeError : null,
          locationCodeError : null,
          dobError : null,
          address1Error: null,
          cityError: null,
          stateError: null,
          zipcodeError: null,
          ethnicityError: null,
          genderError: null,
          isPregnantError: null,
          locationList : [],
          testTypeList: [],
          locationCode : null,
          experienceSymptomsValue: 'NO',
          closeContactValue: 'NO',
          isolationValue: 'NO',
          waitingOnResultsValue: 'NO',
          approvedToEnter : true,
          notApprovedToEnter : false
        }, () => selfUR.initializeExisitingForm());
      }
      async function fetchAllLocationsForExistingPatient () {
        await dataProvider.getList('location/list',{
              filter: { },
              sort: { field: 'id', order: 'DESC' },
              pagination: { page: 1, perPage: 5000 },
        }).then((returnedLocations) => {
            selfUR.setState({
                locationList : returnedLocations.data,
            }, () => fetchAllTestTypesForExistingPatient());
        }).catch(({ status, statusText, headers, body }) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
        });
      }
      async function fetchAllTestTypesForExistingPatient () {
        await dataProvider.getList('test/type',{
              filter: { },
              sort: { field: 'id', order: 'DESC' },
              pagination: { page: 1, perPage: 5000 },
        }).then((returnedLocations) => {
            selfUR.setState({
                showExistingPatientCheckInDialog: true,
                isLoading : false,
                firstNameError : null,
                lastNameError : null,
                phoneError : null,
                emailError : null,
                testTypeError : null,
                locationCodeError : null,
                dobError : null,
                address1Error : null,
                cityError : null,
                stateError : null,
                zipcodeError: null,
                ethnicityError: null,
                genderError: null,
                isPregnantError: null,
                testTypeList : returnedLocations.data,
                locationCode : null
            });
        }).catch(({ status, statusText, headers, body }) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
        });
      }
      selfUR.initializeExisitingForm = () => {
        form.change('locationCode', null);
        form.change('testType', null);
        form.change('firstName', null);
        form.change('lastName', null);
        form.change('gender', null);
        form.change('email', null);
        form.change('phone', null);
        form.change('dobday', null);
        form.change('dobmonth', null);
        form.change('dobyear', null);
        form.change('notes', null);
        form.change('address1', null);
        form.change('address2', null);
        form.change('city', null);
        form.change('state', null);
        form.change('zipcode', null);
        form.change('ethnicity', null);
        form.change('gender', null);
        form.change('isPregant', false);
      }
      const dataProvider = useDataProvider();
      function handleLocationTextChange(event) {
        var thisEvent = event.target && event.target.value ? event.target.value : null
        selfUR.state.locationCode = thisEvent;
      }
      const firstName = useField("firstName", form);
      const lastName = useField("lastName", form);
      const email = useField("email", form);
      const phone = useField("phone", form);
      const dateOfBirth = useField("dateOfBirth", form);
      const notes = useField("notes", form);
      const gender = useField("gender", form);
      const ethnicity = useField("ethnicity", form);
      const isPregnant = useField("isPregnant", form);
      const address1 = useField("address1", form);
      const address2 = useField("address2", form);
      const city = useField("city", form);
      const state = useField("state", form);
      const zipcode = useField("zipcode", form);

      function updateNotes (event) {
        //notesUpdated = event.target.value
        form.change('notes', event.target.value);
      }
      function checkOpenSurvey() {
        setTimeout(function() {
          if (selfUR.state.surveys && selfUR.state.surveys.length > 0) {
            document.getElementById('OpenSurvey').click()
            setTimeout(function() {
              document.getElementById('SetDefaultSurveyAnswers').click()
            }, 50)
          } else {
            refresh()
          }
        }, 200)
      }
      selfUR.saveCheckInExistingPatient = () => {
        selfUR.setState({
          isLoading : true
        });
        toast.info("Checking-in Existing patient, please wait...", {
          position: toast.POSITION.BOTTOM_CENTER,
          closeButton: false,
          hideProgressBar: true
        });
        var locationCode =  document.getElementById('existingLocationCode').value
        var testType = document.getElementById('existingTestType').value
        //setCookie('testType', testType, { path: '/' });
        //setCookie('locationCode', locationCode, { path: '/' });
        console.info("locationCode ", locationCode)
        const token = localStorage.getItem('token');
        var today = new Date();
        var d = today.getDate();
        var m = today.getMonth() + 1; //Month from 0 to 11
        var y = today.getFullYear()
        const requestOptions = {
           method: 'POST',
           headers: { 'Content-Type': 'application/json', Authorization: token  },
           body: JSON.stringify(
              {
                 locationCode: locationCode,
                 testType: testType,
                 result: 'PROCESSING',
                 collectionDate: y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d),
                 notes: notes.input.value,
                 patient : {
                   id : selfUR.state.checkedInExistingPatientId,
                   firstName: firstName.input.value,
                   lastName: lastName.input.value,
                   email: email.input.value,
                   phone: phone.input.value,
                   dob: dateOfBirth.input.value,
                   gender : gender.input.value,
                   ethnicity: ethnicity.input.value,
                   address1: address1.input.value,
                   address2: address2.input.value,
                   city: city.input.value,
                   state: state.input.value,
                   zipcode: zipcode.input.value,
                   isPregnant: isPregnant.input.value,
                   country : 'USA',
                   language: selfUR.state.selectedSurveyIndex === 0 ? "en" : "es",
                 }
              }
           )
        };
        fetch('/api/v1/patient/', requestOptions)
        .then(response => response.json())
        .then(responseData => {
          toast.dismiss()
          if (responseData && !responseData.id && !responseData.success) {
            var firstNameError = null
            var lastNameError = null
            var emailError = null
            var phoneError = null
            var testTypeError = null
            var locationCodeError = null
            var dobError = null
            var address1Error = null
            var cityError = null
            var stateError = null
            var zipcodeError = null
            var ethnicityError = null
            var genderError = null
            var isPregnantError = null
            if (responseData.details) {
              for (var i = 0; i < responseData.details.length; i++) {
                if (responseData.details[i].field === 'patient.firstName')
                  firstNameError = responseData.details[i].message
                if (responseData.details[i].field === 'patient.lastName')
                  lastNameError = responseData.details[i].message
                if (responseData.details[i].field === 'patient.phone')
                  phoneError = responseData.details[i].message
                if (responseData.details[i].field === 'patient.email')
                  emailError = responseData.details[i].message
                if (responseData.details[i].field === 'testType')
                  testTypeError = responseData.details[i].message
                if (responseData.details[i].field === 'locationCode')
                  locationCodeError = responseData.details[i].message
                if (responseData.details[i].field === 'patient.dob')
                  dobError = responseData.details[i].message
                if (responseData.details[i].field === 'patient.address1')
                  address1Error = responseData.details[i].message
                if (responseData.details[i].field === 'patient.city')
                  cityError = responseData.details[i].message
                if (responseData.details[i].field === 'patient.state')
                  stateError = responseData.details[i].message
                if (responseData.details[i].field === 'patient.zipcode')
                  zipcodeError = responseData.details[i].message
                if (responseData.details[i].field === 'patient.ethnicity')
                  ethnicityError = responseData.details[i].message
                if (responseData.details[i].field === 'patient.gender')
                  genderError = responseData.details[i].message
                if (responseData.details[i].field === 'patient.isPregnant')
                  isPregnantError = responseData.details[i].message
              }
            }
            selfUR.setState({
                firstNameError : firstNameError,
                lastNameError : lastNameError,
                phoneError : phoneError,
                emailError : emailError,
                testTypeError : testTypeError,
                locationCodeError : locationCodeError,
                dobError : dobError,
                locationCode: locationCode,
                testType: testType,
                address1Error: address1Error,
                cityError : cityError,
                stateError : stateError,
                zipcodeError: zipcodeError,
                ethnicityError : ethnicityError,
                genderError : genderError,
                isPregnantError : isPregnantError,
                isLoading : false
            });
            toast.error(responseData && responseData.message ? responseData.message : "Error", {
              position: toast.POSITION.BOTTOM_CENTER,
              hideProgressBar: true,
              closeButton: true
            });
          } else {
            toast.dismiss()
            toast.success("Existing Patient Checked-in", {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: true,
              hideProgressBar: true,
              autoClose: 1000
            });
            selfUR.setState({
                showExistingPatientCheckInDialog: false,
                surveys : responseData.surveys,
                isLoading : false,
                patientToCheckOut: {id : responseData.id}
            }, checkOpenSurvey());
          }
        }).catch(({ status, statusText, headers, body }) => {
          console.log("error saving results ", body);
          toast.dismiss()
          if (body && !body.success) {
            var firstNameError = null
            var lastNameError = null
            var emailError = null
            var phoneError = null
            var testTypeError = null
            var locationCodeError = null
            var dobError = null
            var address1Error = null
            var cityError = null
            var stateError = null
            var zipcodeError = null
            var ethnicityError = null
            var genderError = null
            var isPregnantError = null
            if (body.details) {
              for (var i = 0; i < body.details.length; i++) {
                if (body.details[i].field === 'patient.firstName')
                  firstNameError = body.details[i].message
                if (body.details[i].field === 'patient.lastName')
                  lastNameError = body.details[i].message
                if (body.details[i].field === 'patient.phone')
                  phoneError = body.details[i].message
                if (body.details[i].field === 'patient.email')
                  emailError = body.details[i].message
                if (body.details[i].field === 'testType')
                  testTypeError = body.details[i].message
                if (body.details[i].field === 'locationCode')
                  locationCodeError = body.details[i].message
                if (body.details[i].field === 'patient.dob')
                  dobError = body.details[i].message
                if (body.details[i].field === 'patient.address1')
                  address1Error = body.details[i].message
                if (body.details[i].field === 'patient.city')
                  cityError = body.details[i].message
                if (body.details[i].field === 'patient.state')
                  stateError = body.details[i].message
                if (body.details[i].field === 'patient.zipcode')
                  zipcodeError = body.details[i].message
                if (body.details[i].field === 'patient.ethnicity')
                  ethnicityError = body.details[i].message
                if (body.details[i].field === 'patient.gender')
                  genderError = body.details[i].message
                if (body.details[i].field === 'patient.isPregnant')
                  isPregnantError = body.details[i].message
              }
            }
            selfUR.setState({
                firstNameError : firstNameError,
                lastNameError : lastNameError,
                phoneError : phoneError,
                emailError : emailError,
                testTypeError : testTypeError,
                locationCodeError : locationCodeError,
                dobError : dobError,
                locationCode: locationCode,
                testType: testType,
                address1Error: address1Error,
                cityError : cityError,
                stateError : stateError,
                zipcodeError: zipcodeError,
                ethnicityError : ethnicityError,
                genderError : genderError,
                isPregnantError : isPregnantError
            });
          }
          toast.error(body && body.message ? body.message : "Error", {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
            closeButton: true
          });
          selfUR.setState({
              isLoading : false
          });
        })
      }
      async function fetchPatientByExistingId (id) {
        await dataProvider.getOne('patient/existing',{
                id
        }).then((patientReturned) => {
            if (patientReturned && patientReturned.data) {
              form.change('firstName', patientReturned.data.firstName);
              form.change('lastName', patientReturned.data.lastName);
              form.change('gender', patientReturned.data.gender);
              form.change('email', patientReturned.data.email);
              form.change('phone', null);
              form.change('phone', patientReturned.data.phone);
              form.change('dateOfBirth', patientReturned.data.dateOfBirth);
              form.change('notes', patientReturned.data.notes);
              form.change('address1', patientReturned.data.address1);
              form.change('address2', patientReturned.data.address2);
              form.change('city', patientReturned.data.city);
              form.change('state', patientReturned.data.state);
              form.change('zipcode', patientReturned.data.zipcode);
              form.change('ethnicity', patientReturned.data.ethnicity);
              form.change('gender', patientReturned.data.gender);
              form.change('isPregnant', patientReturned.data.isPregnant ? patientReturned.data.isPregnant : false);
              selfUR.setState({
                  notApprovedToEnter : null,
                  checkedInExistingPatientId : patientReturned.data.id,
                  //disableTestType : patientReturned.data.locationCode === 'PRE-REG FORM',
                  testType : patientReturned.data.testType ? patientReturned.data.testType : null, //cookies.testType,
              }, () => fetchAllLocationsForExistingPatient());
            } else {
              toast.error("Could not locate patient with that id", {
                position: toast.POSITION.BOTTOM_CENTER,
                hideProgressBar: true,
                closeButton: true
              });
            }
        }).catch(({ status, statusText, headers, body }) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
          toast.error("Could not locate patient with that id", {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
            closeButton: true
          });
        });
      }
      async function fetchPatientByMappingResultId (id) {
        await dataProvider.getOne('patient/result',{
                id
        }).then((patientReturned) => {
          if (patientReturned && patientReturned.data) {
            form.change('firstName', patientReturned.data.firstName);
            form.change('lastName', patientReturned.data.lastName);
            form.change('gender', patientReturned.data.gender);
            form.change('email', patientReturned.data.email);
            form.change('phone', null);
            form.change('phone', patientReturned.data.phone);
            form.change('dateOfBirth', patientReturned.data.dateOfBirth);
            form.change('notes', patientReturned.data.notes);
            form.change('address1', patientReturned.data.address1);
            form.change('address2', patientReturned.data.address2);
            form.change('city', patientReturned.data.city);
            form.change('state', patientReturned.data.state);
            form.change('zipcode', patientReturned.data.zipcode);
            form.change('ethnicity', patientReturned.data.ethnicity);
            form.change('gender', patientReturned.data.gender);
            form.change('isPregnant', patientReturned.data.isPregnant ? patientReturned.data.isPregnant : false);
            console.log("patientReturned ", patientReturned);
            console.log("patientReturned.data.testType ", patientReturned.data.testType);
            selfUR.setState({
                notApprovedToEnter : null,
                checkedInExistingPatientId : patientReturned.data.id,
                //disableTestType : patientReturned.data.locationCode === 'PRE-REG FORM',
                testType : patientReturned.data.testType ? patientReturned.data.testType : null //cookies.testType,
            }, () => fetchAllLocationsForExistingPatient());
          } else {
            toast.error("Could not locate patient with that id", {
              position: toast.POSITION.BOTTOM_CENTER,
              hideProgressBar: true,
              closeButton: true
            });
          }
        }).catch(({ status, statusText, headers, body }) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
          toast.error("Could not locate patient with that id", {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
            closeButton: true
          });
        });
      }
      selfUR.closeExistingPatientCheckInDialog = () => {
        selfUR.setState({
          showExistingPatientCheckInDialog: false,
          isLoading : false,
        }, () => refresh());
      }
      const existingPatientSelected = patientId => {
        fetchPatientByExistingId(patientId)
        form.change('existingPatient', null);
      };
      const patientOptionInputText = record => `${record.firstName} ${record.lastName} - ${record.email} - ${record.phone} - ${record.dateOfBirth ? Moment(record.dateOfBirth).format('DD MMM YYYY') : ""}`;
      const patientSuggestion = (filterValue, suggestion) => {
        var valueToFilter = `${suggestion.firstName.toLowerCase()} ${suggestion.lastName.toLowerCase()} - ${suggestion.email} - ${suggestion.phone} - ${suggestion.dateOfBirth ? Moment(suggestion.dateOfBirth).format('DD MMM YYYY') : ""}`
        return valueToFilter.includes(filterValue.toLowerCase());
      }
      selfUR.isApprovedToEnter = () => {
        if (selfUR.state.experienceSymptomsValue === 'NO' &&
            selfUR.state.closeContactValue === 'NO' &&
            selfUR.state.isolationValue === 'NO' &&
            selfUR.state.waitingOnResultsValue === 'NO') {
          selfUR.setState({
            approvedToEnter : true
          });
        } else {
          selfUR.setState({
            approvedToEnter : null
          });
        }
        if (selfUR.state.experienceSymptomsValue === null || selfUR.state.closeContactValue === null || selfUR.state.isolationValue === null || selfUR.state.waitingOnResultsValue === null) {
          selfUR.setState({
            notApprovedToEnter : null
          });
        } else {
          if (selfUR.state.experienceSymptomsValue === 'YES' || selfUR.state.closeContactValue === 'YES' || selfUR.state.isolationValue === 'YES' || selfUR.state.waitingOnResultsValue === 'YES') {
            selfUR.setState({
              notApprovedToEnter : true
            });
          } else {
            selfUR.setState({
              notApprovedToEnter : null
            });
          }
        }
      }
      selfUR.experienceSymptoms = (value) => {
        selfUR.setState({experienceSymptomsValue : value}, ()=> selfUR.isApprovedToEnter());
      }
      selfUR.closeContact = (value) => {
        selfUR.setState({closeContactValue : value}, ()=> selfUR.isApprovedToEnter());
      }
      selfUR.isolation = (value) => {
        selfUR.setState({isolationValue : value}, ()=> selfUR.isApprovedToEnter());
      }
      selfUR.waitingOnResults = (value) => {
        selfUR.setState({waitingOnResultsValue : value}, ()=> selfUR.isApprovedToEnter());
      }
      selfUR.closeNewPatientSurvey = () => {
        selfUR.setState({
          showNewPatientSurvey: false,
        }, () => refresh());
      }
      selfUR.checkOutPatientSurvey = () => {
        selfUR.setState({
          showNewPatientSurvey: false,
        }, () => selfUR.checkOutPatient());
      }
      selfUR.checkOutPatient = () => {
        selfUR.setState({
          isLoading : true
        });
        toast.info("Checking-out patient, please wait...", {
          position: toast.POSITION.BOTTOM_CENTER,
          closeButton: false,
          hideProgressBar: true
        });
        dataProvider.deleteMany('patient', {
          ids: [selfUR.state.patientToCheckOut.id]
        }).then((checkout) => {
          toast.dismiss()
          toast.success("Patient Checked-out", {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: true,
            hideProgressBar: true,
            autoClose: 1000
          });
          selfUR.setState({
            showPatientCheckOutDialog: false,
            isLoading : false,
          }, () => refresh());
        }).catch(({ status, statusText, headers, body }) => {
          console.log("error checking out patient ", body);
          toast.dismiss()
          toast.error(body && body.message ? body.message : "Error", {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
            closeButton: true
          });
          selfUR.setState({
              isLoading : false
          });
        });
      }
      const handleLocationCodeChange = (event) => {
        if (event.target.innerHTML && event.target.innerHTML.indexOf("<path") >= 0)
          selfUR.setState({
              locationCode : undefined
          });
        else
          selfUR.setState({
              locationCode : event.target.innerHTML
          });
      }
      const handleTestTypeChange = (event) => {
        if (event.target.innerHTML && event.target.innerHTML.indexOf("<path") >= 0)
          selfUR.setState({
              testType : undefined
          });
        else
          selfUR.setState({
              testType : event.target.innerHTML
          });
      }
      function SurveyDialog() {
        const classes = useStyles();
        const [open, setOpen] = React.useState(false);
        const [approvedToEnter, setApprovedToEnter] = React.useState(true);
        const [notApprovedToEnter, setNotApprovedToEnter] = React.useState(false);
        const [selectedSurvey, setSelectedSurvey] = React.useState({ questions: []});
        const [selectedSurveyIndex, setSelectedSurveyIndex] = React.useState(0);
        const [answers, setAnswers] = React.useState([]);
        const [surveySubmitted, setSurveySubmitted] = React.useState(false);

        const setDefaultAnswers = () => {
          var index = 0;
          if (selectedSurvey && selectedSurvey.questions) {
            selectedSurvey.questions.forEach(function(question) {
              answerQuestion(index++, 'NO')
            });
          }
        };
        const handleClickOpen = () => {
          setOpen(true);
        };
        const handleClose = () => {
          setOpen(false);
        }
        const handleSubmit = () => {
          selfUR.setState({
            isLoading : true,
            showExistingPatientCheckInDialog: true,
          });
          if (selectedSurvey.questions && selectedSurvey.questions.length > 0 && answers) {
            for (var i = 0; i < selectedSurvey.questions.length; i++) {
              selectedSurvey.questions[i].answer = answers[i] === 'YES'
            }
          }
          const token = localStorage.getItem('token');
          const requestOptions = {
             method: 'PUT',
             headers: { 'Content-Type': 'application/json', Authorization: token},
             body: JSON.stringify(
                selectedSurvey
             )
          };
          fetch('/api/v1/patient/survey/' + selfUR.state.patientToCheckOut.id, requestOptions)
          .then(response => response.json())
          .then(responseData => {
            if (approvedToEnter) {
              setOpen(false);
              selfUR.setState({
                isLoading : false,
                showExistingPatientCheckInDialog: false,
                approvedToEnter : approvedToEnter,
                notApprovedToEnter: notApprovedToEnter
              }, () => refresh());
            } else {
              setSurveySubmitted(true);
              selfUR.setState({
                isLoading : false,
                approvedToEnter : approvedToEnter,
                notApprovedToEnter: notApprovedToEnter
              });
            }
          }).catch(({ status, statusText, headers, body }) => {
            console.log("error saving results ", body);
            toast.dismiss()
            toast.error(body && body.message ? body.message : "Error", {
              position: toast.POSITION.BOTTOM_CENTER,
              hideProgressBar: true,
              closeButton: true
            });
            selfUR.setState({
                isLoading : false
            });
          })
        };

        const handleSurveyChange = (index) => {
          setSelectedSurveyIndex(index)
          setOpen(true);
          setSelectedSurvey(surveys[index]);
        };

        const answerQuestion = (index, value) => {
          //var anotherIndex = 0;
          //selectedSurvey.questions.forEach(function(question) {
          //console.log("Setting question answer for ", index);
          setQuestionAnswer(index, value)
          setTimeout(function() {
            setSelectedSurvey(selectedSurveyIndex);
          },300)
        }

        const setQuestionAnswer = useCallback((index, value) => {
          let answer = answers[index];
          answer = value
          answers[index] = answer
          setAnswers(answers)
          selfUR.state.surveyAnswers = answers
          var answerCheckIndex = 0
          setNotApprovedToEnter(false)
          setApprovedToEnter(true)
          if (selectedSurvey && selectedSurvey.questions) {
            selectedSurvey.questions.forEach(function(question) {
              if(selectedSurvey.questions[answerCheckIndex].neededForPass === true && answers[answerCheckIndex] === 'YES') {
                setApprovedToEnter(false)
                setNotApprovedToEnter(true)
              }
              answerCheckIndex++
            });
          }
        }, [answers, selectedSurvey])

        useEffect(() => {
          //console.log("Inside useEffect")
          if (surveys && surveys.length > 0)
            setSelectedSurvey(surveys[selectedSurveyIndex]);
          /*
          var index = 0;
          if (!selfUR.state.hasInitialized && selectedSurvey && selectedSurvey.questions) {
            selectedSurvey.questions.forEach(function(question) {
              //console.log("Setting question answer to no for ", question);
              setQuestionAnswer(index++, 'NO')
            });
            if (selectedSurvey.questions && selectedSurvey.questions.length > 0)
              selfUR.state.hasInitialized = true
          }
          */
        }, [selectedSurvey, setQuestionAnswer, answers, selectedSurveyIndex]);

        return (
          <div>
            <CoreButton style={{ display: 'none' }} id="OpenSurvey" variant="outlined" color="primary" onClick={handleClickOpen}>
              Open alert dialog
            </CoreButton>
            <CoreButton style={{ display: 'none' }} id="SetDefaultSurveyAnswers" variant="outlined" color="primary" onClick={setDefaultAnswers}>
              Set Default Answers
            </CoreButton>
            <Dialog
              disableBackdropClick disableEscapeKeyDown
              fullWidth={true} maxWidth={"md"}
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{false && selectedSurvey && selectedSurvey.name}
                {selectedSurveyIndex === 1 && surveys && surveys.length > 1 && <CoreButton style={{float: 'right'}} onClick={() => handleSurveyChange(0)} color="primary" className={classes.surveyButtons}>
                  English
                </CoreButton> }
                {selectedSurveyIndex === 0 && surveys && surveys.length > 1 && <CoreButton style={{float: 'right'}} onClick={() => handleSurveyChange(1)} color="primary" className={classes.surveyButtons}>
                  Español
                </CoreButton>}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {selectedSurvey && selectedSurvey.header}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {selectedSurvey && selectedSurvey.prefix}
                </DialogContentText>
                <Fragment>
                  { notApprovedToEnter && surveySubmitted && <Box mt={2} justifyContent="center" className={classes.formContainer}>
                    Your survey results indicate that you are <span className={classes.notClearToEnter}>NOT CLEAR</span> to enter<br/><br/>
                  </Box>}
                  {!surveySubmitted && selectedSurvey && selectedSurvey.questions && selectedSurvey.questions.map(function(name, index) {
                   return <Box key={index} display="flex" justifyContent="flex-start" className={classes.cdcScreeningSurvey}>
                    <Box display="flex-row" justifyContent="flex-start" className={classes.cdcScreeningSurveyLeftCol}>
                      {selectedSurvey && selectedSurvey.questions[index].prompt}
                    </Box>
                    <Box display="flex-row" alignContent="center" justifyContent="center" className={classes.cdcScreeningSurveyRightCol}>
                      <Box display="flex" alignContent="stretch" justifyContent="center" className={classes.yesNoButtonsContainer}>
                        <Box flexGrow={1} className={classes.yesNoButtonsElement}>
                          <IconButton
                            onClick={() => answerQuestion(index, 'YES')}
                            className={answers[index] === 'YES' ? classes.yesSelected : classes.yes}
                          >
                            {selectedSurvey && selectedSurvey.yes}
                          </IconButton>
                        </Box>
                        <Box flexGrow={1} className={classes.yesNoButtonsElement}>
                          <IconButton
                            onClick={() => answerQuestion(index, 'NO')}
                            className={answers[index] === 'NO' ? classes.noSelected : classes.no}
                          >
                            {selectedSurvey && selectedSurvey.no}
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  })}
                  {false && approvedToEnter && <Box display="flex" justifyContent="flex-start" className={classes.cdcScreeningSurvey}>
                    <Box display="flex-row" justifyContent="flex-start" className={classes.approved}>
                      APPROVED
                    </Box>
                  </Box>}
                  {false && notApprovedToEnter && <Box display="flex" justifyContent="flex-start" className={classes.cdcScreeningSurvey}>
                    <Box display="flex-row" justifyContent="flex-start" className={classes.notApproved}>
                      NOT APPROVED
                    </Box>
                  </Box> }
               </Fragment>
              </DialogContent>
              <DialogActions style={{marginLeft: 'auto', marginRight: 'auto', display: 'block'}}>
                  {false && approvedToEnter && selectedSurvey && <Box display="flex" alignContent="stretch" justifyContent="center">
                    {false && selectedSurvey.footer}
                  </Box>}
                  <Box display="flex" alignContent="stretch" justifyContent="center" >
                    {false && approvedToEnter && <CoreButton onClick={handleSubmit} color="primary">
                      {selectedSurvey && selectedSurvey.no}
                    </CoreButton> }
                    {false && approvedToEnter && <CoreButton onClick={handleSubmit} color="primary" autoFocus>
                      {selectedSurvey && selectedSurvey.yes}
                    </CoreButton>}
                    {!surveySubmitted && <CoreButton onClick={handleSubmit} color="primary" autoFocus className={classes.surveyButtons}>
                      Submit
                    </CoreButton>}
                    {surveySubmitted && <CoreButton onClick={handleClose} color="primary" autoFocus className={classes.surveyButtons}>
                      Close
                    </CoreButton>}
                  </Box>
              </DialogActions>
            </Dialog>
          </div>
        );
      }
      return (
        <>
          <SurveyDialog />
          <Dialog fullWidth={true} maxWidth={!notApprovedToEnter ? "md" : "sm"}  disableBackdropClick disableEscapeKeyDown open={showExistingPatientCheckInDialog} >
              { !notApprovedToEnter && <DialogTitle>Existing Patient Check-in</DialogTitle> }
              { isLoading ?
              <DialogContent>
                <Loading />
              </DialogContent> :
              <DialogContent>{ notApprovedToEnter && <><Box mt={2} justifyContent="center" className={classes.formContainer}>
                Survey results indicate that the patient is <span className={classes.notClearToEnter}>NOT CLEAR</span> to enter<br/><br/>
              </Box></>}
              { !notApprovedToEnter && <>
                <Autocomplete
                  value={selfUR.state.locationCode}
                  id="existingLocationCode"
                  onChange={handleLocationCodeChange}
                  options={selfUR.state.locationList.map((option) => option.code)}
                  renderInput={(params) => (
                    <TextFieldCore onChange={handleLocationTextChange} {...params} label="Location" margin="normal" variant="outlined" helperText={locationCodeError} />
                  )}
                />
                <Autocomplete
                  id="existingTestType"
                  value={selfUR.state.testType}
                  freeSolo
                  onChange={handleTestTypeChange}
                  disabled={disableTestType}
                  options={selfUR.state.testTypeList.map((option) => option.name)}
                  renderInput={(params) => (
                    <TextFieldCore onChange={handleLocationTextChange} {...params} label="Test Type" margin="normal" variant="outlined" helperText={testTypeError} />
                  )}
                />
                <TextInput source="firstName" label="First Name" validate={required()} fullWidth helperText={firstNameError} />
                <TextInput source="lastName" label="Last Name" validate={required()} fullWidth helperText={lastNameError} />
                <TextInput source="email" label="Email" validate={required()} fullWidth helperText={emailError}  />
                <TextInput source="phone" label="Phone Number" validate={required()} helperText={phoneError} fullWidth />
                <TextInput source="dateOfBirth" label="Date Of Birth" helperText={dobError} fullWidth />
                <SelectInput source="gender" id="gender" validate={required()} label="Gender" helperText={genderError} style={{ marginBottom: '0', minWidth: '25px !important', width: '50px !important', float: 'left' }} choices={genderOptions}/>
                <SelectInput source="ethnicity" id="ethnicity" validate={required()} label="Ethnicity" helperText={ethnicityError} style={{ marginBottom: '0', minWidth: '25px !important', width: '50px !important', float: 'left' }} choices={ethnicityOptions}/>
                <TextInput source="address1" label="Address 1" validate={required()} helperText={address1Error} fullWidth />
                <TextInput source="address2" label="Address 2" fullWidth />
                <TextInput source="city" label="City" validate={required()} helperText={cityError} fullWidth />
                <SelectInput id="state" validate={required()} label="State" helperText={stateError} style={{ marginBottom: '0', minWidth: '25px !important', width: '50px !important', float: 'left' }} source="state" choices={stateOptions}/>
                <TextInput source="zipcode" label="Zipcode" validate={required()} helperText={zipcodeError} fullWidth />
                <BooleanInput source="isPregnant" label="Pregnant" style={{width: "100%"}} />
                <span className={classes.dateOfBirth}>
                  Notes
                </span>
                <TextFieldCore onChange={updateNotes} defaultValue={notes.input.value} source="notes" label="" fullWidth />
              </>}</DialogContent> }
          <DialogActions>
            { !isLoading && !notApprovedToEnter && <Button
              label="Check In"
              onClick={() => selfUR.saveCheckInExistingPatient()}
            >
            <IconUpload />
            </Button> }
            { !isLoading && !notApprovedToEnter && <Button
            label="Cancel"
            onClick={() => selfUR.closeExistingPatientCheckInDialog()}
            >
            <IconCancel />
            </Button> }
            { !isLoading && notApprovedToEnter && <Button
            label="Close"
            onClick={() => selfUR.closeExistingPatientCheckInDialog()}
            >
            <IconCancel />
            </Button> }
          </DialogActions>
        </Dialog>
          <ReferenceInput label="Enter patient name" source="existingPatient" reference="patient/existing" onChange={existingPatientSelected}><AutocompleteInput fullWidth inputText={patientOptionInputText}  matchSuggestion={patientSuggestion} optionText={<PatientOptionRenderer />} shouldRenderSuggestions={(val) => { return val.trim().length > 2 }} /></ReferenceInput>
        </>
      )
    }
    return <Fragment>
      <SimpleForm style={{float: 'left', width: '40%', position: 'absolute', zIndex: '1'}} toolbar={null} id="ExistingPatientForm">
        <ExistingPatientCheckIn />
      </SimpleForm>
    </Fragment>
  }
}

class PatientCheckOutSection extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isLoading : false,
        showPatientCheckOutDialog : false,
        patientToCheckOut : {
          patient: {}
        }
      }
  }
  render() {
    const {
      isLoading,
      showPatientCheckOutDialog,
    } = this.state;
    const selfUR = this;
    this.showPatientCheckOutDialog = (record) => {
      selfUR.setState({
        isLoading : false,
        showPatientCheckOutDialog : true
      });
      selfUR.initialize(record);
    }
    const CheckOutPatientButton = ({ record }) => {
      return <Fragment>
        <Button label='Check-out' onClick={() =>this.showPatientCheckOutDialog(record)}><IconRemove/></Button>
      </Fragment>
    }
    function PatientCheckOutDialog() {
      const refresh = useRefresh();
      const dataProvider = useDataProvider();
      selfUR.initialize = (record) => {
        selfUR.setState({
          patientToCheckOut: record
        });
      }
      selfUR.closePatientCheckOutDialog = () => {
        selfUR.setState({
          showPatientCheckOutDialog: false,
          isLoading : false,
        }, () => refresh());
      }
      selfUR.checkOutPatient = () => {
        selfUR.setState({
          isLoading : true
        });
        toast.info("Checking-out patient, please wait...", {
          position: toast.POSITION.BOTTOM_CENTER,
          closeButton: false,
          hideProgressBar: true
        });
        dataProvider.deleteMany('patient', {
          ids: [selfUR.state.patientToCheckOut.id]
        }).then((checkout) => {
          toast.dismiss()
          toast.success("Patient Checked-out", {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: true,
            hideProgressBar: true,
            autoClose: 1000
          });
          selfUR.setState({
            showPatientCheckOutDialog: false,
            isLoading : false,
          }, () => refresh());
        }).catch(({ status, statusText, headers, body }) => {
          console.log("error checking out patient ", body);
          toast.dismiss()
          toast.error(body && body.message ? body.message : "Error", {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
            closeButton: true
          });
          selfUR.setState({
              isLoading : false
          });
        });
      }
      return (
        <>
          <Dialog fullWidth={true} maxWidth={"sm"}  disableBackdropClick disableEscapeKeyDown open={showPatientCheckOutDialog} >
              <DialogTitle>Confirm Check-out of Patient</DialogTitle>
              { isLoading ?
              <DialogContent>
                <Loading />
              </DialogContent> :
              <DialogContent>
                <TextFieldCore disabled defaultValue={selfUR.state.patientToCheckOut.locationCode} label="Location" source="locationCode" />
                <TextFieldCore disabled defaultValue={selfUR.state.patientToCheckOut.testType} label="Type" source="testType" />
                <TextFieldCore disabled defaultValue={selfUR.state.patientToCheckOut.patient.firstName} label="First Name" source="firstName"  />
                <TextFieldCore disabled defaultValue={selfUR.state.patientToCheckOut.patient.lastName} label="Last Name" source="lastName" />
                <TextFieldCore disabled defaultValue={selfUR.state.patientToCheckOut.patient.phone} label="Phone Number" source="phone" />
                <TextFieldCore disabled defaultValue={selfUR.state.patientToCheckOut.patient.email} label="Email" source="email" />
                <TextFieldCore disabled defaultValue={selfUR.state.patientToCheckOut.patient.dob} label="Date of Birth" source="dateOfBirth" />
              </DialogContent> }
          <DialogActions>
            { !isLoading && <Button
              label="Yes, Check Out"
              onClick={() => selfUR.checkOutPatient()}
            >
            <IconRemove />
            </Button>}
            { !isLoading && <Button
            label="Cancel"
            onClick={() => selfUR.closePatientCheckOutDialog()}
            >
            <IconCancel />
            </Button> }
          </DialogActions>
        </Dialog>
        </>
      )
    }
    return <Fragment>
      <SimpleForm toolbar={null} className="CheckOutPatientForm">
        <PatientCheckOutDialog />
      </SimpleForm>
      <CheckOutPatientButton {...selfUR.props} />
    </Fragment>
  }
}

const PatientDateOfBirthFormat = ({ record = {} }) => <span>{record.patient && record.patient.dob ? Moment(record.patient.dob).format('DD MMM YYYY') : ""}</span>;
const ResultsButton = ({ record = {} }) => <Fragment>
  {record.result === "DETECTED" && <Tooltip title="DETECTED" aria-label="DETECTED"><DetectedIcon className="DetectedColor" /></Tooltip>}
  {record.result === "NOT DETECTED" && <Tooltip title="NOT DETECTED" aria-label="NOT DETECTED"><NotDetectedIcon className="NotDetectedColor" /></Tooltip>}
  {record.result === "INCONCLUSIVE" && <Tooltip title="INCONCLUSIVE" aria-label="INCONCLUSIVE"><InconclusiveIcon className="InconclusiveColor" /></Tooltip>}
  {record.result === "VACCINATED" && <Tooltip title="VACCINATED" aria-label="VACCINATED"><VaccinatedIcon className="VaccinatedColor" /></Tooltip>}
  {record.result === "VACCINATED WITH ID" && <Tooltip title="VACCINATED WITH ID" aria-label="VACCINATED WITH ID"><VaccinatedWithIdIcon title="VACCINATED WITH ID" className="VaccinatedColor" /></Tooltip>}
  {record.result === "VERIFIED NEGATIVE" && <Tooltip title="VERIFIED NEGATIVE" aria-label="VERIFIED NEGATIVE"><VerifiedNegativeIcon className="VerifiedNegativeColor" /></Tooltip>}
</Fragment>;
export const PatientCheckInList = props => (
  <Fragment>
    <PatientCheckInSection />
    <section>
      <List  sort={{ field: 'id', order: 'DESC' }} perPage={50} title="Home" {...props} actions={<PatientCheckInActions />} pagination={< PatientCheckInPagination />} bulkActionButtons={false} >
          <Datagrid expand={<TextField label="Notes" source="notes" />}>
            <ResultsButton label="Results" source="result" />
            <TextField label="Location" source="locationCode" />
            <TextField label="First Name" source="patient.firstName"  />
            <TextField label="Last Name" source="patient.lastName" />
            <TextField label="Phone Number" source="patient.phone" />
            <TextField label="Email" source="patient.email" />
            <PatientDateOfBirthFormat source="patient.dob" label="Date of Birth" />
            <PatientCheckOutSection label='Check-out Patient'  />
          </Datagrid>
      </List>
    </section>
  </Fragment>
);

var genderOptions = [
  { id: 'Male', value: 'Male', name: 'Male' },
  { id: 'Female', value: 'Female', name: 'Female' },
  { id: 'Other', value: 'Other', name: 'Other' },
];
var ethnicityOptions = [
  { id: 'American Indian or Alaska Native', value: 'American Indian or Alaska Native', name: 'American Indian or Alaska Native' },
  { id: 'Asian', value: 'Asian', name: 'Asian' },
  { id: 'Black or African American', value: 'Black or African American', name: 'Black or African American' },
  { id: 'Hispanic or Latino', value: 'Hispanic or Latino', name: 'Hispanic or Latino' },
  { id: 'Native Hawaiian or Other Pacific Islander', value: 'Native Hawaiian or Other Pacific Islander', name: 'Native Hawaiian or Other Pacific Islander' },
  { id: 'Not Hispanic or Latino', value: 'Not Hispanic or Latino', name: 'Not Hispanic or Latino' },
  { id: 'White', value: 'White', name: 'White' },
  { id: 'Other', value: 'Other', name: 'Other' },
];

var stateOptions = [
  { id : 'AK', value: "AK", name: "Alaska" },
  { id : 'AL', value: "AL", name: "Alabama" },
  { id : 'AR', value: "AR", name: "Arkansas" },
  { id : 'AS', value: "AS", name: "American Samoa" },
  { id : 'AZ', value: "AZ", name: "Arizona" },
  { id : 'CA', value: "CA", name: "California" },
  { id : 'CO', value: "CO", name: "Colorado" },
  { id : 'CT', value: "CT", name: "Connecticut" },
  { id : 'DC', value: "DC", name: "District of Columbia" },
  { id : 'DE', value: "DE", name: "Delaware" },
  { id : 'FL', value: "FL", name: "Florida" },
  { id : 'GA', value: "GA", name: "Georgia" },
  { id : 'GU', value: "GU", name: "Guam" },
  { id : 'HI', value: "HI", name: "Hawaii" },
  { id : 'IA', value: "IA", name: "Iowa" },
  { id : 'ID', value: "ID", name: "Idaho" },
  { id : 'IL', value: "IL", name: "Illinois" },
  { id : 'IN', value: "IN", name: "Indiana" },
  { id : 'KS', value: "KS", name: "Kansas" },
  { id : 'KY', value: "KY", name: "Kentucky" },
  { id : 'LA', value: "LA", name: "Louisiana" },
  { id : 'MA', value: "MA", name: "Massachusetts" },
  { id : 'MD', value: "MD", name: "Maryland" },
  { id : 'ME', value: "ME", name: "Maine" },
  { id : 'MI', value: "MI", name: "Michigan" },
  { id : 'MN', value: "MN", name: "Minnesota" },
  { id : 'MO', value: "MO", name: "Missouri" },
  { id : 'MS', value: "MS", name: "Mississippi" },
  { id : 'MT', value: "MT", name: "Montana" },
  { id : 'NC', value: "NC", name: "North Carolina" },
  { id : 'ND', value: "ND", name: "North Dakota" },
  { id : 'NE', value: "NE", name: "Nebraska" },
  { id : 'NH', value: "NH", name: "New Hampshire" },
  { id : 'NJ', value: "NJ", name: "New Jersey" },
  { id : 'NM', value: "NM", name: "New Mexico" },
  { id : 'NV', value: "NV", name: "Nevada" },
  { id : 'NY', value: "NY", name: "New York" },
  { id : 'OH', value: "OH", name: "Ohio" },
  { id : 'OK', value: "OK", name: "Oklahoma" },
  { id : 'OR', value: "OR", name: "Oregon" },
  { id : 'PA', value: "PA", name: "Pennsylvania" },
  { id : 'PR', value: "PR", name: "Puerto Rico" },
  { id : 'RI', value: "RI", name: "Rhode Island" },
  { id : 'SC', value: "SC", name: "South Carolina" },
  { id : 'SD', value: "SD", name: "South Dakota" },
  { id : 'TN', value: "TN", name: "Tennessee" },
  { id : 'TX', value: "TX", name: "Texas" },
  { id : 'UT', value: "UT", name: "Utah" },
  { id : 'VA', value: "VA", name: "Virginia" },
  { id : 'VI', value: "VI", name: "Virgin Islands" },
  { id : 'VT', value: "VT", name: "Vermont" },
  { id : 'WA', value: "WA", name: "Washington" },
  { id : 'WI', value: "WI", name: "Wisconsin" },
  { id : 'WV', value: "WV", name: "West Virginia" },
  { id : 'WY', value: "WY", name: "Wyoming" }
]
var months = [
  {id : '', value: '', name: ''},
  {id : '01', value: '01', name: 'Jan'},
  {id : '02', value: '02', name: 'Feb'},
  {id : '03', value: '03', name: 'Mar'},
  {id : '04', value: '04', name: 'Apr'},
  {id : '05', value: '05', name: 'May'},
  {id : '06', value: '06', name: 'Jun'},
  {id : '07', value: '07', name: 'Jul'},
  {id : '08', value: '08', name: 'Aug'},
  {id : '09', value: '09', name: 'Sep'},
  {id : '10', value: '10', name: 'Oct'},
  {id : '11', value: '11', name: 'Nov'},
  {id : '12', value: '12', name: 'Dec'},
]

export const NewPatientCheckIn = props => (
  <NewPatientCheckInSection {...props} />
);

class NewPatientCheckInSection extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isLoading : false,
        firstNameError : null,
        lastNameError : null,
        emailError : null,
        phoneError : null,
        locationCodeError: null,
        testTypeError : null,
        showNewPatientCheckInDialog : false,
        showNewPatientSurvey : false,
        dobError : false,
        locationList: [],
        testTypeList: [],
        locationCode: null,
        testType : null,
        notes : null,
        experienceSymptomsValue: null,
        closeContactValue: null,
        isolationValue: null,
        waitingOnResultsValue: null,
        approvedToEnter : null,
        notApprovedToEnter : null,
        patientToCheckOut: {
          patient: {}
        },
        hasInitialized: false,
        selectedSurveyIndex: -1,
        surveyAnswers: [],
        surveys: [],
      }
  }

  render() {
    const {
      isLoading,
      showNewPatientCheckInDialog,
      locationCodeError,
      testTypeError,
      firstNameError,
      lastNameError,
      emailError,
      phoneError,
      dobError,
      surveys,
      notApprovedToEnter,
      address1Error,
      cityError,
      stateError,
      zipcodeError,
      ethnicityError,
      genderError
    } = this.state;
    const selfNewPatient = this;
    this.showNewPatientCheckInDialog = () => {
      selfNewPatient.initialize();
    }
    const CheckInNewPatientButton = ({ record }) => {
      return <Fragment>
        <Button label='Check-in New Patient' onClick={() =>this.showNewPatientCheckInDialog()}><IconPlus/></Button>
      </Fragment>
    }
    function NewPatientCheckInDialog() {
      //const { permissions } = usePermissions();
      const refresh = useRefresh();
      const classes = useStyles();
      const dataProvider = useDataProvider();
      const form = useForm();
      const firstName = useField("firstName", form);
      const lastName = useField("lastName", form);
      const email = useField("email", form);
      const phone = useField("phone", form);
      const dobday = useField("dobday", form);
      const dobmonth = useField("dobmonth", form);
      const dobyear = useField("dobyear", form);
      const notes = useField("notes", form);
      const gender = useField("gender", form);
      const ethnicity = useField("ethnicity", form);
      const isPregnant = useField("isPregnant", form);
      const address1 = useField("address1", form);
      const address2 = useField("address2", form);
      const city = useField("city", form);
      const state = useField("state", form);
      const zipcode = useField("zipcode", form);

      //const [cookies, setCookie] = useCookies();

      async function fetchAllTestTypes () {
        await dataProvider.getList('test/type',{
              filter: { },
              sort: { field: 'id', order: 'DESC' },
              pagination: { page: 1, perPage: 5000 },
        }).then((returnedTestTypes) => {
            selfNewPatient.setState({
                showNewPatientCheckInDialog: true,
                testTypeList : returnedTestTypes.data,
            });
        }).catch(({ status, statusText, headers, body }) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
        });
      }
      async function fetchAllLocations () {
        await dataProvider.getList('location/list',{
              filter: { },
              sort: { field: 'id', order: 'DESC' },
              pagination: { page: 1, perPage: 5000 },
        }).then((returnedLocations) => {
            selfNewPatient.setState({
                locationList : returnedLocations.data,
            }, () => fetchAllTestTypes());
        }).catch(({ status, statusText, headers, body }) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
        });
      }
      selfNewPatient.initialize = () => {
        form.change('locationCode', null);
        form.change('testType', null);
        form.change('firstName', null);
        form.change('lastName', null);
        form.change('email', null);
        form.change('phone', null);
        form.change('dobday', null);
        form.change('dobmonth', null);
        form.change('dobyear', null);
        form.change('notes', null);

        form.change('gender', null);
        form.change('ethnicity', null);
        form.change('address1', null);
        form.change('address2', null);
        form.change('city', null);
        form.change('state', null);
        form.change('zipcode', null);
        form.change('isPregnant', false);

        selfNewPatient.setState({
          isLoading : false,
          firstNameError : null,
          lastNameError : null,
          phoneError : null,
          emailError : null,
          testTypeError : null,
          locationCodeError : null,
          dobError : null,
          address1Error : null,
          cityError : null,
          stateError : null,
          zipcodeError : null,
          ethnicityError: null,
          genderError: null,
          isPregnantError: null,
          locationList : [],
          testTypeList: [],
          notes : null,
          locationCode: null,
          testType: null,
          experienceSymptomsValue: 'NO',
          closeContactValue: 'NO',
          isolationValue: 'NO',
          waitingOnResultsValue: 'NO',
          approvedToEnter : true,
          notApprovedToEnter : null
        }, () => fetchAllLocations());
      }
      selfNewPatient.closeNewPatientCheckInDialog = () => {
        selfNewPatient.setState({
          showNewPatientCheckInDialog: false,
          isLoading : false,
        }, () => refresh());
      }
      function updateNotes (event) {
        //notesUpdated = event.target.value
        form.change('notes', event.target.value);
      }
      function checkOpenSurvey() {
        if (selfNewPatient.state.surveys && selfNewPatient.state.surveys.length > 0) {
          setTimeout(function() {
            document.getElementById('OpenNewPatientSurvey').click()
            setTimeout(function() {
              document.getElementById('SetDefaultNewPatientSurveyAnswers').click()
            }, 50)
          }, 50)
        } else {
          refresh()
        }
      }
      selfNewPatient.saveNewPatientCheckIn = () => {
        selfNewPatient.setState({
          isLoading : true
        });
        toast.info("Checking-in new patient, please wait...", {
          position: toast.POSITION.BOTTOM_CENTER,
          closeButton: false,
          hideProgressBar: true
        });
        var formattedDay = dobday.input.value == null || dobday.input.value === '' ? null : parseInt(dobday.input.value) < 10 ? "0" + parseInt(dobday.input.value) : parseInt(dobday.input.value)
        var formattedMonth = dobmonth.input.value == null || dobmonth.input.value === ''  ? null : parseInt(dobmonth.input.value) < 10 ? "0" + parseInt(dobmonth.input.value) : parseInt(dobmonth.input.value)
        var formattedYear = dobyear.input.value == null || dobyear.input.value === '' ? null : parseInt(dobyear.input.value)
        var dob = formattedDay != null && formattedMonth != null &&  formattedDay != null ? formattedYear + "-" + formattedMonth + "-" + formattedDay : ""
        var locationCode =  document.getElementById('locationCode').value
        var testType = document.getElementById('testType').value
        //setCookie('testType', testType, { path: '/' });
        //setCookie('locationCode', locationCode, { path: '/' });
        const token = localStorage.getItem('token');
        var today = new Date();
        var d = today.getDate();
        var m = today.getMonth() + 1; //Month from 0 to 11
        var y = today.getFullYear()
        const requestOptions = {
           method: 'POST',
           headers: { 'Content-Type': 'application/json', Authorization: token},
           body: JSON.stringify(
             {
                 locationCode: locationCode,
                 testType: testType,
                 result: 'PROCESSING',
                 collectionDate: y + '-' + (m<=9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d),
                 notes: notes.input.value,
                 patient : {
                   firstName: firstName.input.value,
                   lastName: lastName.input.value,
                   email: email.input.value,
                   phone: phone.input.value,
                   dob: dob,
                   gender : gender.input.value,
                   ethnicity: ethnicity.input.value,
                   address1: address1.input.value,
                   address2: address2.input.value,
                   city: city.input.value,
                   state: state.input.value,
                   zipcode: zipcode.input.value,
                   isPregnant: isPregnant.input.value,
                   country : 'USA',
                   language: selfNewPatient.state.selectedSurveyIndex === 0 ? "en" : "es",
                 }
              }
           )
        };
        fetch('/api/v1/patient', requestOptions)
        .then(response => response.json())
        .then(body => {
          toast.dismiss();
          if (body && !body.id && !body.success) {
            var firstNameError = null
            var lastNameError = null
            var emailError = null
            var phoneError = null
            var testTypeError = null
            var locationCodeError = null
            var dobError = null
            var address1Error = null
            var cityError = null
            var stateError = null
            var zipcodeError = null
            var ethnicityError = null
            var genderError = null
            var isPregnantError = null
            if (body && body.details) {
              for (var i = 0; i < body.details.length; i++) {
                if (body.details[i].field === 'patient.firstName')
                  firstNameError = body.details[i].message
                if (body.details[i].field === 'patient.lastName')
                  lastNameError = body.details[i].message
                if (body.details[i].field === 'patient.phone')
                  phoneError = body.details[i].message
                if (body.details[i].field === 'patient.email')
                  emailError = body.details[i].message
                if (body.details[i].field === 'testType')
                  testTypeError = body.details[i].message
                if (body.details[i].field === 'locationCode')
                  locationCodeError = body.details[i].message
                if (body.details[i].field === 'patient.dob')
                  dobError = body.details[i].message
                if (body.details[i].field === 'patient.address1')
                  address1Error = body.details[i].message
                if (body.details[i].field === 'patient.city')
                  cityError = body.details[i].message
                if (body.details[i].field === 'patient.state')
                  stateError = body.details[i].message
                if (body.details[i].field === 'patient.zipcode')
                  zipcodeError = body.details[i].message
                if (body.details[i].field === 'patient.gender')
                  genderError = body.details[i].message
                if (body.details[i].field === 'patient.ethnicity')
                  ethnicityError = body.details[i].message
                if (body.details[i].field === 'patient.isPregnant')
                  isPregnantError = body.details[i].message
              }
            }
            selfNewPatient.setState({
                firstNameError : firstNameError,
                lastNameError : lastNameError,
                phoneError : phoneError,
                emailError : emailError,
                testTypeError : testTypeError,
                locationCodeError : locationCodeError,
                dobError : dobError,
                locationCode : locationCode,
                testType : testType,
                address1Error : address1Error,
                cityError : cityError,
                stateError : stateError,
                zipcodeError : zipcodeError,
                genderError: genderError,
                ethnicityError: ethnicityError,
                isPregnantError: isPregnantError,
                isLoading : false
            });
            toast.error(body && body.message ? body.message : "Error", {
              position: toast.POSITION.BOTTOM_CENTER,
              hideProgressBar: true,
              closeButton: true
            });
          } else {
            console.log("New patient checked - in response ", body)
            toast.dismiss()
            toast.success("New Patient Checked-in", {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: true,
              hideProgressBar: true,
              autoClose: 1000
            });
            selfNewPatient.setState({
                surveys : body.surveys,
            });
            selfNewPatient.setState({
                showNewPatientCheckInDialog: false,
                isLoading : false,
                checkedInExistingPatientId: body.id,
                patientToCheckOut: {id : body.id}
            }, checkOpenSurvey())
          }
        }).catch(({ status, statusText, headers, body }) => {
          console.log("error saving results ", body);
          toast.dismiss()
          if (body && !body.success) {
            var firstNameError = null
            var lastNameError = null
            var emailError = null
            var phoneError = null
            var testTypeError = null
            var locationCodeError = null
            var dobError = null
            var address1Error = null
            var cityError = null
            var stateError = null
            var zipcodeError = null
            var ethnicityError = null
            var genderError = null
            var isPregnantError = null
            if (body && body.details) {
              for (var i = 0; i < body.details.length; i++) {
                if (body.details[i].field === 'patient.firstName')
                  firstNameError = body.details[i].message
                if (body.details[i].field === 'patient.lastName')
                  lastNameError = body.details[i].message
                if (body.details[i].field === 'patient.phone')
                  phoneError = body.details[i].message
                if (body.details[i].field === 'patient.email')
                  emailError = body.details[i].message
                if (body.details[i].field === 'testType')
                  testTypeError = body.details[i].message
                if (body.details[i].field === 'locationCode')
                  locationCodeError = body.details[i].message
                if (body.details[i].field === 'patient.dob')
                  dobError = body.details[i].message
                if (body.details[i].field === 'patient.address1')
                  address1Error = body.details[i].message
                if (body.details[i].field === 'patient.city')
                  cityError = body.details[i].message
                if (body.details[i].field === 'patient.state')
                  stateError = body.details[i].message
                if (body.details[i].field === 'patient.zipcode')
                  zipcodeError = body.details[i].message
                if (body.details[i].field === 'patient.gender')
                  genderError = body.details[i].message
                if (body.details[i].field === 'patient.ethnicity')
                  ethnicityError = body.details[i].message
                if (body.details[i].field === 'patient.isPregnant')
                  isPregnantError = body.details[i].message
              }
            }
            selfNewPatient.setState({
                firstNameError : firstNameError,
                lastNameError : lastNameError,
                phoneError : phoneError,
                emailError : emailError,
                testTypeError : testTypeError,
                locationCodeError : locationCodeError,
                dobError : dobError,
                locationCode : locationCode,
                testType : testType,
                address1Error : address1Error,
                cityError : cityError,
                stateError : stateError,
                zipcodeError : zipcodeError,
                genderError: genderError,
                ethnicityError: ethnicityError,
                isPregnantError: isPregnantError
            });
          }
          toast.error(body && body.message ? body.message : "Error", {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
            closeButton: true
          });
          selfNewPatient.setState({
              isLoading : false
          });
        })
      }
      selfNewPatient.isApprovedToEnter = () => {
        if (selfNewPatient.state.experienceSymptomsValue === 'NO' &&
            selfNewPatient.state.closeContactValue === 'NO' &&
            selfNewPatient.state.isolationValue === 'NO' &&
            selfNewPatient.state.waitingOnResultsValue === 'NO') {
          selfNewPatient.setState({
            approvedToEnter : true
          });
        } else {
          selfNewPatient.setState({
            approvedToEnter : null
          });
        }
        if (selfNewPatient.state.experienceSymptomsValue === null || selfNewPatient.state.closeContactValue === null || selfNewPatient.state.isolationValue === null || selfNewPatient.state.waitingOnResultsValue === null) {
          selfNewPatient.setState({
            notApprovedToEnter : null
          });
        } else {
          if (selfNewPatient.state.experienceSymptomsValue === 'YES' || selfNewPatient.state.closeContactValue === 'YES' || selfNewPatient.state.isolationValue === 'YES' || selfNewPatient.state.waitingOnResultsValue === 'YES') {
            selfNewPatient.setState({
              notApprovedToEnter : true
            });
          } else {
            selfNewPatient.setState({
              notApprovedToEnter : null
            });
          }
        }
      }
      selfNewPatient.closeNewPatientSurvey = () => {
        selfNewPatient.setState({
          showNewPatientSurvey: false,
        }, () => refresh());
      }
      selfNewPatient.checkOutPatientSurvey = () => {
        selfNewPatient.setState({
          showNewPatientSurvey: false,
        }, () => selfNewPatient.checkOutPatient());
      }
      selfNewPatient.checkOutPatient = () => {
        selfNewPatient.setState({
          isLoading : true
        });
        toast.info("Checking-out patient, please wait...", {
          position: toast.POSITION.BOTTOM_CENTER,
          closeButton: false,
          hideProgressBar: true
        });
        dataProvider.deleteMany('patient', {
          ids: [selfNewPatient.state.patientToCheckOut.id]
        }).then((checkout) => {
          toast.dismiss()
          toast.success("Patient Checked-out", {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: true,
            hideProgressBar: true,
            autoClose: 1000
          });
          selfNewPatient.setState({
            showPatientCheckOutDialog: false,
            isLoading : false,
          }, () => refresh());
        }).catch(({ status, statusText, headers, body }) => {
          console.log("error checking out patient ", body);
          toast.dismiss()
          toast.error(body && body.message ? body.message : "Error", {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
            closeButton: true
          });
          selfNewPatient.setState({
              isLoading : false
          });
        });
      }
      const handleLocationCodeChange = (event) => {
        if (event.target.innerHTML && event.target.innerHTML.indexOf("<path") >= 0)
          selfNewPatient.setState({
              locationCode : undefined
          });
        else
          selfNewPatient.setState({
              locationCode : event.target.innerHTML
          });
      }
      const handleTestTypeChange = (event) => {
        if (event.target.innerHTML && event.target.innerHTML.indexOf("<path") >= 0)
          selfNewPatient.setState({
              testType : undefined
          });
        else
          selfNewPatient.setState({
              testType : event.target.innerHTML
          });
      }
      function NewPatientSurveyDialog() {
        const classes = useStyles();
        const [openNewPatientSurvey, setOpenNewPatientSurvey] = React.useState(false);
        const [approvedToEnterNewPatientSurvey, setApprovedToEnterNewPatientSurvey] = React.useState(true);
        const [notApprovedToEnterNewPatientSurvey, setNotApprovedToEnterNewPatientSurvey] = React.useState(false);
        const [selectedNewPatientSurvey, setSelectedNewPatientSurvey] = React.useState({ questions: []});
        const [selectedNewPatientSurveyIndex, setSelectedNewPatientSurveyIndex] = React.useState(0);
        const [newPatientSurveyAnswers, setNewPatientSurveyAnswers] = React.useState([]);
        const [newPatientSurveySubmitted, setNewPatientSurveySubmitted] = React.useState(false);

        const setDefaultAnswers = () => {
          var index = 0;
          if (selectedNewPatientSurvey && selectedNewPatientSurvey.questions) {
            selectedNewPatientSurvey.questions.forEach(function(question) {
              answerNewPatientSurveyQuestion(index++, 'NO')
            });
          }
        };
        const handleClickOpen = () => {
          setOpenNewPatientSurvey(true);
        };
        const handleClose = () => {
          setOpenNewPatientSurvey(false);
        }
        const handleNewPatientSurveySubmit = () => {
          selfNewPatient.setState({
            showNewPatientCheckInDialog: true,
            isLoading: true
          });
          if (selectedNewPatientSurvey.questions && selectedNewPatientSurvey.questions.length > 0 && newPatientSurveyAnswers) {
            for (var i = 0; i < selectedNewPatientSurvey.questions.length; i++) {
              selectedNewPatientSurvey.questions[i].answer = newPatientSurveyAnswers[i] === 'YES'
            }
          }
          const token = localStorage.getItem('token');
          const requestOptions = {
             method: 'PUT',
             headers: { 'Content-Type': 'application/json', Authorization: token},
             body: JSON.stringify(
                selectedNewPatientSurvey
             )
          };
          fetch('/api/v1/patient/survey/' + selfNewPatient.state.checkedInExistingPatientId, requestOptions)
          .then(response => response.json())
          .then(responseData => {
            console.log("Done submitting survey ", approvedToEnterNewPatientSurvey);
            if (approvedToEnterNewPatientSurvey) {
              setOpenNewPatientSurvey(false);
              selfNewPatient.setState({
                showNewPatientCheckInDialog: false,
                isLoading: false,
                approvedToEnter : approvedToEnterNewPatientSurvey,
                notApprovedToEnter: notApprovedToEnterNewPatientSurvey
              });
              refresh()
            } else {
              selfNewPatient.setState({
                isLoading: false,
                approvedToEnter : approvedToEnterNewPatientSurvey,
                notApprovedToEnter: notApprovedToEnterNewPatientSurvey
              });
              setOpenNewPatientSurvey(true);
              setNewPatientSurveySubmitted(true);
            }
          }).catch(({ status, statusText, headers, body }) => {
            console.log("error saving results ", body);
            toast.dismiss()
            toast.error(body && body.message ? body.message : "Error", {
              position: toast.POSITION.BOTTOM_CENTER,
              hideProgressBar: true,
              closeButton: true
            });
            selfNewPatient.setState({
                isLoading : false
            });
          })
        };

        const handleNewPatientSurveyChange = (index) => {
          setSelectedNewPatientSurveyIndex(index)
          setOpenNewPatientSurvey(true);
          setSelectedNewPatientSurvey(surveys[index]);
        };

        const answerNewPatientSurveyQuestion = (index, value) => {
          //var anotherIndex = 0;
          //selectedSurvey.questions.forEach(function(question) {
          //console.log("Setting question answer for ", index);
          setNewPatientSurveyQuestionAnswer(index, value)
          setTimeout(function() {
            setSelectedNewPatientSurvey(selectedNewPatientSurveyIndex);
          },300)
        }

        const setNewPatientSurveyQuestionAnswer = useCallback((index, value) => {
          let answer = newPatientSurveyAnswers[index];
          answer = value
          newPatientSurveyAnswers[index] = answer
          setNewPatientSurveyAnswers(newPatientSurveyAnswers)
          selfNewPatient.state.surveyAnswers = newPatientSurveyAnswers
          var answerCheckIndex = 0
          setNotApprovedToEnterNewPatientSurvey(false)
          setApprovedToEnterNewPatientSurvey(true)
          if (selectedNewPatientSurvey && selectedNewPatientSurvey.questions) {
            selectedNewPatientSurvey.questions.forEach(function(question) {
              if(selectedNewPatientSurvey.questions[answerCheckIndex].neededForPass === true && newPatientSurveyAnswers[answerCheckIndex] === 'YES') {
                setApprovedToEnterNewPatientSurvey(false)
                setNotApprovedToEnterNewPatientSurvey(true)
              }
              answerCheckIndex++
            });
          }
        }, [newPatientSurveyAnswers, selectedNewPatientSurvey])

        useEffect(() => {
          if (surveys && surveys.length > 0)
            setSelectedNewPatientSurvey(surveys[selectedNewPatientSurveyIndex]);
        }, [selectedNewPatientSurvey, setNewPatientSurveyQuestionAnswer, newPatientSurveyAnswers, selectedNewPatientSurveyIndex]);

        return (
          <div>
            <CoreButton style={{ display: 'none' }} id="OpenNewPatientSurvey" variant="outlined" color="primary" onClick={handleClickOpen}>
              Open New Patient Survey
            </CoreButton>
            <CoreButton style={{ display: 'none' }} id="SetDefaultNewPatientSurveyAnswers" variant="outlined" color="primary" onClick={setDefaultAnswers}>
              Set Default Answers
            </CoreButton>
            <Dialog
              disableBackdropClick disableEscapeKeyDown
              fullWidth={true} maxWidth={"md"}
              open={openNewPatientSurvey}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{false && selectedNewPatientSurvey && selectedNewPatientSurvey.name}
                {selectedNewPatientSurveyIndex === 1 && surveys && surveys.length > 1 && <CoreButton style={{float: 'right'}} onClick={() => handleNewPatientSurveyChange(0)} color="primary" className={classes.surveyButtons}>
                  English
                </CoreButton> }
                {selectedNewPatientSurveyIndex === 0 && surveys && surveys.length > 1 &&<CoreButton style={{float: 'right'}} onClick={() => handleNewPatientSurveyChange(1)} color="primary" className={classes.surveyButtons}>
                  Español
                </CoreButton>}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {selectedNewPatientSurvey && selectedNewPatientSurvey.header}
                </DialogContentText>
                <DialogContentText id="alert-dialog-description">
                  {selectedNewPatientSurvey && selectedNewPatientSurvey.prefix}
                </DialogContentText>
                <Fragment>
                  {!newPatientSurveySubmitted && selectedNewPatientSurvey && selectedNewPatientSurvey.questions && selectedNewPatientSurvey.questions.map(function(name, index) {
                   return <Box key={index} display="flex" justifyContent="flex-start" className={classes.cdcScreeningSurvey}>
                    <Box display="flex-row" justifyContent="flex-start" className={classes.cdcScreeningSurveyLeftCol}>
                      {selectedNewPatientSurvey && selectedNewPatientSurvey.questions[index].prompt}
                    </Box>
                    <Box display="flex-row" alignContent="center" justifyContent="center" className={classes.cdcScreeningSurveyRightCol}>
                      <Box display="flex" alignContent="stretch" justifyContent="center" className={classes.yesNoButtonsContainer}>
                        <Box flexGrow={1} className={classes.yesNoButtonsElement}>
                          <IconButton
                            onClick={() => answerNewPatientSurveyQuestion(index, 'YES')}
                            className={newPatientSurveyAnswers[index] === 'YES' ? classes.yesSelected : classes.yes}
                          >
                            {selectedNewPatientSurvey && selectedNewPatientSurvey.yes}
                          </IconButton>
                        </Box>
                        <Box flexGrow={1} className={classes.yesNoButtonsElement}>
                          <IconButton
                            onClick={() => answerNewPatientSurveyQuestion(index, 'NO')}
                            className={newPatientSurveyAnswers[index] === 'NO' ? classes.noSelected : classes.no}
                          >
                            {selectedNewPatientSurvey && selectedNewPatientSurvey.no}
                          </IconButton>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  })}
                  {false && approvedToEnterNewPatientSurvey && <Box display="flex" justifyContent="flex-start" className={classes.cdcScreeningSurvey}>
                    <Box display="flex-row" justifyContent="flex-start" className={classes.approved}>
                      APPROVED
                    </Box>
                  </Box>}
                  {false && notApprovedToEnterNewPatientSurvey && <Box display="flex" justifyContent="flex-start" className={classes.cdcScreeningSurvey}>
                    <Box display="flex-row" justifyContent="flex-start" className={classes.notApproved}>
                      NOT APPROVED
                    </Box>
                  </Box> }
               </Fragment>
              </DialogContent>
              <DialogActions style={{marginLeft: 'auto', marginRight: 'auto', display: 'block'}}>
                  {false && approvedToEnterNewPatientSurvey && selectedNewPatientSurvey && <Box display="flex" alignContent="stretch" justifyContent="center">
                    {false && selectedNewPatientSurvey.footer}
                  </Box>}
                  <Box display="flex" alignContent="stretch" justifyContent="center" >
                    {false && approvedToEnterNewPatientSurvey && <CoreButton onClick={handleNewPatientSurveySubmit} color="primary">
                      {selectedNewPatientSurvey && selectedNewPatientSurvey.no}
                    </CoreButton> }
                    {false && approvedToEnterNewPatientSurvey && <CoreButton onClick={handleNewPatientSurveySubmit} color="primary" autoFocus>
                      {selectedNewPatientSurvey && selectedNewPatientSurvey.yes}
                    </CoreButton>}
                    {!newPatientSurveySubmitted && <CoreButton onClick={handleNewPatientSurveySubmit} color="primary" autoFocus className={classes.surveyButtons}>
                      Submit
                    </CoreButton>}
                    {newPatientSurveySubmitted && <CoreButton onClick={handleClose} color="primary" autoFocus className={classes.surveyButtons}>
                      Submit
                    </CoreButton>}
                  </Box>
              </DialogActions>
            </Dialog>
          </div>
        );
      }
      return (
        <>
          <NewPatientSurveyDialog />
          <Dialog fullWidth={true} maxWidth={!notApprovedToEnter ? "md" : "sm"}  disableBackdropClick disableEscapeKeyDown open={showNewPatientCheckInDialog} >
              { !notApprovedToEnter && <DialogTitle>New Patient Check-in</DialogTitle> }
              { isLoading ?
              <DialogContent>
                <Loading />
              </DialogContent> :
              <DialogContent>{ notApprovedToEnter && <><Box mt={2} justifyContent="center" className={classes.formContainer}>
                Survey results indicate that the patient is <span className={classes.notClearToEnter}>NOT CLEAR</span> to enter<br/><br/>
              </Box></>}
              { !notApprovedToEnter && <>
                <Autocomplete
                  id="locationCode"
                  value={selfNewPatient.state.locationCode}
                  onChange={handleLocationCodeChange}
                  options={selfNewPatient.state.locationList.map((option) => option.code)}
                  renderInput={(params) => (
                    <TextFieldCore {...params} label="Location" margin="normal" variant="outlined" helperText={locationCodeError} />
                  )}
                />
                <Autocomplete
                  id="testType"
                  value={selfNewPatient.state.testType}
                  freeSolo
                  onChange={handleTestTypeChange}
                  options={selfNewPatient.state.testTypeList.map((option) => option.name)}
                  renderInput={(params) => (
                    <TextFieldCore {...params} label="Test Type" margin="normal" variant="outlined" helperText={testTypeError} />
                  )}
                />
                <TextInput source="firstName" label="First Name" validate={required()} fullWidth helperText={firstNameError} />
                <TextInput source="lastName" label="Last Name" validate={required()} fullWidth helperText={lastNameError} />
                <TextInput source="email" label="Email" validate={required()} fullWidth helperText={emailError}  />
                <TextInput source="phone" label="Phone Number" validate={required()} helperText={phoneError} fullWidth />
                <Fragment>
                  <Box display="flex-row" justifyContent="flex-start" className={classes.dateOfBirth}>
                    Date of Birth
                  </Box>
                  <Box id="DOBInputs" display="flex" style={{ width: '100%' }}>
                    <Box display="flex-row" justifyContent="flex-start" className={classes.dateOfBirthInput}>
                      <TextInput style={{ marginBottom: '0' }} source="dobday" validate={required()} label="Day"/>
                    </Box>
                    <Box display="flex-row" justifyContent="flex-start" className={classes.dateOfBirthSelectInput}>
                      <SelectInput id="DobMonth" validate={required()} label="Month" style={{ marginBottom: '0', minWidth: '25px !important', width: '50px !important', float: 'left' }} source="dobmonth" choices={months}/>
                    </Box>
                    <Box display="flex-row" justifyContent="flex-start" className={classes.dateOfBirthInput}>
                      <TextInput source="dobyear" validate={required()} label="Year"/>
                    </Box>
                 </Box>
                 <Box display="flex-row" justifyContent="flex-start">
                   <span style={{ color: 'red', paddingLeft: '12px', fontSize: '13px' }}>{dobError}</span>
                 </Box>
               </Fragment>
               <SelectInput source="gender" id="gender" validate={required()} label="Gender" helperText={genderError} style={{ marginBottom: '0', minWidth: '25px !important', width: '50px !important', float: 'left' }} choices={genderOptions}/>
               <SelectInput source="ethnicity" id="ethnicity" validate={required()} label="Ethnicity" helperText={ethnicityError} style={{ marginBottom: '0', minWidth: '25px !important', width: '50px !important', float: 'left' }} choices={ethnicityOptions}/>
               <TextInput source="address1" label="Address 1" validate={required()} helperText={address1Error} fullWidth />
               <TextInput source="address2" label="Address 2" fullWidth />
               <TextInput source="city" label="City" validate={required()} helperText={cityError} fullWidth />
               <SelectInput id="state" validate={required()} label="State" helperText={stateError} style={{ marginBottom: '0', minWidth: '25px !important', width: '50px !important', float: 'left' }} source="state" choices={stateOptions}/>
               <TextInput source="zipcode" label="Zipcode" validate={required()} helperText={zipcodeError} fullWidth />
               <BooleanInput source="isPregnant" label="Pregnant" style={{width: "100%"}} />
               <span className={classes.dateOfBirth}>
                 Notes
               </span>
               <TextFieldCore onChange={updateNotes} defaultValue={notes.input.value} source="notes" label="" fullWidth />
              </>}
            </DialogContent> }
          <DialogActions>
            {!isLoading && !notApprovedToEnter && <Button
              label="Check In"
              onClick={() => selfNewPatient.saveNewPatientCheckIn()}
            >
            <IconUpload />
            </Button>}
            {!isLoading && !notApprovedToEnter && <Button
            label="Cancel"
            onClick={() => selfNewPatient.closeNewPatientCheckInDialog()}
            >
            <IconCancel />
            </Button>}
            {!isLoading && notApprovedToEnter && <Button
            label="Close"
            onClick={() => selfNewPatient.closeNewPatientCheckInDialog()}
            >
            <IconCancel />
            </Button>}
          </DialogActions>
        </Dialog>
        <CheckInNewPatientButton />
        <RefreshButton /></>
      )
    }
    return <Fragment>
      <SimpleForm toolbar={null} id="NewPatientCheckInDialogForm">
        <NewPatientCheckInDialog />
      </SimpleForm>
    </Fragment>
  }
}

//<TextField source="testType" label="Test Type" />
//<CollectionDateFormat source="collectionDate" label="Collection Date"  />
//<CreatedDateFormat source="createdDatetime" label="Created" />
//<ModifiedDateFormat  source="modifiedDatetime" label="Modified" />
