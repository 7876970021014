import React  from 'react';
import { List, Datagrid, TextField, Edit, Create, SimpleForm,
  TextInput, BooleanInput, FileInput, FileField, ArrayInput, SelectInput,
  SimpleFormIterator, Link, Pagination, TopToolbar, RefreshButton, CreateButton,
  FormDataConsumer, ReferenceInput, maxLength, required,
  Button, LinearProgress } from 'react-admin';
import Box from '@material-ui/core/Box';
import IconCancel from '@material-ui/icons/Cancel';
import IconSave from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from "@material-ui/core/Button";
import { toast } from 'react-toastify';
import { useForm, useField } from 'react-final-form';
import * as XLSX from 'xlsx';
import Moment from 'moment';

const ListActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <CreateButton basePath={basePath} />
        <RefreshButton />
    </TopToolbar>
);

const CreatedDateFormat = ({ record = {} }) => <span>{record.createdDatetime ? Moment(record.createdDatetime).format('DD MMM YYYY') : ""}</span>;
const ModifiedDateFormat = ({ record = {} }) => <span>{record.modifiedDatetime ? Moment(record.modifiedDatetime).format('DD MMM YYYY hh:mm:ss A') : ""}</span>;

const FileFormatPagination = props => <Pagination rowsPerPageOptions={[25, 50, 100]} {...props} />
export const MappingList = props => (
    <List actions={<ListActions />} pagination={< FileFormatPagination />} perPage={50} title="File Formats" {...props} bulkActionButtons={false} >
        <Datagrid rowClick="edit">
            <TextField source="id" />
            <TextField source="name" />
            <CreatedDateFormat source="createdDatetime" label="Created" />
            <ModifiedDateFormat  source="modifiedDatetime" label="Modified" />
        </Datagrid>
    </List>
);

const EditMappingBreadCrumb = ({ record }) => {
  return <><span className="breadcrumbs"><Link to={"/mapping"}>File Formats</Link> &nbsp;> {record && record.id ? `Edit File Format: ${record.name} (${record.id})` : 'Create File Format'}</span></>;
};
const EditMappingTitle = ({ record }) => {
  return <span>Edit File Format {record ? `${record.name} (${record.id})` : ''}</span>;
};
const CreateMappingTitle = ({ record }) => {
  return <span>Creat File Format</span>;
};

class MappingForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showUploadDialog: false,
      uploading : false
    }
  }
  render() {
    const {
      showUploadDialog
    } = this.state
    const self = this;
    function UploadDialog() {
      const form = useForm();
      //const dataProvider = useDataProvider();
      const file = useField("file", form);
      self.closeUploadDialog = () => {
        self.setState({showUploadDialog: false });
      }
      self.showUploadDialog = () => {
        self.setState({showUploadDialog: true });
      }
      self.processFileData = dataString => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
          const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
          if (headers && row.length === headers.length) {
            const obj = {};
            for (let j = 0; j < headers.length; j++) {
              let d = row[j];
              if (d.length > 0) {
                if (d[0] === '"')
                  d = d.substring(1, d.length - 1);
                if (d[d.length - 1] === '"')
                  d = d.substring(d.length - 2, 1);
              }
              if (headers[j]) {
                obj[headers[j]] = d;
              }
            }
            // remove the blank rows
            if (Object.values(obj).filter(x => x).length > 0) {
              list.push(obj);
            }
          }
        }
        // prepare columns list from headers
        const columns = headers.map(c => ({
          columnName: c,
        }));
        console.log("columns ", columns)
        self.setState({
            uploading: false,
            showUploadDialog: false
        })
        form.change('columns', columns);
      }
      self.uploadMapping = () => {
        if (!file.input.value) {
          var error = ""
          if (!file.input.value) {
            if (error.length > 0)
              error += " | "
            error += "No file selected for upload"
          }
          toast.error(error, {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: true,
            autoClose: 3000
          });
        } else {
          self.setState({ uploading: true})
          const reader = new FileReader();
          try {
            reader.onload = (evt) => {
              /* Parse data */
              const bstr = evt.target.result;
              const wb = XLSX.read(bstr, { type: 'binary' });
              /* Get first worksheet */
              const wsname = wb.SheetNames[0];
              const ws = wb.Sheets[wsname];
              /* Convert array of arrays */
              const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
              //console.log(data);
              self.processFileData(data)
            };
            reader.readAsBinaryString(file.input.value.rawFile);
          } catch (ex) {
            toast.error(ex ? "Exception processing file " + ex : "Error", {
              position: toast.POSITION.BOTTOM_CENTER,
              hideProgressBar: true,
              closeButton: true
            });
          }
        }
      }
      return (
        <>
        <Dialog fullWidth={true} width={"md"}  disableBackdropClick disableEscapeKeyDown open={showUploadDialog} >
          <DialogTitle>Upload Sample File (csv)</DialogTitle>
            { self.state.uploading && <DialogContent> <LinearProgress style={{height: "5px", width: "100%", margin: "0" }}/> </DialogContent>}
            { !self.state.uploading && <DialogContent>
              <FileInput source="file" label="Sample" multiple={false} accept=".csv" >
                <FileField source="file" title="title" />
              </FileInput>
            </DialogContent> }
          <DialogActions>
          { !self.state.uploading && <Button
          label="Upload"
          onClick={() => self.uploadMapping()}
          >
          <IconSave />
          </Button> }
          { !self.state.uploading && <Button
          label="Close"
          onClick={() => self.closeUploadDialog()}
          >
          <IconCancel />
          </Button> }
          </DialogActions>
        </Dialog>
        <IconButton onClick={() => self.showUploadDialog()}>Upload Sample File (csv)</IconButton>
        </>
      )
    }
    const FileUpload = ({ record }) => {
      return <>{record != null && record.id == null && <UploadDialog {...this.props} />}</>
    };
    return (
      <SimpleForm initialValues = {{ "hasHeader" : "true", "isDisplayable" : "true"}} {...this.props}>
        <FileUpload {...this.props} />
        <BooleanInput source="hasHeader" style={{display: 'none'}} />
        <BooleanInput source="isDisplayable" style={{display: 'none'}} />
        <EditMappingBreadCrumb />
        <div style={{ width: '100%' }}>
          <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
            <Box display="flex" justifyContent="space-between" style={{ width: '8%' }}>
              <TextInput disabled source="id" />
            </Box>
            <Box display="flex" justifyContent="space-between" style={{ width: '90%' }}>
              <TextInput  validate={[required(), maxLength(100)]}  source="name" fullWidth />
            </Box>
          </Box>
          <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
            <Box display="flex" justifyContent="space-between" style={{ width: '48%' }}>
              <ArrayInput source="columns">
              <SimpleFormIterator >
                <FormDataConsumer>
                      {({ getSource, scopedFormData }) => {
                       return (
                          <>
                          <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                            <Box display="flex" justifyContent="space-between" style={{ width: '48%' }}>
                              <TextInput label="Source Columns" source={getSource('columnName')} />
                            </Box>
                            <Box display="flex" justifyContent="space-between" style={{ width: '48%' }}>
                              <ReferenceInput perPage={100}  label="Standardized Column" reference="mapping/standard/columns" source={getSource('standardColumnName')}>
                                <SelectInput optionText="label" optionValue="value" />
                              </ReferenceInput>
                            </Box>
                          </Box>
                          </>
                        );
                      }}
                  </FormDataConsumer>
              </SimpleFormIterator>
              </ArrayInput>
            </Box>
            <Box display="flex" justifyContent="space-between" style={{ width: '50%' }}>
              <ArrayInput source="results">
                <SimpleFormIterator>
                  <FormDataConsumer>
                    {({ getSource, scopedFormData }) => {
                     return (
                        <>
                        <Box display="flex" justifyContent="space-between" style={{ width: '100%' }}>
                          <Box display="flex" justifyContent="space-between" style={{ width: '48%' }}>
                            <TextInput label="Source Result Value" source={getSource('resultValue')} />
                          </Box>
                          <Box display="flex" justifyContent="space-between" style={{ width: '48%' }}>
                            <ReferenceInput perPage={100} label="Standardized Result Value" reference="mapping/standard/results" source={getSource('standardValue')}>
                              <SelectInput optionText="label" optionValue="value" />
                            </ReferenceInput>
                          </Box>
                        </Box>
                        </>
                      );
                    }}
                  </FormDataConsumer>
                </SimpleFormIterator>
              </ArrayInput>
            </Box>
          </Box>
        </div>
      </SimpleForm>
    )
  }
}

export const MappingEdit = props => (
    <Edit undoable={false} {...props} title={<EditMappingTitle />}>
        <MappingForm {...props} />
    </Edit>
);

export const MappingCreate = props => (
    <Create undoable={false} {...props} title={<CreateMappingTitle />}>
        <MappingForm  {...props} />
    </Create>
);

//<Box display="flex" justifyContent="space-between" style={{ width: '30%' }}>
  //<TextInput label="Source Date Format" source={getSource('dateFormat')} style={{ width: '33% !important', float: 'left' }} />
//</Box>
