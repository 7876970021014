import React, {  Fragment }  from 'react';
import {  Button, SimpleForm, useDataProvider, FileInput, AutocompleteInput,
          ReferenceInput, SelectInput, useRefresh, FileField,
          required, useQuery, Loading, ListContextProvider, Datagrid,
          TextField, Pagination} from 'react-admin';
import { useForm, useField } from 'react-final-form';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconCancel from '@material-ui/icons/Cancel';
import IconSave from '@material-ui/icons/Save';
import IconUpload from '@material-ui/icons/CloudUpload';
import { toast } from 'react-toastify';
import keyBy from 'lodash/keyBy';
import Box from '@material-ui/core/Box';
import Moment from 'moment';
//import * as Config from '../config'

export const UploadResults = props => (
  <UploadResultsSection {...props}/>
);

class UploadResultsSection extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        isLoading : false,
        showUploadResultsDialog : false,
        fileName : "",
        resultsPage : 1,
        resultsPerPage : 10,
        isShowResultsSample : false
      }
  }

  render() {
    const {
      isLoading,
      showUploadResultsDialog,
      isShowResultsSample,
    } = this.state;
    const selfUR = this;

    this.showUploadResultsDialog = () => {
      selfUR.setState({
        showUploadResultsDialog: true,
        loading : false,
        fileName : null,
        resultsPage : 1,
        resultsPerPage: 10,
        isShowResultsSample : false,
        isLoading : false
      });
      selfUR.initialize();
    }

    const UploadResultsButton = ({ record }) => {
      return <Fragment>
        <Button label='Upload' onClick={() =>this.showUploadResultsDialog()}><IconUpload/></Button>
      </Fragment>
    }

    function UploadResultsDialog() {
      //const { permissions } = usePermissions();
      const refresh = useRefresh();
      const dataProvider = useDataProvider();
      const form = useForm();
      const file = useField("file", form);
      const sourceId = useField("sourceId", form);
      const locationCode = useField("locationCode", form);
      const CustomList = () => {
          var page = selfUR.state.resultsPage
          var perPage = selfUR.state.resultsPerPage;
          function setPage(newPage) {
            selfUR.setState({
              resultsPage : newPage
            })
          }
          function setPerPage(newPerPage) {
            selfUR.setState({
              resultsPage : 1,
              resultsPerPage : newPerPage
            })
          }
          const { data, total, loading, error } = useQuery({
              type: 'getList',
              resource: 'mapping/dryrun',
              payload: {
                  pagination: { page, perPage },
                  sort: { field: 'collectionDate', order: 'DESC' },
                  filter: {
                    file: selfUR.state.fileName,
                    sourceId: sourceId.input.value,
                    locationCode: locationCode.input.value,
                  },
              }
          });

          if (loading) {
            return <Box display="flex" justifyContent="center" style={{ width: '100%' }}><Loading /></Box>
          }
          if (error) {
              return <p>ERROR: {error}</p>
          }
          const PatientDateOfBirthFormat = ({ record = {} }) => <span>{record.patientDateOfBirth ? Moment(record.patientDateOfBirth).format('DD MMM YYYY') : ""}</span>;
          const CollectionDateFormat = ({ record = {} }) => <span>{record.collectionDate ? Moment(record.collectionDate).format('DD MMM YYYY') : ""}</span>;
          return (
              <ListContextProvider
                  value={{
                      resource: 'mapping/dryrun',
                      basePath: 'mapping/result',
                      data: keyBy(data, 'id'),
                      ids: data != null ? data.map(({ id }) => id) : [],
                      currentSort: { field: 'id', order: 'ASC' },
                      selectedIds: [],
                      total,
                      page,
                      perPage,
                      setPage,
                      setPerPage
                  }}
              >
                  <Datagrid>
                      <TextField sortable={false} source="patientFirstName" label="Patient First Name" />
                      <TextField sortable={false} source="patientLastName" label="Patient Last Name" />
                      <PatientDateOfBirthFormat sortable={false} source="patientDateOfBirth" label="Patient Date of Birth" />
                      <TextField sortable={false} source="patientEmail" />
                      <TextField sortable={false} source="testType" />
                      <TextField sortable={false} source="result" />
                      <CollectionDateFormat sortable={false} source="collectionDate" label="Collection Date"  />
                  </Datagrid>
                  <Pagination />
              </ListContextProvider>
          );
      };
      selfUR.initialize = () => {
        form.change('file', null);
        form.change('sourceId', null);
        form.change('locationCode', null);
      }

      selfUR.closeUploadResultsDialog = () => {
        selfUR.setState({
          showUploadResultsDialog: false,
          isLoading : false,
        }, () => refresh());
      }
      selfUR.saveUploadedResults = () => {
        if (sourceId.input.value != null && sourceId.input.value !== "" && file.input.value != null && file.input.value !== "") {
          selfUR.setState({
            isLoading : true
          });
          toast.info("Saving uploaded results, please wait...", {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: false,
            hideProgressBar: true
          });
          dataProvider.create('mapping/result', { data: {
            file: selfUR.state.fileName,
            sourceId: sourceId.input.value,
            locationCode: locationCode.input.value,
          }
        }).then((savedResultsReturned) => {
            console.log("savedResultsReturned ", savedResultsReturned)
            toast.dismiss()
            toast.success("Results Saved", {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: true,
              hideProgressBar: true,
              autoClose: 1000
            });
            refresh()
            selfUR.setState({
                showUploadResultsDialog: false,
                isShowResultsSample : false,
                isLoading : false
            });
          }).catch(({ status, statusText, headers, body }) => {
            console.log("error saving results ", body);
            toast.dismiss()
            toast.error(body && body.message ? body.message : "Error", {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: true,
              hideProgressBar: true
            });
            selfUR.setState({
                isLoading : false
            });
          })
        } else {
          if (sourceId.input.value == null || sourceId.input.value === "")
            toast.error("Source is required before saving results", {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: false,
              hideProgressBar: true
            });
          if (file.input.value == null || file.input.value === "")
            toast.error("You must select a file to upload before saving results", {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: false,
              hideProgressBar: true
            });
        }
      }
      selfUR.uploadResults = () => {
        console.log("sourceId.input.value ", sourceId.input.value);
        if (sourceId.input.value != null && sourceId.input.value !== "" && file.input.value != null && file.input.value !== "") {
          selfUR.setState({
            isLoading : true
          });
          toast.info("Uploading file, please wait...", {
            position: toast.POSITION.BOTTOM_CENTER,
            closeButton: false,
            hideProgressBar: true
          });
          dataProvider.create('file', { data: {
            file: file.input.value
          }
          }).then((uploadedFileReturned) => {
              toast.dismiss()
              toast.success("Verify results sample below before saving", {
                position: toast.POSITION.BOTTOM_CENTER,
                closeButton: true,
                hideProgressBar: true,
                autoClose: 1000
              });
              selfUR.setState({
                  fileName : uploadedFileReturned.data.file,
                  resultsPage : 1,
                  resultsPerPage: 10,
                  isShowResultsSample : true,
                  isLoading : false
              });
          }).catch(({ status, statusText, headers, body }) => {
            console.log("error uploading Results ", body);
            toast.dismiss()
            toast.error(body && body.message ? body.message : "Error", {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: true,
              hideProgressBar: true
            });
            selfUR.setState({
                isLoading : false
            });
          })
        } else {
          if (sourceId.input.value == null || sourceId.input.value === "")
            toast.error("Source is required before uploading file", {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: false,
              hideProgressBar: true
            });
          if (file.input.value == null || file.input.value === "")
            toast.error("You must select a file to upload before clicking upload file", {
              position: toast.POSITION.BOTTOM_CENTER,
              closeButton: false,
              hideProgressBar: true
            });
        }
      }
      return (
        <>
          <Dialog fullWidth={true} maxWidth={"lg"}  disableBackdropClick disableEscapeKeyDown open={showUploadResultsDialog} >
              <DialogTitle>Upload Results</DialogTitle>
              { isLoading ?
              <DialogContent>
                <Loading />
              </DialogContent> :
              <DialogContent>
                <ReferenceInput source="sourceId" label="Source" reference="source"><SelectInput validate={[required()]} fullWidth optionText="name" /></ReferenceInput>
                <ReferenceInput label="Location" source="locationCode" reference="location/list" alwaysOn>
                  <AutocompleteInput optionText="code" fullWidth />
                </ReferenceInput>
                <FileInput source="file" label="Results File" multiple={false} maxSize={50000000}>
                  <FileField onClick={() => console.log("File removed")} source="src" title="title" />
                </FileInput>
                { isShowResultsSample && file.input.value != null && file.input.value !== "" && <CustomList />}
              </DialogContent> }
          <DialogActions>
          <Button
            label="Upload"
            onClick={() => selfUR.uploadResults()}
          >
          <IconUpload />
          </Button>
          <Button
          label="Cancel"
          onClick={() => selfUR.closeUploadResultsDialog()}
          >
          <IconCancel />
          </Button>
          { isShowResultsSample && <Button
            label="Save Uploaded Results"
            onClick={() => selfUR.saveUploadedResults()}
          >
          <IconSave />
          </Button> }
          </DialogActions>
        </Dialog>
        <UploadResultsButton /></>
      )
    }
    //{permissions && permissions.includes(Config.ROLE_ADMIN) &&  }
    return <Fragment>
      <SimpleForm toolbar={null} id="UploadForm">
        <UploadResultsDialog />
      </SimpleForm>
    </Fragment>
  }
}
