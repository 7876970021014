import React, {Fragment, useEffect, useState}  from 'react';
import {List, Datagrid, TextField, TopToolbar, RefreshButton,
  ExportButton, Filter, TextInput, DateInput, Pagination, Loading,
  useRefresh, useDataProvider } from 'react-admin';
import Button from '@material-ui/core/Button';
import IconCancel from '@material-ui/icons/Cancel';
import IconEdit from '@material-ui/icons/Create';
import {UploadResults} from './UploadResultsDialog.js'
import Moment from 'moment';
import EmailSentIcon from '../images/icons/EMAIL_SENT.png'
import EmailNotSentIcon from '../images/icons/EMAIL_NOT_SENT.png'
import EmailDeliveredIcon from '../images/icons/EMAIL_DELIVERED.png'
import EmailBouncedIcon from '../images/icons/EMAIL_BOUNCED.png'
import PreRegisteredIcon from '../images/icons/PRE_REGISTERED.png'
import UnableToSendIcon from '../images/icons/UNABLE_TO_SEND.png';
import VaccinatedIcon from '../images/icons/VACCINATED.png';
import VerifiedNegativeIcon from '../images/icons/VERIFIED_NEGATIVE.png';
import PDFIcon from '../images/icons/pdf.png';
import DocumentsIcon from '../images/icons/documents.png';
import IconSave from '@material-ui/icons/Save';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextFieldCore from '@material-ui/core/TextField';
import IconButton from "@material-ui/core/Button";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputMask from 'react-input-mask'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import { useSelector } from 'react-redux';
import { LinearProgress } from 'react-admin';
import ReactPanZoom from 'react-image-pan-zoom-rotate';

const useStyles = makeStyles({
  dateOfBirth : {
    paddingTop: '20px',
    fontSize: '20px',
    width: '25%'
  },
  dateOfBirthInput : {
    maxWidth: '20%',
    paddingRight: '1%',
  },
  dateOfBirthInputMobile: {
    maxWidth: '100%',
  },
  dateOfBirthSelectInput : {
    paddingRight: '1%',
  },
  form : {
    minWidth: '33%'
  }
});

const HomeActions = ({
    bulkActions,
    basePath,
    currentSort,
    displayedFilters,
    exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    total
}) => (
    <TopToolbar>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        {false && <UploadResults />}
        {<ExportButton
              maxResults={1000000}
              disabled={total === 0}
              resource={resource}
              sort={currentSort}
              filter={filterValues}
              exporter={exporter}
          />}
        {
          // <CreateButton basePath={basePath} />
        }
        <RefreshButton />
    </TopToolbar>
);

var editRecord = {}
var loadingDocument = false

const HomeFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Location Code" source="locationCode" style={{ maxWidth: '200px' }} resettable alwaysOn />
        <DateInput label="Collection Date Start" source="startCollectionDate" style={{ minWidth: '160px' }} alwaysOn />
        <DateInput className="beforeClearButtonFilter" label="Collection Date End" source="endCollectionDate" style={{ minWidth: '160px' }}  alwaysOn />
        <Button className="clearButtonFilter" key={'ClearCollectionDates'} style={{ display: 'block', minWidth: '5px', padding: '0', paddingBottom: '10px', color: 'black' }} onClick={() => props.setFilters({
              locationCode: props.filterValues.locationCode,
              startCollectionDate: '',
              endCollectionDate: '',
              resultStatus: props.filterValues.resultStatus,
              patientFirstName: props.filterValues.patientFirstName,
              patientLastName: props.filterValues.patientLastName,
              patientDateOfBirth: props.filterValues.patientDateOfBirth
         })} alwaysOn aria-label="Clear Collection Dates"><IconCancel /></Button>
        <TextInput label="Result Status" source="resultStatus" style={{ maxWidth: '200px' }}  resettable alwaysOn />
        <TextInput label="Patient First Name" source="patientFirstName" style={{ maxWidth: '200px' }}  resettable alwaysOn />
        <TextInput label="Patient Last Name" source="patientLastName" style={{ maxWidth: '200px' }}  resettable alwaysOn />
        <DateInput className="beforeClearButtonFilter" label="Patient Date of Birth" source="patientDateOfBirth" alwaysOn />
        <Button className="clearButtonFilter" key={'ClearPatientDOBDates'}  style={{ display: 'block', minWidth: '5px', padding: '0', paddingBottom: '10px', color: 'black' }} onClick={() => props.setFilters({
              locationCode: props.filterValues.locationCode,
              startCollectionDate: props.filterValues.startCollectionDate,
              endCollectionDate: props.filterValues.endCollectionDate,
              resultStatus: props.filterValues.resultStatus,
              patientFirstName: props.filterValues.patientFirstName,
              patientLastName: props.filterValues.patientLastName,
              patientDateOfBirth: ''
         })} alwaysOn aria-label="Clear Patient Date of Birth"><IconCancel /></Button>
         <TextInput label="Test Type" source="testType" style={{ maxWidth: '200px' }}  resettable alwaysOn />
    </Filter>
);
const PatientDateOfBirthFormat = ({ record = {} }) => <span>{record.patientDateOfBirth ? Moment(record.patientDateOfBirth).format('DD MMM YYYY') : ""}</span>;
const CollectionDateFormat = ({ record = {} }) => <span>{record.collectionDate ? Moment(record.collectionDate).format('DD MMM YYYY') : ""}</span>;
const CreatedDateFormat = ({ record = {} }) => <span>{record.createdDatetime ? Moment(record.createdDatetime).format('DD MMM YYYY') : ""}</span>;
const ModifiedDateFormat = ({ record = {} }) => <span>{record.modifiedDatetime ? Moment(record.modifiedDatetime).format('DD MMM YYYY hh:mm:ss A') : ""}</span>;
const HomePagination = props => <Pagination style={{ display: 'block' }} rowsPerPageOptions={[25, 50, 100]} {...props} />
const StatusIcon = ({ record = {} }) => <Fragment>
  {record.status === "EMAIL_SENT" && <img src={EmailSentIcon} style={{ textAlign: 'center', width: '20px', minWidth: '20px', padding: '0' }} aria-label="Email Sent" title="Email Sent" className="NotDetectedColor" />}
  {record.status === "EMAIL_NOT_SENT" && <img src={EmailNotSentIcon} style={{ textAlign: 'center', width: '20px', minWidth: '20px', padding: '0' }} aria-label="Email Not Sent" title="Email Not Sent" className="InconclusiveColor" />}
  {record.status === "EMAIL_DELIVERED" && <img src={EmailDeliveredIcon} style={{ textAlign: 'center', width: '20px', minWidth: '20px', padding: '0' }} aria-label="Email Delivered" title="Email Delivered" className="VaccinatedColor" />}
  {record.status === "EMAIL_BOUNCED" && <img src={EmailBouncedIcon} style={{ textAlign: 'center', width: '20px', minWidth: '20px', padding: '0' }} aria-label="Email Bounced" title="Email Bounced" className="DetectedColor" />}
  {record.status === "UNABLE_TO_SEND" && <img src={UnableToSendIcon} style={{ textAlign: 'center', width: '20px', minWidth: '20px', padding: '0' }} aria-label="Unable To Send" title="Unable To Send" className="DetectedColor" />}
  {record.status === "PRE_REGISTERED" && <img src={PreRegisteredIcon} style={{ textAlign: 'center', width: '20px', minWidth: '20px', padding: '0' }} aria-label="Pre-Registered" title="Pre-Registered" className="VerifiedNegativeColor" />}
  {record.status === "VACCINATED" && <img src={VaccinatedIcon} style={{ textAlign: 'center', width: '20px', minWidth: '20px', padding: '0' }} aria-label="Vaccinated" title="Vaccinated" className="VaccinatedColor" />}
  {record.status === "VERIFIED_NEGATIVE" && <img src={VerifiedNegativeIcon} style={{ textAlign: 'center', width: '20px', minWidth: '20px', padding: '0' }} aria-label="Verified Negative" title="Verified Negative" className="VaccinatedColor" />}
</Fragment>;

class PatientDocuments extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        patientToEdit: {},
        locationList: [],
        testTypeList: [],
        resultList: [
          {id : '', value: '', name: ''},
          {id : 'NOT DETECTED', value: 'NOT DETECTED', name: 'Negative'},
          {id : 'DETECTED', value: 'DETECTED', name: 'Positive'},
          {id : 'INCONCLUSIVE', value: 'INCONCLUSIVE', name: 'Inconclusive'},
          {id : 'SPECIMEN UNSATISFACTORY', value: 'SPECIMEN UNSATISFACTORY', name: 'Unsatisfactory'},
          {id : 'VACCINATED', value: 'VACCINATED', name: 'Vaccinated'},
          {id : 'VERIFIED NEGATIVE', value: 'VERIFIED NEGATIVE', name: 'Verified Negative'},
          {id : 'VACCINATED WITH ID', value: 'VACCINATED WITH ID', name: 'Vaccinated with ID'}
        ]
      }
  }
  render() {
    const {
      locationList,
      testTypeList,
      resultList
    } = this.state;
    this.showPatientDocumentsDialog = (record) => {
      selfUR.fetchAllLocations(record);
    }
    const PatientDocumentsButton = ({ record }) => {
      return <Fragment>
        <Button style={{ display: 'none' }} id="DocumentsClickedButton" onClick={() => this.showPatientDocumentsDialog(record)}>DOCUMENTS</Button>
      </Fragment>
    }
    const MyDocumentsDialog = ({ open, handleClose, title, children }) => {
      return (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            { !useSelector(state => state.admin.loading > 0) && <IconButton
              onClick={() => selfUR.expressCheckin()}
              startIcon={<IconSave />}
            >
              Check In
            </IconButton> }
            { !useSelector(state => state.admin.loading > 0) && <IconButton
            onClick={handleClose}
            startIcon={<IconCancel />}
            >
              Cancel
            </IconButton> }
          </DialogActions>
        </Dialog>
      );
    };

    const useDialog = () => {
      const [open, setOpen] = useState(false);
      const openDialog = () => {
        loadingDocument =  false
        setOpen(!open);
      };
      const handleClose = () => {
        setOpen(false);
      };
      const props = {
        open,
        handleClose
      };
      return [openDialog, props];
    };

    const selfUR = this;
    function PatientDocumentsDialog() {
      const [patientId, setPatientId] = React.useState(undefined);
      const [locationCode, setLocationCode] = React.useState(undefined);
      const [testType, setTestType] = React.useState(undefined);
      const [result, setResult] = React.useState(undefined);
      const [documents, setDocuments] = React.useState([]);
      const [openDialog, dialogProps] = useDialog();
      const [documentData, setDocumentData] = React.useState(undefined);
      const [loadingDocumentData, setLoadingDocumentData] = React.useState(false);
      const [resultError, setResultError] = React.useState(undefined);
      const [testTypeError, setTestTypeError] = React.useState(undefined);
      const [locationCodeError, setLocationCodeError] = React.useState(undefined);
      const [collectionDate, setCollectionDate] = React.useState(undefined);
      const [collectionDateError, setCollectionDateError] = React.useState(undefined);

      const isLoading = useSelector(state => state.admin.loading > 0)
      const dataProvider = useDataProvider();
      const refresh = useRefresh();
      selfUR.fetchAllTestTypes = (record, locationList) => {
        selfUR.setState({
            loading: true,
        }, ()=> document.getElementById('OpenDocumentsMR').click());
        dataProvider.getList('test/type',{
              filter: { },
              sort: { field: 'id', order: 'DESC' },
              pagination: { page: 1, perPage: 5000 },
        }).then((returnedTestTypes) => {
            selfUR.setState({
                locationList: locationList,
                testTypeList : returnedTestTypes.data,
            }, ()=> document.getElementById('OpenDocumentsMR').click());
        }).catch(({ status, statusText, headers, body }) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
        });
      }
      selfUR.fetchAllLocations = (record) => {
        console.log("fetchAllLocations record ", record)
        dataProvider.getList('location/list',{
              filter: { },
              sort: { field: 'id', order: 'DESC' },
              pagination: { page: 1, perPage: 5000 },
        }).then((returnedLocations) => {
            selfUR.fetchAllTestTypes(record, returnedLocations.data);
        }).catch(({ status, statusText, headers, body }) => {
          console.log("Caug≤ ht status status ", status);
          console.log("Caught status statusText ", statusText);
        });
      }
      const classes = useStyles();
      useEffect(() => {
        //console.log("Inside  useEffect firstName ", selfUR.state.patientToEdit.patientFirstName);
        setPatientId(editRecord.id)
        if (editRecord.locationCode !== 'PRE-REG FORM')
          setLocationCode(editRecord.locationCode)
        setTestType(editRecord.testType)
        setCollectionDate(editRecord.collectionDate)
        setDocuments(editRecord.documents)
        if (editRecord.documents && editRecord.documents.length > 0) {
          setTimeout(function () {
              selfUR.downloadDocument(editRecord.documents[0].name, editRecord.documents[0].id)
          }, Math.floor(Math.random() * 500));
        }
      }, []);

      selfUR.expressCheckin = () => {
        var testTypeId = 0;
        setTestTypeError(undefined)
        setLocationCodeError(undefined)
        setResultError(undefined)
        setCollectionDateError(undefined)
        for (var i= 0; i <  selfUR.state.testTypeList.length; i++) {
           if (selfUR.state.testTypeList[i].name === testType){
             testTypeId = selfUR.state.testTypeList[i].id
           }
        }
        var validationError = false
        if (!result || result == null) {
          setResultError("Result is required")
          validationError = true
        }
        if (!locationCode || locationCode == null) {
          if (document.getElementById('existingLocationCode') && document.getElementById('existingLocationCode').value && document.getElementById('existingLocationCode').value.length > 0) {
            setLocationCode(document.getElementById('existingLocationCode').value)
          } else {
            setLocationCodeError("Location is required")
            validationError = true
          }
        }
        if (!testTypeId || testTypeId === 0) {
          setTestTypeError("Test type is required")
          validationError = true
        }
        if (!collectionDate || collectionDate == null) {
          setCollectionDateError("Collection date is required")
          validationError = true
        }
        if (!validationError) {
          const token = localStorage.getItem('token');
          const requestOptions = {
             method: 'PUT',
             headers: { 'Content-Type': 'application/json', Authorization: token},
             body: JSON.stringify(
               {
                 testTypeId: testTypeId,
                 locationCode: document.getElementById('existingLocationCode') && document.getElementById('existingLocationCode').value && document.getElementById('existingLocationCode').value.length > 0 ? document.getElementById('existingLocationCode').value : locationCode,
                 result: result ? result : "",
                 collectionDate : collectionDate
               }
             )
          };
          if (!loadingDocumentData) {
            setLoadingDocumentData(true)
            fetch('/api/v1/result/express/' + patientId, requestOptions)
            .then(response => response.json())
            .then(jsonResponse => {
              setLoadingDocumentData(false)
              if (jsonResponse && !jsonResponse.id && !jsonResponse.success) {
                console.log("express jsonResponse ", jsonResponse);
                if (jsonResponse.message && jsonResponse.message.indexOf('Test type') >= 0 ) {
                  setTestTypeError(jsonResponse.message)
                }
              } else {
                refresh();
                openDialog();
              }
            }).catch(({ status, statusText, headers, body }) => {
              console.log("Caught status status ", status);
              console.log("Caught status statusText ", statusText);
              console.log("Caught status body ", body);
              if (body.details) {
                for (var i = 0; i < body.details.length; i++) {
                  if (body.details[i].field === 'testType')
                    setTestTypeError(body.details[i].message)
                  if (body.details[i].field === 'locationCode')
                    setLocationCodeError(body.details[i].message)
                  if (body.details[i].field === 'result')
                    setResultError(body.details[i].message)
                }
              }
            });
          }
        }
      }
      const handleLocationCodeChange = (event) => {
        if (event.target.innerHTML && event.target.innerHTML.indexOf("<path") >= 0)
          setLocationCode(undefined)
        else
          setLocationCode(event.target.innerHTML)
      }
      const handleTestTypeChange = (event) => {
        if (event.target.innerHTML && event.target.innerHTML.indexOf("<path") >= 0)
          setTestType(undefined)
        else
          setTestType(event.target.innerHTML)
      }
      const handleResultChange = (event) => {
        for (var i =0; i < resultList.length; i++) {
          if (event.target.innerHTML === resultList[i].name) {
              setResult(resultList[i].value)
          }
        }
      }
      const handleCollectionDateChange = (event) => {
        if (event.target.name === 'collectionDate')
          setCollectionDate(event.target.value)
      }
      selfUR.downloadDocument = (docName, docId) => {
        console.log("Inside downloadDocument");
        console.log("DocumentDownload ", loadingDocument)
        if (!loadingDocument && !isLoading) {
          loadingDocument = true
          console.log("set Loading Document data");
          setLoadingDocumentData(true)
          const token = localStorage.getItem('token');
          const requestOptions = {
             method: 'GET',
             headers: { Authorization: token}
          };
          fetch('/api/v1/result/document/' + docId, requestOptions)
          .then(response => response.blob())
          .then(blob => {
              var url = window.URL.createObjectURL(blob);
              setLoadingDocumentData(false)
              setDocumentData(url)
          });
        }
      }
      return (
        <>
          <MyDocumentsDialog fullWidth={true} maxWidth={"sm"} disableBackdropClick disableEscapeKeyDown {...dialogProps} title="Express Check-in">
            { useSelector(state => state.admin.loading > 0) ?
              <Loading />
             : <form  style={{ minWidth: '500px' }} className={classes.form} noValidate onSubmit={selfUR.onSubmit} >
             <Autocomplete
               id="existingLocationCode"
               value={locationCode}
               freeSolo
               onChange={handleLocationCodeChange}
               options={locationList.map((option) => option.code)}
               renderInput={(params) => (
                 <TextFieldCore required {...params} label="Location" margin="normal" variant="outlined" helperText={locationCodeError} />
               )}
             />
             <Autocomplete
               id="testType"
               value={testType}
               onChange={handleTestTypeChange}
               options={testTypeList.map((option) => option.name)}
               renderInput={(params) => (
                 <TextFieldCore required {...params} label="Test Type" margin="normal" variant="outlined" helperText={testTypeError} />
               )}
             />
             <Autocomplete
               id="result"
               value={result}
               onChange={handleResultChange}
               options={resultList.map((option) => option.name)}
               renderInput={(params) => (
                 <TextFieldCore required {...params} label="Result" margin="normal" variant="outlined" helperText={resultError} />
               )}
             />
             <FormControl fullWidth error={collectionDateError} className={classes.formInput}>
               <InputMask
                 mask="9999-99-99"
                 value={collectionDate}
                 onChange={handleCollectionDateChange}
               >
                 {() => <TextFieldCore variant="outlined" margin="normal"
                 fullWidth
                 id="collectionDate"
                 label="Collection Date *"
                 name="collectionDate" />}
               </InputMask>
               <FormHelperText>{collectionDateError}</FormHelperText>
             </FormControl>
             { false && <Typography  variant="h4" style={{ paddingTop: '10px', fontSize: '14px' }}>Documents<br /><br /></Typography> }
             { false && documents && documents.length > 0 && documents.map(document => {
              return <ListItem button onClick={() => selfUR.downloadDocument(document.name, document.id)} key={`doc-${document.id}`}>
                <ListItemText primary={<span>{document.name}</span>} />
              </ListItem>
             })}
             { !loadingDocumentData && documentData && <div style={{height: '250px', width: '100%', position: 'relative', display: 'initial' }}><ReactPanZoom image={documentData} id="DocumentDownload" alt="document image" /></div> }
             { loadingDocumentData && <LinearProgress style={{height: '5px', bottom: '50px', width: '90%', margin: '0px', position: 'absolute' }}/>}
            </form>
          }
          </MyDocumentsDialog>
          <button style={{ display: 'none' }} id="OpenDocumentsMR" onClick={openDialog}>Open dialog</button>
        </>
      )
    }
    return <Fragment>
      <PatientDocumentsDialog />
      <PatientDocumentsButton {...this.props} />
    </Fragment>
  }
}
const DocumentsClicked = (record) => {
   document.getElementById('DocumentsClickedButton').click()
   editRecord = record
}
const DocumentsButton = ({ record = {} }) => <Fragment>
  { record.documents && record.status === 'PRE_REGISTERED' && <img src={DocumentsIcon} onClick={() => DocumentsClicked(record)} style={{ cursor: 'pointer', textAlign: 'center', width: '20px' }} aria-label="Documents" title="Documents" />}
</Fragment>;
const PDFResultsButton = ({ record = {} }) => <Fragment>
  { record.documents && record.documents && <img src={PDFIcon} style={{ textAlign: 'center', width: '20px' }} aria-label="Results" title="Results" />}
</Fragment>;
class PatientEdit extends React.Component {
  constructor(props) {
      super(props)
      this.state = {
        firstName: "",
        lastNameError : null,
        phoneError : null,
        emailError : null,
        testTypeError : null,
        locationCodeError : null,
        dobError : null,
        locationCode : null,
        testType : null,
        address1Error : null,
        cityError : null,
        stateError : null,
        zipcodeError : null,
        countryError : null,
        genderError: null,
        ethnicityError: null,
        collectionDateError: null,
        patientDateOfBirthError: null,
        patientToEdit : {},
        locationList: [],
        testTypeList: [],
        counter: 0
      }
  }
  render() {
    this.showPatientEditDialog = (record) => {
      selfUR.setState({
        patientToEdit: {},
      });
      selfUR.fetchAllLocations(record);
    }
    const EditPatientButton = ({ record }) => {
      return <Fragment>
        <Button style={{ display: 'none', color: 'black', padding: '0' }} id="EditButton" onClick={() => this.showPatientEditDialog(record)}>EDIT</Button>
      </Fragment>
    }
    const MyDialog = ({ open, handleClose, title, children }) => {
      return (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            { !useSelector(state => state.admin.loading > 0) && <IconButton
              onClick={() => selfUR.savePatient()}
              startIcon={<IconSave />}
            >
              Save
            </IconButton> }
            { !useSelector(state => state.admin.loading > 0) && <IconButton
            onClick={handleClose}
            startIcon={<IconCancel />}
            >
              Cancel
            </IconButton> }
          </DialogActions>
        </Dialog>
      );
    };

    const useDialog = () => {
      const [open, setOpen] = useState(false);
      const openDialog = () => {
        setOpen(!open);
      };
      const handleClose = () => {
        setOpen(false);
      };
      const props = {
        open,
        handleClose
      };
      return [openDialog, props];
    };

    const selfUR = this;
    function PatientUpdateDialog() {
      const [patientId, setPatientId] = React.useState(undefined);
      const [patientFirstName, setPatientFirstName] = React.useState(undefined);
      const [firstNameError, setFirstNameError] = React.useState(undefined);
      const [patientLastName, setPatientLastName] = React.useState(undefined);
      const [lastNameError, setLastNameError] = React.useState(undefined);
      const [patientPhone, setPatientPhone] = React.useState(undefined);
      const [phoneError, setPhoneError] = React.useState(undefined);
      const [patientEmail, setPatientEmail] = React.useState(undefined);
      const [emailError, setEmailError] = React.useState(undefined);
      const [collectionDate, setCollectionDate] = React.useState(undefined);
      const [collectionDateError, setCollectionDateError] = React.useState(undefined);
      const [patientDateOfBirth, setPatientDateOfBirth] = React.useState(undefined);
      const [dateOfBirthError, setDateOfBirthError] = React.useState(undefined);
      const [resendResult, setResendResult] = React.useState(undefined);
      const [locationCode, setLocationCode] = React.useState(undefined);
      const [status, setStatus] = React.useState(undefined);
      const [locationCodeError, setLocationCodeError] = React.useState(undefined);
      const [result, setResult] = React.useState(undefined);
      const [testType, setTestType] = React.useState(undefined);
      const [testTypeError, setTestTypeError] = React.useState(undefined);
      const [openDialog, dialogProps] = useDialog();
      const refresh = useRefresh();
      const dataProvider = useDataProvider();
      const classes = useStyles();

      useEffect(() => {
        //console.log("Inside  useEffect firstName ", selfUR.state.patientToEdit.patientFirstName);
        setPatientId(editRecord.id)
        setPatientFirstName(editRecord.patientFirstName)
        setPatientLastName(editRecord.patientLastName)
        setPatientPhone(editRecord.patientPhone)
        setPatientEmail(editRecord.patientEmail)
        setPatientDateOfBirth(editRecord.patientDateOfBirth)
        setCollectionDate(editRecord.collectionDate)
        setResendResult(editRecord.resendResult)
        setResult(editRecord.result)
        setLocationCode(editRecord.locationCode)
        setTestType(editRecord.testType)
        setStatus(editRecord.status)
      }, [setPatientFirstName, setPatientLastName, setPatientPhone, setPatientEmail, setCollectionDate, setResendResult, setLocationCode, setTestType]);

      selfUR.savePatient = () => {
        var testTypeId = 0;
        for (var i= 0; i <  selfUR.state.testTypeList.length; i++) {
           if (selfUR.state.testTypeList[i].name === testType){
             testTypeId = selfUR.state.testTypeList[i].id
           }
        }
        dataProvider.update('result', { id: patientId, data: {
          id : patientId,
          firstName : patientFirstName,
          lastName : patientLastName,
          phone : patientPhone,
          email : patientEmail,
          dob : patientDateOfBirth,
          locationCode: document.getElementById('editPatientLocationCode') && document.getElementById('editPatientLocationCode').value && document.getElementById('editPatientLocationCode').value.length > 0 ? document.getElementById('editPatientLocationCode').value : locationCode,
          result: result,
          testTypeId: testTypeId && testTypeId !== 0 ? testTypeId : null,
          collectionDate: collectionDate,
          resendResult: resendResult
        }}).then((updateResult) => {
          refresh();
          openDialog();
        }).catch(({ status, statusText, headers, body }) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
          console.log("Caught status body ", body);
          if (body.details) {
            for (var i = 0; i < body.details.length; i++) {
              if (body.details[i].field === 'firstName')
                setFirstNameError(body.details[i].message)
              if (body.details[i].field === 'lastName')
                setLastNameError(body.details[i].message)
              if (body.details[i].field === 'email')
                setEmailError(body.details[i].message)
              if (body.details[i].field === 'phone')
                setPhoneError(body.details[i].message)
              if (body.details[i].field === 'collectionDate')
                setCollectionDateError(body.details[i].message)
              if (body.details[i].field === 'testTypeId')
                setTestTypeError("Test type is required")
              if (body.details[i].field === 'locationCode')
                setLocationCodeError(body.details[i].message)
              if (body.details[i].field === 'dob')
                setDateOfBirthError(body.details[i].message)
            }
          }
        });
      }
      selfUR.fetchAllTestTypes = (record, locationList) => {
        dataProvider.getList('test/type',{
              filter: { },
              sort: { field: 'id', order: 'DESC' },
              pagination: { page: 1, perPage: 5000 },
        }).then((returnedTestTypes) => {
            selfUR.setState({
                locationList: locationList,
                testTypeList : returnedTestTypes.data,
            }, ()=> document.getElementById('OpenEditMR').click());
        }).catch(({ status, statusText, headers, body }) => {
          console.log("Caught status status ", status);
          console.log("Caught status statusText ", statusText);
        });
      }
      selfUR.fetchAllLocations = (record) => {
        selfUR.setState({
            loading: true,
        }, ()=> document.getElementById('OpenEditMR').click());
        dataProvider.getList('location/list',{
              filter: { },
              sort: { field: 'id', order: 'DESC' },
              pagination: { page: 1, perPage: 5000 },
        }).then((returnedLocations) => {
            selfUR.fetchAllTestTypes(record, returnedLocations.data);
        }).catch(({ status, statusText, headers, body }) => {
          console.log("Caug≤ ht status status ", status);
          console.log("Caught status statusText ", statusText);
        });
      }
      selfUR.closePatientEditDialog = () => {
        selfUR.setState({
          patientEdit: false,
        }, () => refresh());
      }
      selfUR.closePatientEditDialog = () => {
        selfUR.setState({
          patientEdit: false,
        }, () => refresh());
      }
      const handleLocationCodeChange = (event) => {
        if (event.target.innerHTML && event.target.innerHTML.indexOf("<path") >= 0)
          setLocationCode(undefined)
        else
          setLocationCode(event.target.innerHTML)
      }
      const handleTestTypeChange = (event) => {
        if (event.target.innerHTML && event.target.innerHTML.indexOf("<path") >= 0)
          setTestType(undefined)
        else
          setTestType(event.target.innerHTML)
      }
      const handleRegistrationChange = (event) => {
        if (event.target.name === 'patientFirstName')
          setPatientFirstName(event.target.value)
        if (event.target.name === 'patientLastName')
          setPatientLastName(event.target.value)
        if (event.target.name === 'patientPhone')
          setPatientPhone(event.target.value)
        if (event.target.name === 'patientEmail')
          setPatientEmail(event.target.value)
        if (event.target.name === 'collectionDate')
          setCollectionDate(event.target.value)
        if (event.target.name === 'patientDateOfBirth')
          setPatientDateOfBirth(event.target.value)
      }
      selfUR.toggleResendResult = () => {
        setResendResult(!resendResult)
      }
      return (
        <>
          <MyDialog fullWidth={true} maxWidth={"sm"}  disableBackdropClick disableEscapeKeyDown {...dialogProps} title="Edit Patient">
            { useSelector(state => state.admin.loading > 0) ?
              <Loading />
             : <form className={classes.form} noValidate onSubmit={selfUR.onSubmit} >
              { status !== 'PRE_REGISTERED' && <Autocomplete
                id="editPatientLocationCode"
                value={locationCode}
                freeSolo
                onChange={handleLocationCodeChange}
                options={selfUR.state.locationList.map((option) => option.code)}
                renderInput={(params) => (
                  <TextFieldCore {...params} label="Location" margin="normal" variant="outlined" helperText={locationCodeError} />
                )}
              /> }
              <Autocomplete
                id="testType"
                value={testType}
                onChange={handleTestTypeChange}
                options={selfUR.state.testTypeList.map((option) => option.name)}
                renderInput={(params) => (
                  <TextFieldCore {...params} label="Test Type" margin="normal" variant="outlined" helperText={testTypeError} />
                )}
              />
              <FormControl fullWidth error={firstNameError} className={classes.formInput}>
                <TextFieldCore
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="patientFirstName"
                  label="First Name *"
                  name="patientFirstName"
                  autoComplete="first name"
                  onChange={handleRegistrationChange}
                  value={patientFirstName}
                />
                <FormHelperText>{firstNameError}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error={lastNameError} className={classes.formInput}>
                <TextFieldCore
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="patientLastName"
                  label="Last Name *"
                  name="patientLastName"
                  autoComplete="last name"
                  onChange={handleRegistrationChange}
                  value={patientLastName}
                />
                <FormHelperText>{lastNameError}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error={emailError} className={classes.formInput}>
                <TextFieldCore
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="patientEmail"
                  label="Email Address *"
                  name="patientEmail"
                  autoComplete="email"
                  onChange={handleRegistrationChange}
                  value={patientEmail}
                />
                <FormHelperText>{emailError}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error={phoneError} className={classes.formInput}>
                <InputMask
                  mask="(999) 999 9999"
                  value={patientPhone}
                  onChange={handleRegistrationChange}
                >
                  {() => <TextFieldCore variant="outlined" margin="normal"
                  fullWidth
                  id="patientPhone"
                  label="Phone Number *"
                  name="patientPhone"
                  autoComplete="phone" />}
                </InputMask>
                <FormHelperText>{phoneError}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error={dateOfBirthError} className={classes.formInput}>
                <InputMask
                  mask="9999-99-99"
                  value={patientDateOfBirth}
                  onChange={handleRegistrationChange}
                >
                  {() => <TextFieldCore variant="outlined" margin="normal"
                  fullWidth
                  id="patientDateOfBirth"
                  label="Date Of Birth *"
                  name="patientDateOfBirth" />}
                </InputMask>
                <FormHelperText>{dateOfBirthError}</FormHelperText>
              </FormControl>
              <FormControl fullWidth error={collectionDateError} className={classes.formInput}>
                <InputMask
                  mask="9999-99-99"
                  value={collectionDate}
                  onChange={handleRegistrationChange}
                >
                  {() => <TextFieldCore variant="outlined" margin="normal"
                  fullWidth
                  id="collectionDate"
                  label="Collection Date *"
                  name="collectionDate" />}
                </InputMask>
                <FormHelperText>{collectionDateError}</FormHelperText>
              </FormControl>
              { status !== 'PRE_REGISTERED' && <FormControlLabel
              control={<Checkbox checked={resendResult} value={resendResult} name="resendResult" id="resendResult" color="primary" onClick={() => selfUR.toggleResendResult() } />}
              label="Resend Result"
              /> }
            </form>
          }
          </MyDialog>
          <button style={{ display: 'none' }} id="OpenEditMR" onClick={openDialog}>Open dialog</button>
        </>
      )
    }
    return <Fragment>
      <PatientUpdateDialog />
      <EditPatientButton {...this.props} />
    </Fragment>
  }
}
const EditClicked = (record) => {
   document.getElementById('EditButton').click()
   editRecord = record
}
const EditButton = ({ record }) => {
  return <Fragment>
    <Button style={{ minWidth: '20px', padding: '0', color: 'black' }} onClick={() => EditClicked(record)}><IconEdit /></Button>
  </Fragment>
}
export const HomeList = props => (
  <section className='section'>
    <List id="HomeList" style={{ minWidth: '2100px' }} sort={{ field: 'id', order: 'DESC' }} perPage={50} title="Home" {...props} actions={<HomeActions />} pagination={< HomePagination />} filters={<HomeFilter />} bulkActionButtons={false} >
        <Datagrid>
          <EditButton style={{ minWidth: '20px', padding: '0', textAlign: 'center', width: '20px' }}  />
          <StatusIcon sortable={false} label="" source="status" style={{  minWidth: '20px', padding: '0', textAlign: 'center', width: '20px' }} />
          <DocumentsButton sortable={false} label="" source="documents" style={{  minWidth: '20px', padding: '0', textAlign: 'center', width: '20px' }} />
          <TextField source="locationCode" label="Location" />
          <TextField label="Patient First Name" source="patientFirstName"  />
          <TextField label="Patient Last Name" source="patientLastName" />
          <TextField source="patientPhone" label="Patient Phone" />
          <TextField source="patientEmail" label="Patient Email" />
          <PatientDateOfBirthFormat source="patientDateOfBirth" label="Patient Date of Birth" />
          <TextField source="result" />
          <TextField source="testType" label="Test Type" />
          <CollectionDateFormat source="collectionDate" label="Collection Date"  />
          <CreatedDateFormat source="createdDatetime" label="Created" />
          <ModifiedDateFormat  source="modifiedDatetime" label="Modified" />
          { false && <PDFResultsButton sortable={false} label="" source="status" style={{ textAlign: 'center', width: '20px' }} /> }
        </Datagrid>
    </List>
    <PatientEdit label=''/>
    <PatientDocuments label=''/>
  </section>
);
