import simpleRestProvider from 'ra-data-simple-rest';
import { fetchUtils } from 'react-admin'; //  GET_ONE, UPDATE,
import * as Config from './config'
import { HttpError } from 'react-admin';
// A function decorating a dataProvider for handling user profiles
const httpClient = (url, options = {}) => {
    const token = localStorage.getItem('token');
    options.headers = new Headers({ Accept: 'application/json', Authorization: token });
    return fetchUtils.fetchJson(url, options);
}

const dataProvider = simpleRestProvider(Config.REACT_APP_API_URL, httpClient)

const myDataProvider = {
    ...dataProvider,
    getOne: (resource, params) => {
      // fallback to the default implementation
      if (resource === "location")
        resource = resource + "/" + localStorage.getItem('tenantId')
      return dataProvider.getOne(resource, params)
          .catch(({ status, statusText, headers, body }) => {
            let json = body;
            let message;
            let errorMessage = "";
            try  {
              if (json.isPrototypeOf(String)) {
                message = JSON.parse(json.message)
                if (message && message.length > 0)
                  message.forEach(function(obj) {
                    var result = obj.field.replace( /([A-Z])/g, " $1" );
                    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                    if (errorMessage !== "")
                      errorMessage += ", "
                    errorMessage += finalResult + " - " + (obj.message ? obj.message : "is empty")
                  });
              } else if (json.message != null) {
                errorMessage = json.message
              } else {
                if (json.details)
                  message = json.details
                if (message && (Array.isArray(message) || message.isPrototypeOf(Array))) {
                  message.forEach(function(obj) {
                    var result = obj.field.replace( /([A-Z])/g, " $1" );
                    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                    if (errorMessage !== "")
                      errorMessage += ", "
                    errorMessage += finalResult + " - " + (obj.message ? obj.message : "is empty")
                  });
                } else {
                  errorMessage += message
                }
              }
            } catch (e) {
                // not json, no big deal
                message = "Error occurred, please contact support"
            }
            if (!message || message.length <= 0)
              message = "Error occurred, please contact support"
            throw new HttpError((errorMessage) || statusText,status,json)
        });
    },
    getList: (resource, params) => {
      // fallback to the default implementation
      console.log("resource ", resource)
      if (resource === "location")
        resource = resource + "/" + localStorage.getItem('tenantId')
      return dataProvider.getList(resource, params)
          .catch(({ status, statusText, headers, body }) => {
            let json = body;
            let message;
            let errorMessage = "";
            try  {
              if (json.isPrototypeOf(String)) {
                message = JSON.parse(json.message)
                if (message && message.length > 0)
                  message.forEach(function(obj) {
                    var result = obj.field.replace( /([A-Z])/g, " $1" );
                    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                    if (errorMessage !== "")
                      errorMessage += ", "
                    errorMessage += finalResult + " - " + (obj.message ? obj.message : "is empty")
                  });
              } else if (json.message != null) {
                errorMessage = json.message
              } else {
                if (json.details)
                  message = json.details
                if (message && (Array.isArray(message) || message.isPrototypeOf(Array))) {
                  message.forEach(function(obj) {
                    var result = obj.field.replace( /([A-Z])/g, " $1" );
                    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                    if (errorMessage !== "")
                      errorMessage += ", "
                    errorMessage += finalResult + " - " + (obj.message ? obj.message : "is empty")
                  });
                } else {
                  errorMessage += message
                }
              }
            } catch (e) {
                // not json, no big deal
                message = "Error occurred, please contact support"
            }
            if (!message || message.length <= 0)
              message = "Error occurred, please contact support"
            throw new HttpError((errorMessage) || statusText,status,json)
        });
    },
    create: (resource, params) => {
        if (resource === "location")
          resource = resource + "/" + localStorage.getItem('tenantId')
        if (resource === 'user') {
          params.data.userRoleNames = [params.data.userRoleName]
          delete params.data.userRoleName
          return fetch(Config.REACT_APP_API_URL + "/user", {
              method: "POST",
              headers: new Headers({'Authorization': localStorage.getItem('token'), 'Content-Type' : 'application/json'}),
              body: JSON.stringify(params.data)
          })
          .then((response) => response.json())
          .then((json) => ( { data: { id: json.id }} ))
        }
        if (resource === 'mapping' && params.data.files) {
            let form = new FormData();
            form.set("file", params.data.files.rawFile);
            form.set("name", params.data.name);
            form.set("hasHeader", params.data.hasHeader);
            return fetch(Config.REACT_APP_API_URL + "/" + resource + "/uploadSample", {
                method: "POST",
                headers: new Headers({'Authorization': localStorage.getItem('token')}),
                body: form
            })
            .then((response) => response.json())
            .then((json) => ( { data: { id: json.id }} ))
        } else if (resource === 'file' &&  params.data.file) {
            let form = new FormData();
            form.set("file", params.data.file.rawFile);
            return fetch(Config.REACT_APP_API_URL + "/" + resource, {
                method: "POST",
                headers: new Headers({'Authorization': localStorage.getItem('token')}),
                body: form
            })
            .then((response) => response.json())
            .then((json) => ( {
              data: { id: 0, file: json.file }
            } ))
            .catch(({ status, statusText, headers, body }) => {
              //console.log("status ", status);
              //console.log("statusText ", statusText);
              //console.log("headers ", headers);
              //console.log("body ", body);
            });
        } else {
          // fallback to the default implementation
          return dataProvider.create(resource, params)
                  .catch(({ status, statusText, headers, body }) => {
                    let json = body;
                    let message;
                    let errorMessage = "";
                    try  {
                      if (json.isPrototypeOf(String)) {
                        message = JSON.parse(json.message)
                        if (message && message.length > 0)
                          message.forEach(function(obj) {
                            var result = obj.field.replace( /([A-Z])/g, " $1" );
                            var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                            if (errorMessage !== "")
                              errorMessage += ", "
                            errorMessage += finalResult + " - " + (obj.message ? obj.message : "is empty")
                          });
                      } else if (json.message != null && json.message.length > 0) {
                        errorMessage = json.message
                      } else if (json.error != null && json.error.length > 0) {
                        errorMessage = json.error
                      } else {
                        if (json.details)
                          message = json.details
                        if (message && (Array.isArray(message) || message.isPrototypeOf(Array))) {
                          message.forEach(function(obj) {
                            var result = obj.field.replace( /([A-Z])/g, " $1" );
                            var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                            if (errorMessage !== "")
                              errorMessage += ", "
                            errorMessage += finalResult + " - " + (obj.message ? obj.message : "is empty")
                          });
                        } else {
                          errorMessage += message
                        }
                      }
                    } catch (e) {
                        // not json, no big deal
                        message = "Error occurred, please contact support"
                    }
                    if (!message || message.length <= 0)
                      message = "Error occurred, please contact support"
                    //console.log("status ", status)
                    //console.log("statusText ", statusText)
                    //console.log("headers ", headers)
                    //console.log("body ", body)
                    //console.log("json ", json)
                    //console.log("message ", message)
                    //console.log("errorMessage ", errorMessage)
                    throw new HttpError((errorMessage) || statusText,status,json)
                });
        }
    },
    update: (resource, params) => {
        if (resource === "location")
          resource = resource + "/" + localStorage.getItem('tenantId')
        // fallback to the default implementation
        if (resource === 'user') {
          params.data.userRoleNames = [params.data.userRoleName]
          delete params.data.userRoleName
          return fetch(Config.REACT_APP_API_URL + "/user/" + params.data.id, {
              method: "PUT",
              headers: new Headers({'Authorization': localStorage.getItem('token'), 'Content-Type' : 'application/json'}),
              body: JSON.stringify(params.data)
          })
          .then((response) => response.json())
          .then((json) => ( { data: { id: json.id }} ))
        }
        return dataProvider.update(resource, params)
                .catch(({ status, statusText, headers, body }) => {
                  let json = body;
                  let message;
                  let errorMessage = "";
                  try  {
                    if (json.isPrototypeOf(String)) {
                      message = JSON.parse(json.message)
                      if (message && message.length > 0)
                        message.forEach(function(obj) {
                          var result = obj.field.replace( /([A-Z])/g, " $1" );
                          var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                          if (errorMessage !== "")
                            errorMessage += ", "
                          errorMessage += finalResult + " - " + (obj.message ? obj.message : "is empty")
                        });
                    } else if (json.message != null && json.message.length > 0) {
                      errorMessage = json.message
                    } else if (json.error != null && json.error.length > 0) {
                      errorMessage = json.error
                    } else {
                      if (json.details)
                        message = json.details
                      if (message && message.isPrototypeOf(Array)) {
                        message.forEach(function(obj) {
                          var result = obj.field.replace( /([A-Z])/g, " $1" );
                          var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                          if (errorMessage !== "")
                            errorMessage += ", "
                          errorMessage += finalResult + " - " + (obj.message ? obj.message : "is empty")
                        });
                      } else {
                        errorMessage += message
                      }
                    }
                  } catch (e) {
                      // not json, no big deal
                      message = "Error occurred, please contact support"
                  }
                  if (!message || message.length <= 0)
                    message = "Error occurred, please contact support"
                  //console.log("status ", status)
                  //console.log("statusText ", statusText)
                  //console.log("headers ", headers)
                  //console.log("body ", body)
                  //console.log("json ", json)
                  //console.log("message ", message)
                  //console.log("errorMessage ", errorMessage)
                  throw new HttpError((errorMessage) || statusText,status,json)
              });
    },
    delete: (resource, params) => {
        if (resource === "location")
          resource = resource + "/" + localStorage.getItem('tenantId')
        return dataProvider.delete(resource, params)
                .catch(({ status, statusText, headers, body }) => {
                  let json = body;
                  let message;
                  let errorMessage = "";
                  try  {
                    if (json.isPrototypeOf(String)) {
                      message = JSON.parse(json.message)
                      if (message && message.length > 0)
                        message.forEach(function(obj) {
                          var result = obj.field.replace( /([A-Z])/g, " $1" );
                          var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                          if (errorMessage !== "")
                            errorMessage += ", "
                          errorMessage += finalResult + " - " + (obj.message ? obj.message : "is empty")
                        });
                    } else if (json.message != null && json.message.length > 0) {
                      errorMessage = json.message
                    } else if (json.error != null && json.error.length > 0) {
                      errorMessage = json.error
                    } else {
                      if (json.details)
                        message = json.details
                      if (message && message.isPrototypeOf(Array)) {
                        message.forEach(function(obj) {
                          var result = obj.field.replace( /([A-Z])/g, " $1" );
                          var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
                          if (errorMessage !== "")
                            errorMessage += ", "
                          errorMessage += finalResult + " - " + (obj.message ? obj.message : "is empty")
                        });
                      } else {
                        errorMessage += message
                      }
                    }
                  } catch (e) {
                      // not json, no big deal
                      message = "Error occurred, please contact support"
                  }
                  if (!message || message.length <= 0)
                    message = "Error occurred, please contact support"
                  throw new HttpError((errorMessage) || statusText,status,json)
              });
    },
}
export default myDataProvider;
