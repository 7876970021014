// in src/theme/Theme.js
import { createMuiTheme } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
//import pink from '@material-ui/core/colors/pink';
import red from '@material-ui/core/colors/red';
import blue from '@material-ui/core/colors/blue';

export const Theme = createMuiTheme({
    palette: {
        primary: indigo,
        secondary: blue,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    overrides: {
        RaLoading: {
          container: {
            height: '50vh !important'
          }
        },
        FormInput: {
          root: {
            width: '100% !important',
          }
        },
        MuiFormControl: {
          root: {
            width: '100% !important',
          }
        },
        MuiToolbar: {
          root: {
            //color: "black",
            //backgroundColor: "white",
            //borderRadius: "0"
            // root: {
                 //button : {
                     //'&:not(:nth-child(1))': {
                         //display: 'none !important!''
                     //}
                 //}
            //}
          }
        },
        MuiAppBar: {
          colorSecondary: {
            color: "black",
            backgroundColor: "white",
          }
        },
        RaAppBar: {
          toolbar: {
            //backgroundColor: "white",
          }
        },
        RaConfirm: {
          confirmSuccess: {
            color: "green"
          }
        },
        MuiPaper: {
          root: {
            borderTopRightRadius: '0px !important',
            borderTopLeftRadius: '0px !important',
          }
        },
        MuiPopover: {
          paper: {
            marginTop: '38px !important'
          }
        },
        MuiButton: { // override the styles of all instances of this component
            root: { // Name of the rule
                color: 'blue', // Some CSS
            },
        },
        MuiFormLabel: {
          root: { // Name of the rule
              color: 'black !important', // Some CSS
          },
        },
        MuiTypography: {
          root: { // Name of the rule
              //color: 'black !important', // Some CSS
          },
        },
        MuiTableSortLabel: {
          root: { // Name of the rule
              color: '#004268  !important', // Some CSS
          },
        },
        MuiMenuItem: {
          root: { // Name of the rule
              color: '#004268 !important', // Some CSS
              fontSize: '14px'
          },
        },
        MuiSnackbarContent: {
          root: { // Name of the rule
              color: 'white !important', // Some CSS
          },
        },
        MuiTableCell: {
          root: { // Name of the rule
              //  verticalAlign: 'top !important' Some CSS
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "black",
            backgroundColor: "white",
            borderRadius: "0"
          }
        },
        SimpleFormIterator: {
          line: {
            root: {
                float: 'left !important',
                width: '20% !important',
                color: '#004268 !important', // Some CSS
            }
          },
        },
        RaFileInputPreview: {
          removeButton: {
            display: "none"
          }
        },
        RaSidebar: {
          drawerPaper: {

          }
        },
        RaAutocompleteSuggestionList: {
          suggestionsContainer: {
            zIndex: 2000
          }
        },
        RaLayout: {
          contentWithSidebar: {
            backgroundImage: 'URL(/dotgrid.png)',
            marginTop: '0px'
          },
        },
        MuiDialogActions: {
          root: {
            justifyContent: 'flex-start',
            marginLeft: '1%'
          }
        },
        MuiFormHelperText: {
          contained: {
            color: 'red'
          }
        }
    },
});

export const ResultsTheme = createMuiTheme({
    palette: {
        primary: indigo,
        secondary: blue,
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Arial',
            'sans-serif',
        ].join(','),
    },
    overrides: {
        FormInput: {
          root: {
            width: '100% !important',
          }
        },
        MuiFormControl: {
          root: {
            width: '100% !important',
          }
        },
        MuiToolbar: {
          root: {
            //color: "black",
            //backgroundColor: "white",
            //borderRadius: "0"
          }
        },
        MuiAppBar: {
          root: {
              display: 'none !important'
          }
        },
        RaAppBar: {
          toolbar: {
            //backgroundColor: "white",
          }
        },
        RaConfirm: {
          confirmSuccess: {
            color: "green"
          }
        },
        MuiPaper: {
          root: {
            borderTopRightRadius: '0px !important',
            borderTopLeftRadius: '0px !important',
            boxShadow: '0 0 black !important',
            backgroundColor: 'inherit'
          },
          elevation: {
          }
        },
        MuiPopover: {
          paper: {
            boxShadow: '2px 1px 1px 1px rgb(0 0 0 / 20%) !important',
            background: 'white'
          }
        },
        MuiButton: { // override the styles of all instances of this component
            root: { // Name of the rule
                color: 'blue', // Some CSS
            },
        },
        MuiFormLabel: {
          root: { // Name of the rule
              color: 'black !important', // Some CSS
          },
        },
        MuiTypography: {
          root: { // Name of the rule
              //color: 'black !important', // Some CSS
          },
        },
        MuiTableSortLabel: {
          root: { // Name of the rule
              color: '#004268  !important', // Some CSS
          },
        },
        MuiMenuItem: {
          root: { // Name of the rule
              color: '#004268 !important', // Some CSS
              fontSize: '14px'
          },
        },
        MuiSnackbarContent: {
          root: { // Name of the rule
              color: 'white !important', // Some CSS
          },
        },
        MuiTableCell: {
          root: { // Name of the rule
              //  verticalAlign: 'top !important' Some CSS
          },
        },
        MuiIconButton: {
          colorInherit: {
            color: "black",
            backgroundColor: "white",
            borderRadius: "0"
          }
        },
        SimpleFormIterator: {
          line: {
            root: {
                float: 'left !important',
                width: '20% !important',
                color: '#004268 !important', // Some CSS
            }
          },
        },
        RaSidebar: {
          drawerPaper: {

          }
        },
        RaAutocompleteSuggestionList: {
          suggestionsContainer: {
            zIndex: 2000
          }
        },
        RaSelectInput: {
          input: {
            minWidth: '0 !important'
          }
        },
        RaLayout: {
          contentWithSidebar: {
            backgroundColor: 'white',
            backgroundImage: 'none',
          },
          appFrame: {
              marginTop: '0 !important'
          }
        },
        MuiCard: {
          root: {
            marginLeft: 'auto',
            marginRight: 'auto'
          }
        },
        MuiCardContent: {
          root: {
            backgroundColor: 'inherit',
          }
        }
    },
});
